import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import DeliveryDate from '../DeliveryDate';
import { setQuantity } from '../../state/create/jewelry/thunks';
import { assemblySubPrice, getNextHighestIndex } from '../../utils/helpers';
import CustomTooltip from '../CustomTooltip';
import { useSearchDebounce } from '../cart/ShippingInfo';

const PriceWidget = ({
  price,
  quantity,
  setQuantity,
  priceIsLoading,
  libraryOpen,
  designWithUsModalOpen,
  creditCardOpen,
  new_order,
  productionPage,
  sellingPrice,
  qty,
  estimatedPrice,
  priceLoading,
  productChosen,
  productPricing,
  materialPrice,
  polishPrice,
  platingPrice,
  assemblyPrice,
  laserEngravingPrice,
  disPrice,
  assemblyDiscount,
  assemblySubItems,
  itemType,
  setShowQtyNotification,
  showQtyNotification,
  msgQtyNotif,
  setMsgQtyNotif,
  setUnlockDiscount,
  unlockDiscount,
  addedToShopify
}) => {
  if (libraryOpen) {
    return null;
  }
  if (designWithUsModalOpen || creditCardOpen) {
    return null;
  }
  const target = useRef(null);

  const profitOutput =
    productionPage &&
    estimatedPrice &&
    Number(sellingPrice) * Number(qty) -
    Number(estimatedPrice && estimatedPrice.totalPrice) * Number(qty);

  const [monthly, setMonthly] = useState(false);
  const [quantityDeb, setQtyDeb] = useState(1);

  var locales = [
    undefined, // Your own browser
    'en-US', // United States
    'de-DE', // Germany
    'ru-RU', // Russia
    'hi-IN', // India
    'de-CH', // Switzerland
  ];
  var n = 100000;
  var opts = { minimumFractionDigits: 2 };

  const revenue = monthly
    ? Number((Number(sellingPrice) * Number(qty)) / 12).toFixed(2)
    : Number(Number(sellingPrice) * Number(qty)).toFixed(2);

  const profit = monthly
    ? Number(profitOutput / 12).toFixed(2)
    : Number(profitOutput).toFixed(2);

  const assemblySubTotal = assemblySubPrice(assemblySubItems);

  const proPrice =
    (assemblyDiscount > 0 ? disPrice : productPricing) +
    materialPrice +
    platingPrice +
    polishPrice +
    assemblyPrice +
    laserEngravingPrice +
    Number(assemblySubTotal);

  const priceTotalAssemblys = price + Number(assemblySubTotal);

  const quantity2 = useSearchDebounce(quantityDeb, 1200);
  useEffect(() => {
    setQuantity(quantity2);
  }, [quantity2]);

  return (
    <Row
      className='price-holder'
      style={productionPage ? { marginTop: '7rem' } : { marginTop: '0rem' }}
    >
      {productionPage ? (
        <Col>
          <Row
            className='price-widget py-4'
            style={
              productionPage ? { minWidth: 'fit-content', padding: '1rem' } : {}
            }
          >
            <div className='main-calc'>
              <div
                className='profit'
                style={{
                  marginBottom: '1.5rem',
                  color: '#7c6263',
                }}
              >
                <span>&#8226;</span>Estimated Cost:{' '}
                {priceLoading ? (
                  <div>
                    <i className='fas fa-circle-notch fa-spin' />
                  </div>
                ) : (
                  `$${estimatedPrice ? estimatedPrice.totalPrice.toFixed(2) : 0
                  }`
                )}
              </div>
              <div className='revenue'>
                <span>&#8226;</span>Revenue Output (
                {monthly ? 'monthly' : 'yearly'}):{' '}
                {`$${Number(revenue).toLocaleString(locales[revenue], opts)}`}
              </div>
              <div className='profit'>
                <span>&#8226;</span>Profit Output:{' '}
                {`$${Number(profit).toLocaleString(locales[profit], opts)}`}
              </div>
            </div>
            <div className='switches'>
              <div className='action-box'>
                <OverlayTrigger
                  overlay={
                    <Tooltip id='tooltip-bottom' style={{ zIndex: '10001' }}>
                      Click to calculate {monthly ? 'yearly' : 'monthly'}{' '}
                      values!
                    </Tooltip>
                  }
                  placement='top'
                >
                  <label className='toggle-switch'>
                    <input
                      type='checkbox'
                      checked={monthly}
                      onChange={(e) => {
                        setMonthly(e.target.checked);
                      }}
                    />
                    <span className='switch' />
                  </label>
                </OverlayTrigger>
              </div>
            </div>
          </Row>
        </Col>
      ) : (
        <Col>
          <Row className='price-widget py-4'>
            <Col>
              <h5>Unit Price</h5>
            </Col>
            {productChosen ? (
              <Col>{`$${proPrice && Number(proPrice).toFixed(2)}`}</Col>
            ) : (
              <Col>
                {!priceIsLoading ? (
                  <>${!!quantity ? priceTotalAssemblys.toFixed(2) : 0.0}</>
                ) : (
                  <i className='fas fa-circle-notch fa-spin' />
                )}
              </Col>
            )}
            <Col>x</Col>
            <Col className='tooltip-col'>
              <CustomTooltip
                text={
                  <span
                    className='main-tooltip-text'
                    style={{ textAlign: 'center' }}
                  >
                    <div className='main-head-text'>
                      {unlockDiscount
                        ? 'Quantity Discount Unlocked🎉!'
                        : 'Unlock quantity discount!'}
                    </div>
                    <span
                      className='main-text-area'
                      style={{
                        height: unlockDiscount ? '0' : 'auto',
                        width: unlockDiscount ? '0' : 'auto',
                      }}
                    >
                      {msgQtyNotif}
                    </span>
                  </span>
                }
                show={showQtyNotification}
                priceTooltip={true}
                setShowQtyNotification={setShowQtyNotification}
              >
                <Form.Group id='unit-tour' style={{ marginTop: '2px' }}>
                  <Form.Control
                    ref={target}
                    style={{ textAlign: 'right' }}
                    as='input'
                    value={quantityDeb}
                    onChange={(e) => {
                      if (e.target.value < 1) return;
                      setQtyDeb(parseInt(e.target.value));
                      // setQuantity(parseInt(e.target.value));
                      if (itemType) {
                        if (itemType.has_discount) {
                          let dscs = JSON.parse(itemType.discount);
                          setShowQtyNotification(true);
                          let qtys = [];
                          if (dscs.length > 0) {
                            dscs.map((it) => {
                              qtys.push(it.quantity);
                            });
                          }

                          const nextHigh = getNextHighestIndex(
                            qtys,
                            e.target.value ? e.target.value : 1
                          );
                          let replacedWord;
                          if (dscs[nextHigh]) {
                            if (
                              Number(e.target.value) ===
                              Number(dscs[nextHigh].quantity)
                            ) {
                              setUnlockDiscount(true);
                            } else {
                              setUnlockDiscount(false);
                            }
                            if (itemType.notification) {
                              if (itemType.notification !== '') {
                                if (itemType.notification.includes('X')) {
                                  replacedWord = itemType.notification.replace(
                                    'X',
                                    `${Number(dscs[nextHigh].quantity) -
                                    Number(e.target.value)
                                    }`
                                  );
                                }
                                if (itemType.notification.includes('Y')) {
                                  replacedWord = replacedWord
                                    ? replacedWord.replace(
                                      'Y',
                                      `${dscs[nextHigh].discount}`
                                    )
                                    : itemType.notification.replace(
                                      'Y',
                                      `${dscs[nextHigh].discount}`
                                    );
                                }
                              }
                            }
                            setMsgQtyNotif(
                              replacedWord
                                ? !replacedWord.includes('X') ||
                                  !replacedWord.includes('Y')
                                  ? replacedWord
                                  : `${Number(dscs[nextHigh].quantity) -
                                    Number(e.target.value) ===
                                    0
                                    ? ``
                                    : `Add ${Number(dscs[nextHigh].quantity) -
                                    Number(e.target.value)
                                    } more items to your cart and unlock a ${dscs[nextHigh].discount
                                    }% discount on your entire order!`
                                  }`
                                : `${Number(dscs[nextHigh].quantity) -
                                  Number(e.target.value) ===
                                  0
                                  ? ''
                                  : `Add ${Number(dscs[nextHigh].quantity) -
                                  Number(e.target.value)
                                  } more items to your cart and unlock a ${dscs[nextHigh].discount
                                  }% discount on your entire order!`
                                }`
                            );
                          }
                        }
                      }
                    }}
                    type='number'
                    disabled={(assemblySubItems && assemblySubItems.length > 0) || addedToShopify}
                  />
                </Form.Group>
              </CustomTooltip>
            </Col>
          </Row>
          <Row className='my-3'>
            {/* <Col>{price !== 0 && <DeliveryDate />}</Col> */}
          </Row>
        </Col>
      )}
    </Row>
  );
};

const mapStateToProps = (state) => ({
  price: state.create.price.price.totalPrice,
  priceIsLoading:
    state.create.price.isFetching ||
    state.create.jewelry.isLoading.modelIsLoading,
  quantity: state.create.jewelry.quantity,
  libraryOpen: state.create.jewelry && state.create.jewelry.libraryOpen,
  creditCardOpen: state.create.jewelry && state.create.jewelry.creditCardOpen,
  designWithUsModalOpen:
    state.create.jewelry && state.create.jewelry.designWithUsModalOpen,
  new_order: state.create.jewelry.new_order,
  assemblySubItems:
    state.create.jewelry && state.create.jewelry.assemblySubItems,
  itemType: state.create.jewelry.itemType,
  addedToShopify: state.create.jewelry.addedToShopify,
});

const mapDispatchToProps = (dispatch) => ({
  setQuantity: (quantity) => dispatch(setQuantity(quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PriceWidget);
