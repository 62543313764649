import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import Option from './options';
import { removeAssemblyOption } from '../../../state/create/jewelry/actions';
import { setAssemblyOption } from '../../../state/create/jewelry/thunks';
import noImage from '../../../images/noimage.jpg';
import { showMinQty } from '../Material';
import { assemblySubPrice } from '../../../utils/helpers';

const AssemblyContent = ({
  itemType,
  assemblyOption,
  assemblyOptions,
  total,
  quantity,
  priceIsLoading,
  getPrice,
  setAssemblyOption,
  removeAssemblyOption,
  membership,
  productionPage,//
  estimatePricing,//
  material,
  polish,
  plating,
  laserEngravingOption,
  productChosen,//
  setAssemblyPrice,//
  totalProductPrice,//
  assemblySubItems,//
  assemblySubStep,
  currentItem,
  setAssemblySubItems,
  step,
  getPriceAssembly,
  assemblySurface,
  assemblyVolume,
  styleAsmb
}) => {
  let title = 'Select A Chain Type';

  let Disclaimer = ({ msg }) => <p className='disclamer'>{msg}</p>;

  if (itemType.name.toLowerCase() === 'earring') {
    title = 'Select An Assembly Option';
    Disclaimer = () => null;
  }

  const assemblySubTotal = assemblySubPrice(assemblySubItems);
  const priceTotalAssemblys = total.totalPrice + Number(assemblySubTotal);

  return (
    <>
      <Row id={styleAsmb ? 'assembly-sub-items' : 'my-row-tour'} className={'d-flex flex-column flex-nowrap'}>
        <p>{title}</p>
        {assemblyOptions &&
          assemblyOptions
            .filter((assembly) => assembly.item.id === itemType.id)
            .map(({ variant }) => {
              const assemblyQty = variant.min_qty
                ? JSON.parse(variant.min_qty)
                : null;

              const mess = showMinQty(assemblyQty, variant, membership, true);
              return (
                <Option
                  key={variant.id}
                  title={variant.name}
                  picture={variant.image ? variant.image : noImage}
                  description={variant.description}
                  isSelected={assemblySubStep ? currentItem ? variant == currentItem.assembly : false : variant == assemblyOption}
                  min_qty={variant.min_qty}
                  mess={mess}
                  quantity={quantity}
                  onSelect={() => {
                    if (assemblySubStep) {
                      if (assemblySubItems && assemblySubItems.length > 0) {
                        if (currentItem && currentItem.id === step) {
                          const newArr = assemblySubItems.map((it) => {
                            if (it.id === step) {
                              return {
                                ...it,
                                assembly: variant
                              };
                            } else {
                              return it
                            }
                          });
                          setAssemblySubItems(newArr);
                        } else {
                          setAssemblySubItems([
                            ...assemblySubItems,
                            {
                              id: step,
                              assembly: variant
                            },
                          ]);
                        }
                      }
                      getPriceAssembly({
                        surface_area: assemblySurface,
                        volume: assemblyVolume,
                      });
                    } else {
                      if (variant == assemblyOption) {
                        removeAssemblyOption();
                        getPrice();
                        return;
                      }
                      setAssemblyOption(variant);
                      getPrice();
                      if (productionPage) {
                        estimatePricing(
                          itemType,
                          material,
                          plating,
                          polish,
                          assemblyOption,
                          laserEngravingOption,
                          quantity
                        );
                      } else if (productChosen) {
                        setAssemblyPrice(variant ? variant.base_price : 0);
                      }
                    }
                  }}
                />
              );
            })}
      </Row>
      <Disclaimer msg='* clasps are included with the necklace assembly' />
      {!productionPage && (
        <>
          <Row>
            <Col>
              <p>Price</p>
            </Col>
            <Col>
              <p style={{ textAlign: 'right' }}>
                USD
                {priceIsLoading ? (
                  <i className='fas fa-circle-notch fa-spin' />
                ) : (
                  <>
                    $
                    {productChosen
                      ? totalProductPrice.toFixed(2)
                      : priceTotalAssemblys.toFixed(2)}
                  </>
                )}
              </p>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  total: state.create.price.price,
  jewelry: state.create.jewelry,
  itemType: state.create.jewelry.itemType,
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  laserEngravingOption: state.create.jewelry.laserEngravingOption,
  assemblyOption: state.create.jewelry.assemblyOption,
  // assemblyOptions: state.itemConfigs.assemblyOptions,
  assemblyOptions:
    state.itemConfigs.configs &&
    state.itemConfigs.configs.length !== 0 &&
    state.itemConfigs.configs.filter(
      (itemConfig) => itemConfig.subItemId === 4
    ),
  membership:
    state.login.auth.profile.membership &&
      state.login.auth.profile.membership.nickname
      ? state.login.auth.profile.membership.nickname
      : state.login.auth.profile.membership.name,
  quantity: state.create.jewelry.quantity,
});

const mapDispatchToProps = (dispatch) => ({
  setAssemblyOption: (option) => dispatch(setAssemblyOption(option)),
  removeAssemblyOption: () => dispatch(removeAssemblyOption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyContent);
