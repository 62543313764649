import React, { useState } from 'react';
import { Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../../../scss/_assembly.scss';

const Assembly = ({
  description,
  title,
  picture,
  isSelected,
  onSelect,
  quantity,
  mess,
}) => {
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => setReadMore(!readMore);

  const readMoreText = readMore ? 'Read Less' : 'Read More';

  const AssemblyOption = ({ disabled = false }) => (
    <Button
      disabled={disabled}
      variant='outline-secondary'
      className='option'
      onClick={onSelect}
    >
      <Row>
        <Col style={{ flexGrow: 0 }}>
          {isSelected ? (
            <div>
              <img src='/images/checkbox.svg' />
            </div>
          ) : (
            <div className='circle' />
          )}
        </Col>
        <Col style={{ flexGrow: 1 }}>
          <p style={{ margin: 0 }}>{title}</p>
        </Col>
        <Col className='picture'>
          <img src={picture} />
        </Col>
      </Row>
      {description && (
        <div onClick={toggleReadMore}>
          <div className='read-more-text' style={{ color: '#7c6262' }}>
            {readMoreText}
          </div>
          <div>{readMore && description}</div>
        </div>
      )}
    </Button>
  );

  if (mess && Number(quantity) < Number(mess.minQuantity)) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id='tooltip-bottom'>{mess.message}</Tooltip>}
      >
        <div>
          <AssemblyOption disabled={true} />
        </div>
      </OverlayTrigger>
    );
  } else {
    return <AssemblyOption />;
  }
};

export default Assembly;
