import React from 'react';

const Slider = ({ currentStep, children, direction }) => {
  const child = React.Children.map(children, (child, ndx) => {
    let state = 'hidden';
    if (ndx === currentStep) {
      state = 'in-view';
    } else if (ndx === currentStep - 1) {
      state = `previous`;
    } else if (ndx === currentStep + 1) {
      state = `next`;
    }
    const className = `slide ${state}`;
    return <div className={className}>{child}</div>;
  });

  const sliderDirection = direction || 'left';
  const className = `slider ${sliderDirection}`;

  return <div className={className}>{child}</div>;
};

export default Slider;
