import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import SalesTax from 'sales-tax';
import CountryDropdown from '../CountryDropdown';
import StateDropdown from '../StateDropdown';
import ShippingMethods from './ShippingMethods';
import { useSearchDebounce } from './ShippingInfo';

const ShippingInfoForm = ({
  values,
  onChange,
  handleChange,
  setShippingValues,
  setValues,
  handleBlur,
  errors,
  touched,
  shipping,
  updateSalesTax,
  cart,
  setFieldValue,
  shippoId,
  setShippoId,
  setShippingRate,
  setShippoMethod,
  setShippoToken,
  setShippoCarrier,
  setShippoEstimatedDelivery,
  cartItems,
  phone,
  shippingRates,
  rateFetched,
  setFetchRates,
  setRates,
  profile,
  type,
  shipTo,
}) => {
  const [addressChange, setAddressChange] = useState(false);
  const [countryChange, setCountryChange] = useState(false);
  const [shippingAddresses, setShippingAddress] = useState({
    address: null,
    city: null,
    zipcode: null,
    state: null,
    country: null,
  });

  const isValid = (fieldName) => {
    return !errors[fieldName] && touched[fieldName];
  };
  const isInvalid = (fieldName) => {
    return errors[fieldName] && touched[fieldName];
  };

  const addressInput = useSearchDebounce(shippingAddresses.address, 500);
  const cityInput = useSearchDebounce(shippingAddresses.city, 500);

  useEffect(() => {
    setShippingAddress({
      ...shippingAddresses,
      address: addressInput,
    });
    setShippingValues({ ...values, address: addressInput });
  }, [addressInput]);
  useEffect(() => {
    setShippingAddress({
      ...shippingAddresses,
      city: cityInput,
    });
    setShippingValues({ ...values, city: cityInput });
    setTimeout(() => {
      setAddressChange(true);
    }, [2800]);
  }, [cityInput]);
  

  useEffect(() => {
    if (Object.keys(shipping).length === 0) {
      setValues({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        country: profile.address.country,
        state: profile.address.state,
        address: `${profile.address.address1} ${profile.address.address2 ? `,${profile.address.address2}` : ''
          }`,
        city: profile.address.city,
        zipcode: profile.address.zipcode,
        phone: profile.phone,
      });
    } else {
      setValues({ ...values, ...shipping });
    }
  }, [shipping]);

  useEffect(() => {
    setShippingValues(values);
    setShippingAddress({
      address: values.address,
      city: values.city,
      zipcode: values.zipcode,
      state: values.state,
      country: values.country,
    });
  }, [values]);

  const shippingAddress =
    profile.address.address1 + ', ' + profile.address.address2;

  useEffect(() => {
    if (shipTo === 'shipToMe') {
      onChange('firstName', profile.firstName);
      onChange('lastName', profile.lastName);
      onChange('email', profile.email);
      onChange('country', profile.address.country);
      onChange('address', shippingAddress);
      onChange('city', profile.address.city);
      onChange('state', profile.address.state);
      onChange('zipcode', profile.address.zipcode);
      onChange('phone', profile.phone);
    } else {
      onChange('billing_firstName', profile.firstName);
      onChange('billing_lastName', profile.lastName);
      onChange('billing_email', profile.email);
      onChange('billing_country', profile.address.country);
      onChange('billing_address', shippingAddress);
      onChange('billing_city', profile.address.city);
      onChange('billing_state', profile.address.state);
      onChange('billing_zipcode', profile.address.zipcode);
      onChange('billing_phone', profile.phone);
      onChange('firstName', '');
      onChange('lastName', '');
      onChange('email', '');
      onChange('country', undefined);
      onChange('address', '');
      onChange('city', '');
      onChange('state', undefined);
      onChange('zipcode', '');
      onChange('phone', '');
    }
  }, [shipTo]);
  const st = {
    height: '2.3rem',
  };

  return (
    <Form style={{ marginTop: '-1rem' }}>
      <Form.Group controlId='firstName'>
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type='text'
          placeholder='First Name'
          value={
            type === 'shipping' ? values.firstName : values.billing_firstName
          }
          name={type === 'shipping' ? 'firstName' : 'billing_firstName'}
          isValid={isValid(
            type === 'shipping' ? 'firstName' : 'billing_firstName'
          )}
          isInvalid={isInvalid(
            type === 'shipping' ? 'firstName' : 'billing_firstName'
          )}
          onChange={(e) => {
            onChange('firstName', e.target.value);
            handleChange(e);
            setShippingValues({ ...values, firstName: e.target.value });
          }}
          onBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.firstName}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='lastName'>
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type='text'
          placeholder='Last Name'
          value={
            type === 'shipping' ? values.lastName : values.billing_lastName
          }
          name={type === 'shipping' ? 'lastName' : 'billing_lastName'}
          isValid={isValid(
            type === 'shipping' ? 'lastName' : 'billing_lastName'
          )}
          isInvalid={isInvalid(
            type === 'shipping' ? 'lastName' : 'billing_lastName'
          )}
          onChange={(e) => {
            onChange('lastName', e.target.value);
            handleChange(e);
            setShippingValues({ ...values, lastName: e.target.value });
          }}
          onBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='email'>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type='text'
          name={type === 'shipping' ? 'email' : 'billing_email'}
          placeholder='Email Address'
          isValid={isValid(type === 'shipping' ? 'email' : 'billing_email')}
          isInvalid={isInvalid(type === 'shipping' ? 'email' : 'billing_email')}
          value={type === 'shipping' ? values.email : values.billing_email}
          onChange={(e) => {
            onChange('email', e.target.value);
            handleChange(e);
            setShippingValues({ ...values, email: e.target.value });
          }}
          onBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='phone'>
        <Form.Label>Phone</Form.Label>
        <Form.Control
          type='text'
          name={type === 'shipping' ? 'phone' : 'billing_phone'}
          placeholder='Phone number'
          isValid={isValid(type === 'shipping' ? 'phone' : 'billing_phone')}
          isInvalid={isInvalid(type === 'shipping' ? 'phone' : 'billing_phone')}
          value={type === 'shipping' ? values.phone : values.billing_phone}
          onChange={(e) => {
            onChange('phone', e.target.value);
            handleChange(e);
            setShippingValues({ ...values, phone: e.target.value });
          }}
          onBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.phone}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='country' className='countries'>
        <Form.Label>Country</Form.Label>
        <CountryDropdown
          name={type === 'shipping' ? 'country' : 'billing_country'}
          value={
            type === 'shipping'
              ? Number(values.country)
              : Number(values.billing_country)
          }
          isValid={isValid(type === 'shipping' ? 'country' : 'billing_country')}
          isInvalid={isInvalid(
            type === 'shipping' ? 'country' : 'billing_country'
          )}
          onChange={(e) => {
            onChange('zipcode', '');
            setShippingAddress({
              ...shippingAddresses,
              country: e.target.value,
            });
            onChange('country', e.target.value);
            handleChange(e);
            setShippingValues({ ...values, country: e.target.value });
          }}
          handleBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.country}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='address'>
        <Form.Label>
          {type === 'shipping' ? 'Shipping Address' : 'Billing Address'}
        </Form.Label>
        <Form.Control
          type='text'
          placeholder='Street Address'
          name={type === 'shipping' ? 'address' : 'billing_address'}
          isValid={isValid(type === 'shipping' ? 'address' : 'billing_address')}
          isInvalid={isInvalid(
            type === 'shipping' ? 'address' : 'billing_address'
          )}
          value={type === 'shipping' ? values.address : values.billing_address}
          onChange={(e) => {
            onChange('address', e.target.value);
            onChange('city', '');
            handleChange(e.target.value);
          }}
          onBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.street}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId='city'>
        <Form.Label>City</Form.Label>
        <Form.Control
          type='text'
          placeholder='City'
          name={type === 'shipping' ? 'city' : 'billing_city'}
          isValid={isValid(type === 'shipping' ? 'city' : 'billing_city')}
          isInvalid={isInvalid(type === 'shipping' ? 'city' : 'billing_city')}
          value={type === 'shipping' ? values.city : values.billing_city}
          onChange={(e) => {
            onChange('city', e.target.value);
            handleChange(e);
          }}
          onBlur={handleBlur}
          style={st}
        />
        <Form.Control.Feedback type='invalid'>
          {errors.city}
        </Form.Control.Feedback>
      </Form.Group>
      <div className='d-flex justify-content-between'>
        <Form.Group controlId='state' className='states'>
          <Form.Label>State</Form.Label>
          <StateDropdown
            id={type === 'shipping' ? values.country : values.billing_country}
            name={type === 'shipping' ? 'state' : 'billing_state'}
            value={
              type === 'shipping'
                ? Number(values.state)
                : Number(values.billing_state)
            }
            isValid={isValid(type === 'shipping' ? 'state' : 'billing_state')}
            isInvalid={isInvalid(
              type === 'shipping' ? 'state' : 'billing_state'
            )}
            onChange={async (e) => {
              setShippingAddress({
                ...shippingAddresses,
                state: e.target.value,
              });
              onChange('state', e.target.value);
              handleChange(e);
              setShippingValues({ ...values, state: e.target.value });
              if (!profile.isTaxExempt) {
                const tax = await SalesTax.getSalesTax(
                  'US',
                  e.target[e.target.selectedIndex].getAttribute('tax')
                );
                const total = cartItems.reduce(
                  (acc, item) => acc + Number(item.price.totalPrice.toFixed(2)),
                  0
                );
                updateSalesTax(total * tax.rate);
              } else {
                updateSalesTax(0.0);
              }
            }}
            handleBlur={handleBlur}
            style={st}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.state}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId='zip'>
          <Form.Label>Zip</Form.Label>
          <Form.Control
            type='text'
            name={type === 'shipping' ? 'zipcode' : 'billing_zipcode'}
            value={
              type === 'shipping' ? values.zipcode : values.billing_zipcode
            }
            isValid={isValid(
              type === 'shipping' ? 'zipcode' : 'billing_zipcode'
            )}
            isInvalid={isInvalid(
              type === 'shipping' ? 'zipcode' : 'billing_zipcode'
            )}
            onChange={(e) => {
              setTimeout(() => {
                setCountryChange(true);
              }, [2000]);
              setShippingAddress({
                ...shippingAddresses,
                zipcode: e.target.value,
              });
              onChange('zipcode', e.target.value);
              handleChange(e);
              setShippingValues({ ...values, zipcode: e.target.value });
            }}
            onBlur={handleBlur}
            style={st}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.zipcode}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      {type === 'shipping' && values.state && values.country && values.zipcode && (
        <>
          <Form.Group controlId='shippingMethod'>
            <Form.Label>Shipping Options</Form.Label>
            <ShippingMethods
              name='shippingMethod'
              value={null}
              isValid={isValid('shippingMethod')}
              isInvalid={isInvalid('shippingMethod')}
              onChange={(e) => {
                setFieldValue('shippingMethod', e.target.value);
                onChange('shippingMethod', e.target.value);
                setShippoId(e.target.value);
                handleChange(e);
                setShippingRate(
                  e.target[e.target.selectedIndex].getAttribute('price')
                );
                setShippoMethod(
                  e.target[e.target.selectedIndex].getAttribute('serviceName')
                );
                setShippoToken(
                  e.target[e.target.selectedIndex].getAttribute('token')
                );
                setShippoCarrier(
                  e.target[e.target.selectedIndex].getAttribute('carrier')
                );
                setShippoEstimatedDelivery(
                  e.target[e.target.selectedIndex].getAttribute(
                    'estimatedDelivery'
                  )
                );
              }}
              onBlur={handleBlur}
              values={values}
              items={cartItems}
              phone={phone}
              addressChange={addressChange}
              setAddressChange={setAddressChange}
              countryChange={countryChange}
              setCountryChange={setCountryChange}
              shippingAddresses={shippingAddresses}
              shippingRates={shippingRates}
              rateFetched={rateFetched}
              setFetchRates={setFetchRates}
              setRates={setRates}
            />
            {errors && errors.shippingMethod && (
              <Form.Control.Feedback
                type='invalid'
                style={{ display: 'block' }}
              >
                You must select shipping method
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId='formBasicChecbox'>
            <Form.Check
              type='checkbox'
              className='text-primary'
              label={
                <>
                  I have read and agree to the{' '}
                  <a
                    target='__blank'
                    href='/terms-of-service'
                    className='font-weight-bold'
                  >
                    Terms of Service{' '}
                  </a>
                  and{' '}
                  <a
                    target='__blank'
                    href='/privacy-policy'
                    className='font-weight-bold'
                  >
                    Privacy Policy
                  </a>
                </>
              }
              isValid={isValid('didLegal')}
              isInvalid={isInvalid('didLegal')}
              name='didLegal'
              checked={values.didLegal}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors && errors.didLegal && (
              <Form.Control.Feedback
                type='invalid'
                style={{ display: 'block' }}
              >
                You must agree to our Terms of Service and Privacy Policy
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </>
      )}
    </Form>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.checkout.shipping,
  profile: state.login.auth.profile,
});

export default connect(mapStateToProps)(ShippingInfoForm);
