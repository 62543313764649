import React, { useState } from 'react';

export default function Tooltip({
  children,
  text,
  show,
  priceTooltip,
  setShowQtyNotification,
  ...rest
}) {
  const [hide, setHide] = useState(false);
  const showTool = priceTooltip ? (show) : (show && !hide ? true : false);
  return (
    <div>
      <div className={showTool ? 'tooltip-custom' : 'hide-tooltip'}>
        {text}
        <span className='tooltip-arrow' />
        <i
          className='fa fa-times del'
          onClick={() => {
            if (priceTooltip) {
              setShowQtyNotification(false);
            } else {
              setHide(true);
            }
          }}
        />
      </div>
      <div
        {...rest}
        // onMouseEnter={() => setShow(true)}
        // onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
    </div>
  );
}
