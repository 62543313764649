import React, { useRef, useState } from 'react';
import { Row, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { removeAssemblyOption } from '../../state/create/jewelry/actions';
import { setAssemblyOption } from '../../state/create/jewelry/thunks';
import { getPrice } from '../../state/create/jewelry/thunks';
import FileUploader from '../FileUpload';
import uuid from 'uuid/v4';
import Model from '../model';
import { uploadFile } from '../../utils/api';
import AssemblyContent from './assembly_options/AssemblyContent';

const SubItem = ({
  setAssemblySubItems,
  assemblySubItems,
  step,
  openDesignLibraryModal,
  openModalLibrary,
  onFileLoad2,
  chosen,
  uploadAssemblyFile,
  setChosenPr,
  assemblyFile,
  assemblyFileName,
  getPriceAssembly,
  setPriceLoading,
  token,
  selectLoading,
  priceLoading,
  assemblyVolume,
  assemblySurface,
  qt,
  setQt,
  setShowError,
  setAssemblyPrice,
  totalProductPrice,
  priceIsLoading,
  getPrice
}) => {
  const [fileLoad, setFileLoad] = useState(false);

  const fileReader =
    typeof window !== `undefined`
      ? new FileReader()
      : { readAsArrayBuffer: (_file) => { } };

  const handleFileUpload = async (e) => {
    setChosenPr(false);
    setFileLoad(true);
    const files = e.currentTarget.files;
    const cartId = `${uuid()}-${uuid()}`;

    if (files.length === 0) return;
    if (files.length > 1) {
      setShowError('Too many files were selected');
      return;
    }

    const toAdd = {
      id: step,
      file: { name: e.currentTarget.files[0].name },
      mesh_folder: cartId,
    };
    if (assemblySubItems && assemblySubItems.length > 0) {
      if (currentItem && currentItem.id === step) {
        const newArr =
          assemblySubItems &&
          assemblySubItems.map((it) => {
            if (it.id === step) {
              return {
                ...it,
                file: { name: e.currentTarget.files[0].name },
                mesh_folder: cartId,
                surface_area: assemblySurface,
                volume: assemblyVolume,
              };
            }
          });
        setAssemblySubItems(newArr);
      } else {
        setAssemblySubItems([
          ...assemblySubItems,
          {
            ...toAdd,
            surface_area: assemblySurface,
            volume: assemblyVolume,
          },
        ]);
      }
    } else {
      setAssemblySubItems([
        ...assemblySubItems,
        {
          id: step,
          file: { name: e.currentTarget.files[0].name },
          mesh_folder: cartId,
          surface_area: assemblySurface,
          volume: assemblyVolume,
        },
      ]);
    }

    fileReader.onload = (e) => {
      uploadAssemblyFile(files[0].name, e.target.result);
    };
    fileReader.readAsArrayBuffer(files[0]);

    const data = {
      id: cartId,
      file: e.currentTarget.files[0],
    };

    const uploaded = await uploadFile(data, token);
    setFileLoad(false);
  };

  const currentItem =
    assemblySubItems &&
    assemblySubItems.length > 0 &&
    assemblySubItems.find((item) => item.id === step);

  const modelRef = useRef();

  return (
    <>
      <Row>
        <div className='spread-title'>
          <div className='spread-first'>Upload your 3d model</div>
          <div className='subVariant-upload-options'>
            <FileUploader
              fileExtensions={['.stl', '.glTF', '.obj', '.st']}
              onFileLoad={(e) => {
                chosen ? onFileLoad2(e) : handleFileUpload(e);
              }}
              styleSub={true}
            />
            <Button
              variant='btn-primary'
              className='btn-primary choose-from-library'
              onClick={() => {
                openModalLibrary(true);
                openDesignLibraryModal(true);
              }}
            >
              Choose from Gildform Library
            </Button>
          </div>
        </div>
      </Row>
      <Row className='mt-2 mb-2'>
        {assemblySubItems.length > 0 &&
          assemblySubItems.find((item) => item.id === step) && (
            <span
              style={{
                backgroundColor: '#cfc6c6',
                color: '#000',
                borderRadius: '5px',
                padding: '5px 5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {currentItem && currentItem.file && currentItem.file.name}
              <i
                className='fa fa-times del-tag-icon ml-2'
                onClick={() => {
                  const newArr =
                    assemblySubItems &&
                    assemblySubItems.filter((item) => item.id !== step);
                  setAssemblySubItems(newArr);
                }}
              />
            </span>
          )}
      </Row>
      <Model
        forwardedRef={modelRef}
        file={assemblyFile}
        fileName={assemblyFileName}
        onError={() => { }}
        assemblyStep={true}
      />
      <Row>
        <Form.Group>
          <Form.Label style={{ fontWeight: 600 }}>Quantity:</Form.Label>
          <Form.Control
            disabled={!currentItem}
            style={{ textAlign: 'right' }}
            as='input'
            value={qt}
            onChange={(e) => {
              setPriceLoading(true);
              setQt(e.target.value);
              const newArr =
                assemblySubItems &&
                assemblySubItems.map((item) => {
                  if (item.id === step) {
                    return {
                      ...item,
                      quantity: Number(e.target.value),
                    };
                  } else {
                    return item;
                  }
                });
              setAssemblySubItems(newArr);
              if (currentItem) {
                if (!currentItem.product) {
                  getPriceAssembly({
                    surface_area: assemblySurface,
                    volume: assemblyVolume,
                  });
                }
              }
              setPriceLoading(false);
            }}
            type='number'
          />
        </Form.Group>
      </Row>
      <Row style={{ height: '110px' }}>
        <Form.Group>
          <Form.Label style={{ fontWeight: 600 }}>
            Describe Your Assembly:
          </Form.Label>
          <Form.Control
            id='desc-tour'
            as='textarea'
            // rows='3'
            onChange={(e) => {
              const newArr =
                assemblySubItems &&
                assemblySubItems.map((item) => {
                  if (item.id === step) {
                    return {
                      ...item,
                      description: e.target.value,
                    };
                  } else {
                    return item;
                  }
                });
              setAssemblySubItems(newArr);
            }}
            value={currentItem ? currentItem && currentItem.description : ''}
          />
        </Form.Group>
      </Row>
      {currentItem && currentItem.file && (
        <AssemblyContent
          productionPage={false}
          estimatePricing={() => { }}
          productChosen={false}
          setAssemblyPrice={setAssemblyPrice}
          totalProductPrice={totalProductPrice}
          assemblySubItems={assemblySubItems}
          assemblySubStep={true}
          currentItem={currentItem}
          setAssemblySubItems={setAssemblySubItems}
          step={step}
          getPriceAssembly={getPriceAssembly}
          assemblySurface={assemblySurface}
          assemblyVolume={assemblyVolume}
          styleAsmb={true}
          getPrice={getPrice}
          priceIsLoading={priceIsLoading}
        />
      )}
      <Row>
        <Form.Label style={{ fontWeight: 600 }}>
          Price: $
          <>
            {selectLoading || fileLoad || priceLoading ? (
              <>
                Calculating ... <i className='fas fa-circle-notch fa-spin' />
              </>
            ) : (
              (currentItem &&
                currentItem.price &&
                currentItem.price.totalPrice.toFixed(2)) ||
              0.0
            )}
          </>
        </Form.Label>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.login.auth && state.login.auth.auth.token,
  itemType: state.create.jewelry.itemType,
});

const mapDispatchToProps = (dispatch) => ({
  getPrice: () => dispatch(getPrice()),
  setAssemblyOption: (option) => dispatch(setAssemblyOption(option)),
  removeAssemblyOption: () => dispatch(removeAssemblyOption()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubItem);
