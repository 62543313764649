import Auth0Lock from '@httptoolkit/auth0-lock';
import Auth0 from '@auth0/auth0-spa-js';

let lock = null;
export const GetLock = (canClose) => {
  let closable = canClose;

  if (canClose === void 0) {
    closable = true;
  }

  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;
  let portStr = '';

  if (port) {
    portStr = `:${port}`;
  }

  const url = `${protocol}//${host}${portStr}/login`;

  lock = new Auth0Lock(
    'cccAjQgAGxN847Yv52s8OgX3lqiOFjbG',
    'gildform.auth0.com',
    {
      closable,
      auth: {
        params: {
          scope: 'openid profile email',
          responseType: 'code',
        },
        audience: 'https://api.gildform.com/',
        redirectUrl: `${url}?return_to=${window.location.pathname}`,
      },
    }
  );
  return lock;
};

export const GetAuth0 = async () => {
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;

  let portStr = '';

  if (port) {
    portStr = `:${port}`;
  }

  const url = `${protocol}//${host}${portStr}/login`;

  return await Auth0({
    domain: 'gildform.auth0.com',
    client_id: 'cccAjQgAGxN847Yv52s8OgX3lqiOFjbG',
    audience: 'https://api.gildform.com/',
    redirectUrl: `${url}?return_to=${window.location.pathname}`,
  });
};

export const CheckToken = (tok, showIfBad) => {
  return new Promise(async (res, reject) => {
    const Lock = GetLock();
    Lock.checkSession({ responseType: 'token' }, (err, authResult) => {
      if (!!err) {
        return reject(err);
      }
      return res(authResult);
    });
  });
};

export const ContinueAuth = (hash) => {
  return GetAuth0().then((auth0Client) => {
    return auth0Client.handleRedirectCallback().then(async () => {
      const token = await auth0Client.getTokenSilently();
      const user = await auth0Client.getUser();
      return { token, user };
    });
  });
};

export const ShowLock = () => {
  if (window === void 0) {
    return true;
  }
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;
  let portStr = '';
  if (port) {
    portStr = `:${port}`;
  }
  const url = `${protocol}//${host}${portStr}/login`;
  GetAuth0().then((auth0Client) => {
    auth0Client.loginWithRedirect({
      redirect_uri: `${url}?return_to=/dashboard`,
    });
  });
};

export default ShowLock;
