import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { StaticQuery, graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/Seo';
import StepList from '../components/StepList';
import PriceWidget from '../components/steps/PriceWidget';
import PrivateRoute from '../components/auth/protected';
import { fetchItemConfigs } from '../state/itemConfigs/thunks';
import { setMaterial, setPolish } from '../state/create/jewelry/thunks';
import 'react-toastify/dist/ReactToastify.css';
import Banner from '../components/Banner';
import {
  setHeight,
  setLength,
  setWidth,
} from '../state/create/jewelry/actions';
import WindowObject from '../components/windowObject';
import { getPrice as getPriceAPI } from '../utils/api';

const ProductionCalculator = ({
  fetchItemConfigs,
  setMaterial,
  setPolish,
  profile,
  setHeight,
  setWidth,
  setLength,
  modelHeight,
  modelLength,
  modelWidth,
  weight,
  quantity,
  itemType,
  material,
  plating,
  polish,
  assemblyOption,
  erpUser,
  authToken,
}) => {
  const modelRef = useRef();

  const fetchAllItemConfigs = async () => {
    const itemConfigs = await fetchItemConfigs();

    const variants = itemConfigs.map((itemConfig) => itemConfig.variant);

    const sterlingSilver = variants.find(
      (variant) => variant.code.toLowerCase() === 'ssa1'
    );

    const noPolish = variants.find(
      (variant) => variant.code.toLowerCase() === 'satin polish'
    );

    setMaterial(sterlingSilver);
    setPolish(noPolish);
  };

  useEffect(() => {
    fetchAllItemConfigs();
  }, []);

  useEffect(() => {
    if (document) {
      document.body.classList.add('gradient');
      return () => document.body.classList.remove('gradient');
    }
  }, []);

  const query = graphql`
    query {
      wordpressWpMessage(wordpress_id: { eq: 883 }) {
        id
        title
        content
      }
    }
  `;

  const [sellingPrice, setSellingPrice] = useState(null);
  const [qty, setQty] = useState(null);
  const [estimatedPrice, setPrice] = useState(null);
  const [priceLoading, setLoading] = useState(false);

  const windowObject = WindowObject();

  const productionPage =
    windowObject && windowObject.pathname.includes('calculat');

  useEffect(() => {
    if (modelHeight && modelWidth && modelLength) {
      setHeight(null);
      setWidth(null);
      setLength(null);
    }
  }, []);

  const submitToHubspot = async () => {
    const profitOutput =
      estimatedPrice &&
      Number(sellingPrice) * Number(qty) -
        Number(estimatedPrice && estimatedPrice.totalPrice) * Number(qty);

    const date = new Date();
    var data = {
      submittedAt: `${date.getTime()}`,
      fields: [
        {
          objectTypeId: '0-1',
          name: 'email',
          value: profile.email,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: profile.firstName,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: profile.lastName,
        },
        {
          objectTypeId: '0-1',
          name: 'item_type',
          value: itemType.name,
        },
        {
          objectTypeId: '0-1',
          name: 'item_material',
          value: material.name,
        },
        {
          objectTypeId: '0-1',
          name: 'plating',
          value: plating.name,
        },
        {
          objectTypeId: '0-1',
          name: 'polish',
          value: polish ? polish.name : '',
        },
        {
          objectTypeId: '0-1',
          name: 'assembly',
          value: assemblyOption ? assemblyOption.name : '',
        },
        {
          objectTypeId: '0-1',
          name: 'quantity',
          value: String(quantity),
        },
        {
          objectTypeId: '0-1',
          name: 'model_height',
          value: modelHeight,
        },
        {
          objectTypeId: '0-1',
          name: 'model_length',
          value: modelLength,
        },
        {
          objectTypeId: '0-1',
          name: 'model_width',
          value: modelWidth,
        },
        {
          objectTypeId: '0-1',
          name: 'model_weight',
          value: String(weight),
        },
        {
          objectTypeId: '0-1',
          name: 'production_cost',
          value: estimatedPrice
            ? String(estimatedPrice.totalPrice.toFixed(2))
            : '',
        },
        {
          objectTypeId: '0-1',
          name: 'selling_price',
          value: String(Number(sellingPrice).toFixed(2)),
        },
        {
          objectTypeId: '0-1',
          name: 'units',
          value: String(qty),
        },
        {
          objectTypeId: '0-1',
          name: 'revenue',
          value: String(Number(Number(sellingPrice) * Number(qty)).toFixed(2)),
        },
        {
          objectTypeId: '0-1',
          name: 'profit',
          value: String(Number(profitOutput).toFixed(2)),
        },
      ],
      context: {
        // hutk: hutl, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
        // pageUri: 'https://migration.gildform.com/onboarding',
        pageUri: `${window ? window.location.href : ''}`,
        pageName: 'Production Calculator',
      },
      legalConsentOptions: {
        // Include this object when GDPR options are enabled
        consent: {
          consentToProcess: true,
          text:
            'I agree to allow Gildform Company to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text:
                'I agree to receive marketing communications from Gildform Company.',
            },
          ],
        },
      },
    };

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/5994191/03c95834-36aa-481b-90f6-aae6581ef30d`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    );

    const dd = await res.json();
  };

  useEffect(() => {
    if (sellingPrice) {
      setTimeout(() => {
        submitToHubspot();
      }, 1800);
    }
  }, [qty, sellingPrice]);

  const estimatePricing = async (
    itemType,
    material,
    plating,
    polish,
    assemblyOption,
    laserEngravingOption,
    quantity
  ) => {
    setLoading(true);
    const onlyWeight = Number(weight) * 1000;
    const materialGravity = material ? material.gravity : 7;
    const volume = onlyWeight / Number(materialGravity);
    const surface_area =
      Number(modelWidth * modelHeight) +
      Number(modelWidth * modelLength) +
      Number(modelHeight * modelLength);

    const priceRequest = {
      customer: erpUser,
      item: itemType.id,
      material: material.id,
      plating: plating ? plating.id : null,
      polish: polish ? polish.id : null,
      assembly_option: assemblyOption ? assemblyOption.id : null,
      laser_option: laserEngravingOption ? laserEngravingOption.id : null,
      volume: volume,
      surface_area: 2 * surface_area,
      quantity: Number(quantity),
    };

    const priceRes = await getPriceAPI(priceRequest, authToken);
    setTimeout(() => {
      setPrice(priceRes);
      setLoading(false);
    }, 500);
  };

  return (
    <PrivateRoute showLock>
      {profile && !profile.answers ? <Banner /> : null}
      <Layout
        className='justify-content-end'
        style={{ transition: 'all 1.5s ease-in-out', opacity: '1' }}
      >
        <SEO title='Production Calculator' />
        <Row className='renderContainer'>
          <Col className='renderColumn'>
            {sellingPrice && qty && (
              <div className='prod-calc-header'>
                <span>
                  {`Let’s bring this vision to life, so that you can generate an
                additional $${Number(
                  Number(sellingPrice) * Number(qty)
                ).toFixed(2)} in income.`}
                </span>
              </div>
            )}
            <PriceWidget
              productionPage={productionPage}
              sellingPrice={sellingPrice}
              qty={qty}
              estimatedPrice={estimatedPrice}
              priceLoading={priceLoading}
            />
            {sellingPrice && qty && (
              <div
                style={{
                  width: '70%',
                  padding: '20px',
                }}
              >
                <div style={{ justifyContent: 'flex-start' }}>
                  <div style={{ fontSize: '20px', marginBottom: '7px' }}>
                    Already have the design complete?
                  </div>
                  <Link to='/model'>
                    <Button>Start Producing</Button>
                  </Link>
                </div>
              </div>
            )}
            <Row style={{ marginTop: '6rem' }}>
              <StaticQuery
                query={query}
                render={(data) => (
                  <p
                    style={{ marginLeft: '50px', marginRight: '50px' }}
                    dangerouslySetInnerHTML={{
                      __html: data.wordpressWpMessage.content,
                    }}
                  />
                )}
              />
            </Row>
          </Col>
          <StepList
            takeScreenShot={() => modelRef.current.takeScreenShot()}
            setQty={setQty}
            setSellingPrice={setSellingPrice}
            sellingPrice={sellingPrice}
            qty={qty}
            estimatedPrice={estimatedPrice}
            setPrice={setPrice}
            setHeight={setHeight}
            setLength={setLength}
            setWidth={setWidth}
            productionPage={productionPage}
            estimatePricing={estimatePricing}
            priceLoading={priceLoading}
          />
        </Row>
      </Layout>
    </PrivateRoute>
  );
};

const mapStateToProps = (state) => ({
  itemConfigs: state.itemConfigs.configs,
  file: state.create.jewelry.file,
  fileName: state.create.jewelry.file_name,
  isLoading: state.create.jewelry.isLoading.modelIsLoading,
  profile: state.login.auth && state.login.auth.profile,
  loadProgress: state.create.jewelry && state.create.jewelry.loadProcess,
  fileDimensionError:
    state.create.jewelry && state.create.jewelry.fileDimensionError,
  new_order: state.create.jewelry && state.create.jewelry.new_order,
  modelHeight: state.create.jewelry.model_height,
  modelWidth: state.create.jewelry.model_width,
  modelLength: state.create.jewelry.model_length,
  weight: state.create.jewelry.weight,
  quantity: state.create.jewelry.quantity,
  itemType: state.create.jewelry.itemType,
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  assemblyOption: state.create.jewelry.assemblyOption,
  authToken: state.login.auth && state.login.auth.auth.accessToken,
  erpUser: state.login.auth && state.login.auth.profile.erp_id,
});

const mapDispatchToProps = (dispatch) => ({
  fetchItemConfigs: () => dispatch(fetchItemConfigs()),
  setMaterial: (material) => dispatch(setMaterial(material)),
  setPolish: (polish) => dispatch(setPolish(polish)),
  setHeight: (data) => dispatch(setHeight(data)),
  setWidth: (data) => dispatch(setWidth(data)),
  setLength: (data) => dispatch(setLength(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductionCalculator);
