import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getStatesByCountry } from '../utils/api';

const StateDropdown = ({
  id,
  onChange,
  handleBlur,
  value,
  name,
  isInvalid,
  isValid,
}) => {
  const [states, setStates] = useState(null);
  const authToken = useSelector((state) => state.login.auth && state.login.auth.auth.accessToken);

  const getStates = async () => {
    const states = await getStatesByCountry(id, authToken);
    setStates(states);
  };

  useEffect(() => {
    if (id) {
      getStates()
    };
  }, [id]);

  return (
    <Form.Control
      as='select'
      name={name}
      value={value}
      onBlur={handleBlur}
      onChange={onChange}
      isValid={!!isValid}
      isInvalid={!!isInvalid}
    >
      <option value=''>Select a state</option>
      {states &&
        states.map((state) => (
          <option key={state.id} value={state.id} tax={0}>
            {state.name}
          </option>
        ))}
    </Form.Control>
  );
};

export default StateDropdown;
