import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Redirect } from '@reach/router';

import CheckJwt from './CheckJwtExpiration';
import { ShowLock } from '../../utils/auth0';
import { getUser } from '../../utils/api';
import { retrieveUser } from '../../state/login/thunks';

const RedirectComponent = () => {
  const [link, setLink] = useState(null);

  useEffect(() => {
    if (window !== void 0) {
      window.setTimeout(() => {
        setLink('/userLogin');
      }, 1000);
    }
  }, []);

  if (!!link) {
    return <Redirect noThrow to={decodeURIComponent(link)} />;
  }

  return null;
};

const PrivateRoute = (props) => {
  const shouldForceCreate =
    props.forceCreate === undefined ? true : props.forceCreate;
  // const validate = props.validate === undefined ? () => true : props.validate;

  if (props.accessToken) {
    useEffect(() => {
      props.getUser(props.profile ? props.profile.sub : null);
    }, []);
  }

  useEffect(() => {
    if (!props.accessToken) {
      if (ShowLock) {
        ShowLock(false);
      }
      return <RedirectComponent />;
      // ShowLock();//This was the normal/output
      // if (ShowLock) {
      //   ShowLock(false);
      // }
      // return () => {
      //   console.log('');
      // };
    }

    if (!props.profile || !props.profile.id) {
      getUser(props.profile ? props.profile.id : null)
        .then(() => true)
        .catch((e) => {
          navigate('/create');
        });
    }

    props.validate === undefined
      ? () => true
      : props.profile ? props.validate(props.profile.sub, props.accessToken, getUser) : null;
    // validate(props.userID, props.accessToken, getUser);
  }, [props.accessToken]);

  return <CheckJwt>{props.children}</CheckJwt>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  accessToken: state.login.auth && state.login.auth.auth.accessToken,
  profile: state.login.auth && state.login.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (userID) => dispatch(retrieveUser(userID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
