import React from 'react';
import { Link } from 'gatsby';

const Banner = () => {
  return (
    <div className='banner'>
      <div className='banner__content'>
        <div className='banner__text'>
          <p>Looks like you haven't completed your profile.</p>
          <Link className='title' to='/onboarding'>
            {' '}
            Click here to finish!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Banner;
