import React from 'react';
import { Row } from 'react-bootstrap';

const AssemblySubItemsList = ({
  assemblySubItems,
  setAssemblySubItems,
  step,
  setAssemblyStep,
  setStep,
  review,
}) => {
  return (
    assemblySubItems &&
    assemblySubItems.length > 0 && (
      <Row>
        <div>
          <div
            style={{
              fontSize: '17px',
              fontWeight: '600',
              marginBottom: '5px',
              textAlign: 'center',
              textDecoration: 'underline',
              marginTop: '10px',
              marginLeft: review ? '17px' : '1px',
            }}
          >
            {review ? 'Assembly Sub Items' : 'Added 3D Model Parts'}
          </div>
          {assemblySubItems.map((it) => {
            return (
              <div
                className={
                  step === it.id ? 'sub-item-assembly-sel' : 'sub-item-assembly'
                }
                onClick={() => {
                  if (!review) {
                    setStep(it.id);
                    setAssemblyStep(it.id);
                  }
                }}
              >
                <div>{it && it.file && it.file.name}</div>
                <div className='sub-item-price'>
                  ${it.price && Number(it.price.totalPrice).toFixed(2)}{' '} <span style={{marginLeft:'40px'}}>X {it.quantity ? it.quantity : 1}</span>
                </div>
                {!review && (
                  <i
                    className='fa fa-times del-tag-icon ml-2'
                    onClick={() => {
                      const newArr =
                        assemblySubItems &&
                        assemblySubItems.filter((item) => item.id !== it.id);
                      setAssemblySubItems(newArr);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </Row>
    )
  );
};

export default AssemblySubItemsList;
