import React from 'react';
import { connect } from 'react-redux';
import { Dropdown, Image } from 'react-bootstrap';

import DropdownLink from './DropdownLink';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { GetAuth0 } from '../../utils/auth0';
import { logout } from '../../state/login/action';
import warningIcon from '../../images/red-warning.png';
import { setOffersPurchased as setOffersPurchasedAction } from '../../state/shoppingCart/actions';

const MARKET_URL = process.env.GATSBY_MARKETPLACE_URL;

const ProfileHeader = ({
  isAuthenticated,
  profile,
  profilePicture,
  logout,
  setOffersPurchased,
}) => {
  if (!isAuthenticated) {
    return null;
  }

  const handleLogout = async () => {
    if (window) {
      const auth = await GetAuth0();
      auth.logout({
        returnTo: `${window.location.protocol}//${window.location.host}/`,
      });

      setTimeout(() => {
        logout();
        setOffersPurchased([]);
      }, 200);
    }
  };

  return (
    <li className='nav-item profile'>
      <Dropdown>
        <Dropdown.Toggle variant='link' className='nav-link'>
          <p>
            <Image
              src={profilePicture || ''}
              roundedCircle
              style={{ width: 30, height: 30 }}
            />
            <span className='user-name font-weight-bold'>
              {profile && (
                <>
                  {profile.subUser ? profile.subUserName : profile.nickname}
                </>
              )}
            </span>
          </p>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <DropdownLink to='/model'>Start A New Order</DropdownLink>
          <DropdownItem>
            <div
              style={{
                justifyContent: 'space-between',
              }}
              onClick={() => {
                if (window) {
                  window.open(`${MARKET_URL}/projects-dashboard`, '_blank');
                }
              }}
            >
              Design Projects
            </div>
          </DropdownItem>
          <DropdownLink to='/dashboard'>
            <div
              style={{
                justifyContent: 'space-between',
              }}
            >
              Dashboard
            </div>
          </DropdownLink>
          <DropdownLink to='/profile/edit'>
            <div
              style={{
                justifyContent: 'space-between',
              }}
            >
              Profile
              {profile && !profile.answers ? (
                <img
                  src={warningIcon}
                  alt='warningIcon'
                  style={{
                    width: '19px',
                    height: '19px',
                    marginBottom: '5px',
                    marginLeft: '10px',
                  }}
                />
              ) : null}
            </div>
          </DropdownLink>
          <DropdownLink to='/orders'>
            <div
              style={{
                justifyContent: 'space-between',
              }}
            >
              Orders
            </div>
          </DropdownLink>
          <Dropdown.Divider />
          <Dropdown.Item variant='danger' onClick={() => handleLogout()}>
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  profile: state.login.auth && state.login.auth.profile,
  profilePicture: state.login.auth && state.login.auth.auth.user.picture,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  setOffersPurchased: (data) => dispatch(setOffersPurchasedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileHeader);
