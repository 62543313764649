import React from 'react';
import {
  Form,
  Row,
  ToggleButton,
  ToggleButtonGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

import {
  setMaterial,
  setPlating,
  setPolish,
} from '../../state/create/jewelry/thunks';

const platingSubText =
  'Plating options are only available for certain material selections.';

const steps = [
  {
    title: 'Select a material',
    target: '#my-material-tour',
    content:
      'Choose the material you’d want your jewelry to be produced in. Be sure to select different material types to compare pricing. (Hint: To unlock Plating Options choose Sterling Silver, Yellow Brass or White Bronze as your base material)',
  },
  {
    title: 'Select a plating type',
    target: '#my-material-tour',
    content:
      'Pick a plating type for your jewelry products. (Hint: To achieve Gold Vermeil, make sure your base material is Sterling Silver and choose any of the gold plating types).',
  },
  {
    title: 'Select a polishing type',
    target: '#polish-tour',
    content: 'Choose from any of our polishing types for your products.',
  },
  {
    title: 'Specify your quantity!',
    target: '#unit-tour',
    content:
      'Enter your specified quantity for immediate volume-based pricing. Enter 50 units to save ~18% on each unit.',
  },
  {
    title: 'Continue',
    target: '#continue-tour',
    content: 'Click here to move on to the next step .',
  },
];

export const showMinQty = (qty, item, membership, assembly) => {
  let objMessage = { message: '', show: false, minQuantity: 0 };
  qty && qty.length !== 0
    ? qty.map((qt) => {
        if (
          String(qt.membership) ===
          String(assembly ? membership : membership && membership.name)
        ) {
          objMessage = {
            message: `To access ${
              assembly ? item.name : item.variant.name
            } you must order at least ${qt.minQuantity} pieces`,
            show: true,
            minQuantity: qt.minQuantity,
          };
        }
      })
    : null;
  return objMessage;
};

const SelectMaterialStep = ({
  itemType,
  material,
  plating,
  polish,
  quantity,
  materialOptions,
  platingOptions,
  polishOptions,
  setMaterial,
  setPlating,
  setPolish,
  paymentDetail,
  membership,
  estimatePricing,
  productionPage,
  productChosen,
  setMaterialPrice,
  setPlatingPrice,
  setPolishPrice,
  profile,
}) => {
  const PlatingOptions = ({ disabled }) => {
    return (
      <Row>
        <Form.Label>Plating</Form.Label>
        <Form.Group>
          <ToggleButtonGroup
            type='radio'
            className='btn-group-separate'
            name='plating'
            value={plating}
            onChange={(pt) => {
              setPlating(pt);
              if (productionPage) {
                estimatePricing(itemType, material, pt, polish, null, null, 1);
              } else if (productChosen) {
                setPlatingPrice(pt ? (pt.base_price ? pt.base_price : 0) : 0);
              }
            }}
          >
            {platingOptions
              .filter((plating) => plating.item.id === itemType.id)
              .map((plating) => {
                const platingQty = plating.variant.min_qty
                  ? JSON.parse(plating.variant.min_qty)
                  : null;
                const mess = showMinQty(platingQty, plating, membership, false);
                if (mess && Number(quantity) < Number(mess.minQuantity)) {
                  return (
                    <OverlayTrigger
                      key={plating.variant.id}
                      overlay={
                        <Tooltip id='tooltip-bottom'>{mess.message}</Tooltip>
                      }
                    >
                      <ToggleButton
                        type='radio'
                        disabled
                        variant='outline-dark'
                        key={plating.variant.id}
                        value={plating.variant}
                      >
                        {plating.variant.name}
                      </ToggleButton>
                    </OverlayTrigger>
                  );
                }

                return (
                  <ToggleButton
                    variant='outline-dark'
                    key={plating.variant.id}
                    value={plating.variant}
                  >
                    {plating.variant.name}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </Form.Group>
        {platingSubText}
      </Row>
    );
  };

  return (
    <>
      <Row>
        <Joyride
          steps={steps}
          continuous={true}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#7c6262',
              overlayColor: 'rgba(0, 0, 0, 0.3)',
            },
          }}
          showProgress={true}
          showSkipButton={true}
        />
        <Form.Label>Select a material</Form.Label>
        <Form.Group id='my-material-tour'>
          <ToggleButtonGroup
            type='radio'
            className='btn-group-separate'
            name='material'
            value={material}
            onChange={(mt) => {
              setMaterial(mt);
              if (productionPage) {
                estimatePricing(itemType, mt, plating, polish, null, null, 1);
              } else if (productChosen) {
                setMaterialPrice(mt.base_price ? mt.base_price : 0);
              }
            }}
          >
            {materialOptions
              .filter((material) => material.item.id === itemType.id)
              .map((material) => {
                const materialQty = material.variant.min_qty
                  ? JSON.parse(material.variant.min_qty)
                  : null;
                const mess = showMinQty(
                  materialQty,
                  material,
                  membership,
                  false
                );
                const mtNew =
                  material.variant.id === 77 || material.variant.id === 78;
                const cstDsbl = material.variant.customers
                  ? JSON.parse(material.variant.customers)
                  : [];
                const dds = mtNew
                  ? cstDsbl.length > 0 && profile
                    ? cstDsbl.find(
                        (cs) =>
                          cs.id === profile.id ||
                          cs.name.trim() ===
                            `${profile.firstName.trim()} ${profile.lastName.trim()}`
                      )
                      ? false
                      : true
                    : true
                  : false;
                if (mess && Number(quantity) < Number(mess.minQuantity)) {
                  return (
                    <OverlayTrigger
                      key={material.id}
                      overlay={
                        <Tooltip id='tooltip-bottom'>{mess.message}</Tooltip>
                      }
                    >
                      <ToggleButton
                        disabled={mtNew ? (dds ? dds : mess.show) : true}
                        type='radio'
                        variant='outline-dark'
                        key={material.variant.id}
                        value={material.variant}
                        // checked={}
                      >
                        {material.variant.name}
                      </ToggleButton>
                    </OverlayTrigger>
                  );
                }
                return (
                  <ToggleButton
                    variant='outline-dark'
                    key={material.variant.id}
                    value={material.variant}
                    disabled={dds}
                  >
                    {material.variant.name}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </Form.Group>
      </Row>
      {platingOptions.length > 0 && material && (
        <PlatingOptions disabled={!material} />
      )}
      <Row>
        <Form.Label>Polish</Form.Label>
        <Form.Group id='polish-tour'>
          <ToggleButtonGroup
            type='radio'
            className='btn-group-separate'
            name='polish'
            value={polish}
            onChange={(pl) => {
              setPolish(pl);
              if (productionPage) {
                estimatePricing(itemType, material, plating, pl, null, null, 1);
              } else if (productChosen) {
                setPolishPrice(pl.base_price ? pl.base_price : 0);
              }
            }}
          >
            {polishOptions
              .filter((polish) => polish.item.id === itemType.id)
              .map((polish) => {
                const polishQty = polish.variant.min_qty
                  ? JSON.parse(polish.variant.min_qty)
                  : null;
                const mess = showMinQty(polishQty, polish, membership, false);
                if (mess && Number(quantity) < Number(mess.minQuantity)) {
                  return (
                    <OverlayTrigger
                      key={polish.variant.id}
                      overlay={
                        <Tooltip id='tooltip-bottom'>{mess.message}</Tooltip>
                      }
                    >
                      <ToggleButton
                        disabled
                        type='radio'
                        variant='outline-dark'
                        key={polish.variant.id}
                        value={polish.variant}
                      >
                        {polish.variant.name}
                      </ToggleButton>
                    </OverlayTrigger>
                  );
                }
                return (
                  <ToggleButton
                    variant='outline-dark'
                    key={polish.variant.id}
                    value={polish.variant}
                  >
                    {polish.variant.name}
                  </ToggleButton>
                );
              })}
          </ToggleButtonGroup>
        </Form.Group>
      </Row>
      <Row>{paymentDetail}</Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemType: state.create.jewelry.itemType,
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  materialOptions: state.itemConfigs.configs.filter(
    (itemConfig) => itemConfig.subItemId === 1
  ),
  platingOptions: state.itemConfigs.configs.filter(
    (itemConfig) => itemConfig.subItemId === 2
  ),
  polishOptions: state.itemConfigs.configs.filter(
    (itemConfig) => itemConfig.subItemId === 3
  ),
  quantity: state.create.jewelry.quantity,
  membership:
    state.login.auth.profile.membership && state.login.auth.profile.membership,
  profile: state.login.auth && state.login.auth.profile,
});

const mapDispatchToProps = (dispatch) => ({
  setMaterial: (material) => dispatch(setMaterial(material)),
  setPlating: (plating) => dispatch(setPlating(plating)),
  setPolish: (polish) => dispatch(setPolish(polish)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectMaterialStep);
