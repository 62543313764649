import React from 'react';
import { Form } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Joyride from 'react-joyride';

const steps = [
  {
    title: 'Design your jewelry',
    target: '#button-d-tour',
    content:
      'Click here to get started with turning your jewelry visions into 3D dimensions',
  },
  {
    title: 'Already have 3D models? ',
    target: '#upload-tour',
    content:
      'Great! Click here to upload your 3D models to get started on production and continue to the next step.',
  },
  {
    title: 'Define your jewelry type.',
    target: '#dropdown-custom-1',
    content:
      "After uploading your 3D model, define the type of jewelry you’ve uploaded. Choose 'Other', if you do not see your jewelry type specified.",
  },
  {
    title: 'Describe your jewelry peice',
    target: '#desc-tour',
    content:
      'Use this space to give as much detail as possible about your design to ensure that your products are created to your vision. The more information the better! ',
  },
];

export default class FormUpload extends React.Component {
  constructor(props) {
    super(props);
    this.formControl = React.createRef();
  }

  render() {
    const { fileExtensions, onFileLoad, styleSub } = this.props;

    return (
      <Dropzone onDrop={(files) => onFileLoad({ currentTarget: { files } })}>
        {({ getRootProps, getInputProps }) => (
          <>
            <Joyride
              steps={steps}
              continuous={true}
              styles={{
                options: {
                  zIndex: 10000,
                  primaryColor: '#7c6262',
                  overlayColor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
              showProgress={true}
              showSkipButton={true}
            />
            <Form.Label
              className={`d-flex flex-column align-content-center justify-content-center ${styleSub ? 'file-upload-sub' : 'file-upload'}`}
              id='upload-tour'
              ref={this.formControl}
              onDrag={(e) => e.preventDefault()}
              {...getRootProps()}
            >
              <Form.Control
                style={{ display: 'none' }}
                type='file'
                accept={fileExtensions.join(',')}
                onChange={onFileLoad}
                onDrag={(e) => e.preventDefault()}
                {...getInputProps()}
              />
              {styleSub ? (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                  <img src='/images/upload.svg' alt='Upload icon' style={styleSub ? { height: '30px' } : {}} />
                  <div style={styleSub ? { fontSize: '15.8px' } : {}}>
                    <span>Upload Your File</span>
                  </div>
                </div>
              ) : (
                <>
                  <img src='/images/upload.svg' alt='Upload icon' />
                  <p>
                    Drag and Drop here or <span>Select Your File </span>
                  </p>
                </>
              )}

            </Form.Label>
            {!styleSub && (
              <>
                We can accept files in {fileExtensions.join(', ')}
              </>
            )}
          </>
        )}
      </Dropzone>
    );
  }
}
