import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import userSchema from '../../utils/schemas/userSchema';

const CalculatorFirst = (fprops) => {
  return (
    <Formik
      initialValues={{ height: '', weight: '', width: '', length: '' }}
      validationSchema={userSchema.productionCalculator}
      onSubmit={(values) => {}}
      render={({ errors, touched, ...props }) => (
        <Form onSubmit={props.handleSubmit} validated={props.isValid}>
          <div className='d-flex flex-column justify-content-between'>
            <Form.Group controlId='formName'>
              <Form.Label>Model Height (mm)</Form.Label>
              <Form.Control
                type='text'
                name='height'
                placeholder='Enter model height'
                value={props.values.height}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.handleChange(e);
                  fprops.setHeight(e.target.value);
                }}
                isInvalid={!!errors.height}
                isValid={!!!errors.height}
                autoComplete={'none'}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.height}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='formName'>
              <Form.Label>Model Length (mm)</Form.Label>
              <Form.Control
                type='text'
                name='length'
                placeholder='Enter model length'
                value={props.values.length}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.handleChange(e);
                  fprops.setLength(e.target.value);
                }}
                isInvalid={!!errors.length}
                isValid={!!!errors.length}
                autoComplete={'none'}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.length}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='formName'>
              <Form.Label>Model Width (mm)</Form.Label>
              <Form.Control
                type='text'
                name='width'
                placeholder='Enter model width'
                value={props.values.width}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.handleChange(e);
                  fprops.setWidth(e.target.value);
                }}
                isInvalid={!!errors.width}
                isValid={!!!errors.width}
                autoComplete={'none'}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.width}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId='formName'>
              <Form.Label>Model weight (g)</Form.Label>
              <Form.Control
                type='text'
                name='weight'
                placeholder='Enter model weight'
                value={props.values.weight}
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.handleChange(e);
                  fprops.setWeight(e.target.value);
                }}
                isInvalid={!!errors.weight}
                isValid={!!!errors.weight}
                autoComplete={'none'}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.weight}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </Form>
      )}
    />
  );
};

export default CalculatorFirst;
