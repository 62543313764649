import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

import CreditCardInfo from './creditcard/CreditCardModal';
import { getMembershipPlans } from '../utils/api';
import Modal from './Modal';
import Slider from './Slider';
import DiscountCode from './DiscountCode';
import CustomTooltip from './Tooltip';

const ChoiceCard = ({
  isSelected,
  onSelect,
  title,
  content,
  price,
  billed,
  item,
  setPlans,
  plans,
  showToggle,
  _setSelectedPlan,
}) => {
  const def = () => false;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const membershipTarget = useRef(null);

  const className = `card option col-md-4 col-12 px-0 mx-1 ${isSelected
    ? 'selected bg-primary border-primary'
    : item.interval === 'year'
      ? 'year'
      : ''
    }`;

  const bodyClass = `card-body ${isSelected ? 'bg-white' : ''} `;

  const onChange = (selectedItem) => {
    const found = plans.find(
      (member) =>
        member.id !== selectedItem.id && member.product === selectedItem.product
    );
    const tt = plans.map((mmb) => {
      if (mmb.id === selectedItem.id) {
        return {
          ...mmb,
          showWhenToggled: false,
          toggled: mmb.interval === 'year' ? true : false,
        };
      } else if (mmb.id === found.id) {
        return {
          ...mmb,
          showWhenToggled: true,
          toggled: mmb.interval === 'year' ? true : false,
        };
      } else {
        return { ...mmb, toggled: mmb.toggled ? true : false };
      }
    });
    setPlans(tt);
    setTimeout(() => {
      _setSelectedPlan({});
    }, 300);
  };

  return (
    <div className={className} onClick={onSelect || def}>
      <CustomTooltip
        show={tooltipOpen}
        action={'profile'}
        target={membershipTarget}
        place='top'
        text={
          item.toggled
            ? `Toggle to view monthly subscription!`
            : `Toggle to view yearly subscription!`
        }
        mmb={true}
      />
      <div className='card-header' id={item.id}>
        {showToggle && (
          <div className='switch-tg'>
            <button
              className={item.toggled ? (isSelected ? 'on1' : 'on2') : 'off'}
              on={`${item.toggled}`}
              onClick={() => onChange(item)}
              ref={membershipTarget}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
            >
              <span className={'pin'} />
            </button>
          </div>
        )}
        <h3>{title}</h3>
      </div>
      <div className={bodyClass}>
        <p
          className='card-text'
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
      <div className='card-footer'>
        {price === 'Free' ? `${price}` : `${price} billed every ${billed}`}
      </div>
    </div>
  );
};

const getPrice = (plan) => {
  return plan.amount > 0 ? `$ ${(plan.amount / 100).toFixed(2)}` : 'Free';
};

export default (props) => {
  const showCard = props.showCard !== void 0 ? props.showCard : true;
  const editProfile = props.editProfile !== void 0 ? props.editProfile : true;

  const [slide, slideNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingSubmission, isLoadingSubmission] = useState(false);
  const [isMemValid, setMemIsValid] = useState(false);
  const [plans, setPlans] = useState([]);
  const [stripe, setStripe] = useState(null);
  const [cardError, setCardError] = useState(undefined);
  const [selectedMb, setSelMb] = useState(null);

  const fetchMemberships = async () => {
    setLoading(true);
    const memberships = await getMembershipPlans();
    const ttt = memberships.data
      .filter((it) => Object.keys(it.metadata).length !== 0)
      .map((it) => {
        if (it.interval === 'year') {
          return {
            ...it,
            showWhenToggled: false,
            toggled: false,
            showToggle: true,
            sort: it.metadata.nickname.includes('Silver') ? 1 : 2,
          };
        } else {
          return {
            ...it,
            showWhenToggled: true,
            toggled: false,
            showToggle: it.metadata.nickname.includes('Standa') ? false : true,
            sort: it.metadata.nickname.includes('Silver')
              ? 1
              : it.metadata.nickname.includes('Gold')
                ? 2
                : 0,
          };
        }
      });
    setPlans(ttt ? ttt : []);
    setLoading(false);
  };

  useEffect(() => {
    if (props.show) {
      fetchMemberships();
    }
  }, [props.show, props.erpMembershipContent]);

  const erpContent =
    props.erpMembershipContent &&
    props.erpMembershipContent.reduce((acc, tier) => {
      acc[tier.sku] = tier;
      return acc;
    }, {});

  const setSelectedPlan = (plan) => {
    if (plan.id) {
      setMemIsValid(true);
    }
    props.set_SelectedPlan(plan);
    props.setChosen(plan);
    setSelMb(plan.metadata.nickname);
  };

  let back = [{ title: 'Back', handle: () => slideNumber(slide - 1) }];

  if (slide === 0) {
    back = [];
  }

  const handleNext = () => {
    if (showCard) {
      if (slide !== 1) {
        return [
          {
            title: 'Next',
            disabled: !isMemValid,
            handle: () => {
              if (isMemValid) {
                slideNumber(slide + 1);
              }
            },
          },
        ];
      }
      if (stripe) {
        return [
          {
            title: `Choose ${props.selectedPlan.metadata.nickname}`,
            disabled: !isMemValid,
            handle: async () => {
              if (!stripe) {
                return;
              }
              setCardError();
              const stripeResponse = await stripe.stripe.createToken(
                stripe.card
              );

              if (stripeResponse.error) {
                setCardError(stripeResponse.error.message);
                return;
              }
              isLoadingSubmission(true);
              props.submitMembership(
                stripeResponse.token.id,
                props.selectedPlan
              );
              isLoadingSubmission(false);
              slideNumber(0);
            },
          },
        ];
      }
    }

    return [
      editProfile
        ? {
          title:
            props.selectedPlan &&
              Object.entries(props.selectedPlan).length !== 0 ? (
              !props.loading ? (
                `Choose ${props.selectedPlan && props.selectedPlan.metadata
                  ? props.selectedPlan.metadata.nickname
                  : props.selectedPlan.nickname
                }`
              ) : (
                <i className='fas fa-circle-notch fa-spin' />
              )
            ) : (
              'Choose one'
            ),
          handle: () => {
            isLoadingSubmission(true);
            props.submitMembership('', props.selectedPlan);
            isLoadingSubmission(false);
            slideNumber(0);
          },
          disabled:
            props.selectedPlan &&
            Object.entries(props.selectedPlan).length === 0 || (props.review ? false : !props.cardInfo),
        }
        : {
          title: `Choose ${props.selectedPlan.metadata.nickname}`,
          handle: () => {
            isLoadingSubmission(true);
            props.submitMembership('', props.selectedPlan);
            isLoadingSubmission(false);
            slideNumber(0);
          },
          disabled: Object.entries(props.selectedPlan).length === 0,
        },
    ];
  };

  const handleClose = () => {
    props.handleClose();
    slideNumber(0);
  };

  return (
    <Modal
      show={props.show}
      title='Choose your membership status'
      handleClose={handleClose}
      dangerButtons={[{ title: 'Cancel', handle: props.cancel || handleClose }]}
      primaryButtons={handleNext()}
      secondaryButtons={back}
      className='membership-modal'
    >
      <Slider currentStep={slide}>
        <div className='plans'>
          {!loading ? (
            <>
              {plans
                .sort((a, b) => a.sort - b.sort)
                .map((plan, index) => {
                  const wpContent = erpContent && erpContent[plan.id];
                  if (wpContent && plan.showWhenToggled) {
                    return (
                      <ChoiceCard
                        title={wpContent && wpContent.title}
                        content={wpContent && wpContent.content}
                        price={getPrice(plan)}
                        isSelected={
                          props.editPlan
                            ? props.editPlan.id === plan.id
                            : props.selectedPlan
                              ? props.selectedPlan.id === plan.id
                              : false
                        }
                        onSelect={() => setSelectedPlan(plan)}
                        billed={plan.interval}
                        key={index}
                        item={plan}
                        setPlans={setPlans}
                        plans={plans}
                        showToggle={plan.showToggle}
                        _setSelectedPlan={props.set_SelectedPlan}
                      />
                    );
                  }
                })}
            </>
          ) : (
            <i className='fas fa-circle-notch fa-spin' />
          )}
        </div>
        {showCard ? (
          <div className='cc-info'>
            <p>Enter Credit Card information</p>
            <CreditCardInfo setStripe={setStripe} />
            {selectedMb && selectedMb !== 'Standard' && (
              <DiscountCode
                promoCode={props.promoCode}
                setPromoCode={props.setPromoCode}
                matchedCode={props.matchedCode}
              />
            )}
            <p>
              You will be charged $
              {props.matchedCode
                ? props.selectedPlan
                  ? Number(
                    (props.selectedPlan.amount || 0) / 100 -
                    (props.matchedCode.coupon.percent_off
                      ? (Number(props.matchedCode.coupon.percent_off) /
                        100) *
                      ((props.selectedPlan.amount || 0) / 100)
                      : props.matchedCode.coupon.amount_off / 100)
                  ).toFixed(2)
                  : 0
                : (
                  (props.selectedPlan ? props.selectedPlan.amount : 0 || 0) /
                  100
                ).toFixed(2)}
            </p>
            {cardError !== void 0 && (
              <Form.Control.Feedback
                type='invalid'
                style={{ display: 'block' }}
              >
                {cardError}
              </Form.Control.Feedback>
            )}
          </div>
        ) : null}
      </Slider>
    </Modal>
  );
};
