import React from 'react';
import { navigate } from 'gatsby';
import { Dropdown } from 'react-bootstrap';

const DropdownLink = ({ to, children }, ...props) => (
  <Dropdown.Item {...props} onClick={() => navigate(to)}>
    {children}
  </Dropdown.Item>
);

export default DropdownLink;
