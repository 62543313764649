import React, { useState } from 'react';
import {
  Form,
  Button,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from 'react-bootstrap';
import closeImage from '../images/close.png';
import { checkItemTag, filterTags } from '../utils/helpers';
import LaserEngravinGLibrary from './LaserEngravingLibrary';
import MarketplaceLibrary from './MarketplaceLibrary';

export const LoaderDesignLibrary = (props) => {
  return (
    <div
      style={{
        textAlign: 'center',
        fontSize: '22px',
        marginTop: '20px',
        color: '#7c6464',
      }}
    >
      {props.title}
      <i
        className='fas fa-circle-notch fa-spin'
        style={{ fontSize: '22px', marginLeft: '10px' }}
      />
    </div>
  );
};

const DesignLibraryModal = ({
  models,
  itemTypes,
  setSelectedModel,
  selected3dModel,
  setSortItemType,
  sortItem,
  membership,
  setMostPopular,
  popularClicked,
  projects,
  filteredDesign,
  setFilteredDesign,
  setSelectedMarket3dModel,
  selectedMarketplace3dModel,
  activeTab,
  setActiveTab,
  products,
  setSelectedProduct,
  selectedProduct,
  //======
  laserEngravingStep,
  projectItems,
  filterProjectItems,
  setFilterProjectItems,
  selectedItem,
  setSelectedItem,
  materialPrice,
  platingPrice,
  polishPrice,
  assemblyPrice,
  setPolishPrice,
  setPlatingPrice,
  setMaterialPrice,
  setAssemblyPrice,
  setLaserEngravingPrice,
  setProductPricing,
  loader,
}) => {
  const [tagsSelected, setTagsSelected] = useState([]);
  const [tagsproductSelected, setTagsProductSelected] = useState([]);

  const filteredModelsByType = sortItem
    ? models.filter((model) => {
      const parsed =
        model.itemTypes && model.itemTypes !== 'undefined'
          ? JSON.parse(model.itemTypes)
          : '';
      if (parsed.name.toLowerCase() === sortItem.name.toLowerCase()) {
        return { ...model };
      }
      if (sortItem.name === 'Default') {
        return { ...model };
      }
    })
    : models;

  const checkAllModelsSelected = (models) => {
    return models.every((model) => model.itemCount === 0);
  };

  const allItemsSelected = checkAllModelsSelected(models);

  const uniqueModelTags = filterTags(models);
  const uniqueProductTags = filterTags(products);

  const filteredProducts =
    tagsproductSelected.length !== 0
      ? products.filter((item) => {
        const parsedTags =
          item.tags && item.tags !== 'undefined' ? JSON.parse(item.tags) : [];
        const containsTag = checkItemTag(parsedTags, tagsproductSelected);
        if (containsTag) {
          return item;
        }
      })
      : products;

  const filterByAll =
    tagsSelected.length !== 0
      ? filteredModelsByType.filter((item) => {
        const parsedTags =
          item.tags && item.tags !== 'undefined' ? JSON.parse(item.tags) : [];
        const containsTag = checkItemTag(parsedTags, tagsSelected);
        if (containsTag) {
          return item;
        }
      })
      : filteredModelsByType;

  const filterMostPopular =
    Number(popularClicked) === 1
      ? filterByAll.sort((a, b) => b.itemCount - a.itemCount)
      : Number(popularClicked) === 2
        ? filterByAll.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        : filterByAll.sort((a, b) => a.itemCount - b.itemCount);

  const membershipDisabled = (parsed, membership) => {
    let objMessage = {
      message: 'Upgrade your membership to use this design!',
      show: true,
    };

    parsed
      ? parsed.length === 0
        ? (objMessage = { message: '', show: false })
        : parsed.map((mm) => {
          if (
            String(mm.membership).trim() === String(membership).trim() ||
            String(membership).trim().includes(String(mm.membership).trim())
          ) {
            objMessage = {
              message: ``,
              show: false,
            };
          }
        })
      : null;
    return objMessage;
  };

  return laserEngravingStep ? (
    <Tabs
      onSelect={(k) => setActiveTab(k)}
      activeKey={activeTab}
      className='myTabs'
    >
      <Tab eventKey={'market'} title={'My Library'} tabClassName='tab-content'>
        <LaserEngravinGLibrary
          projectItems={projectItems}
          filterProjectItems={filterProjectItems}
          setFilterProjectItems={setFilterProjectItems}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </Tab>
    </Tabs>
  ) : (
    <Tabs
      onSelect={(k) => setActiveTab(k)}
      activeKey={activeTab}
      className='myTabs'
    >
      <Tab
        eventKey={'product'}
        title={
          <>
            <span
              style={{
                color: '#fe015b',
                fontSize: '11px',
                marginBottom: '15px',
              }}
            >
              *New
            </span>
            <span>Product Library</span>
          </>
        }
        tabClassName='tab-content pr-tab'
      >
        <Form className='design-content'>
          <div>
            <div className='d-flex flex-row mt-3'>
              <div className='mt-2 mr-1'>Sort by item type:</div>
              <div>
                <Form.Control
                  as='select'
                  name={'select'}
                  onChange={(e) => {
                    if (!tagsproductSelected.includes(e.target.value)) {
                      setTagsProductSelected([
                        ...tagsproductSelected,
                        e.target.value,
                      ]);
                    }
                  }}
                >
                  <option value=''>Select collections</option>
                  {uniqueProductTags.length !== 0
                    ? uniqueProductTags.map((tag, idx) => {
                      return (
                        <option key={idx} value={tag}>
                          {tag}
                        </option>
                      );
                    })
                    : null}
                </Form.Control>
              </div>
              <div className='ml-5 d-flex flex-row flex-wrap'>
                {tagsproductSelected.map((tag, idx) => {
                  return (
                    <span
                      key={idx}
                      className={'card-type ml-1 mr-1 selected-type text-white'}
                    >
                      {tag}
                      <Button
                        style={{
                          paddingRight: '0.3rem',
                          paddingLeft: '0.3rem',
                          paddingBottom: '0',
                          paddingTop: '0',
                          marginLeft: '4px',
                          marginBottom: '5px',
                        }}
                        onClick={() => {
                          setTagsProductSelected(
                            tagsproductSelected.filter((item) => item !== tag)
                          );
                        }}
                      >
                        <img src={closeImage} width='12px' height='12px' alt='closing icon' />
                      </Button>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='switcher'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <h1
                style={{
                  marginTop: '2rem',
                  color: '#7c6262',
                  fontWeight: 600,
                }}
              >
                Products
              </h1>
              {loader ? (
                <LoaderDesignLibrary title={'Products Loading ...'} />
              ) : (
                <div className='cards-list'>
                  {filteredProducts.length === 0 ? (
                    <div className='text-center'>
                      There are no products available right now!
                    </div>
                  ) : (
                    filteredProducts.map((product, index) => {
                      const matched = selectedProduct
                        ? product.id === selectedProduct.id
                          ? true
                          : false
                        : false;
                      const parsedMembership = product.memberships
                        ? JSON.parse(product.memberships)
                        : [];
                      const disableModel = membershipDisabled(
                        parsedMembership,
                        membership
                      );
                      if (disableModel.show) {
                        return (
                          <OverlayTrigger
                            key={index}
                            overlay={
                              <Tooltip id='tooltip-bottom'>
                                {disableModel.message}
                              </Tooltip>
                            }
                          >
                            <div>
                              <div
                                className={'card 1 disable-card'}
                                key={index}
                              >
                                <div className='card_image'>
                                  <img
                                    src={product.prodImages[0]}
                                    alt='Product'
                                    className='img-fluid'
                                  />
                                </div>
                                <div className='card_title title-white'>
                                  <p
                                    style={{
                                      color: 'black',
                                      fontWeight: 600,
                                      marginBottom: '5px',
                                    }}
                                  >
                                    {product.name}
                                  </p>
                                  <p style={{ color: 'black' }}>
                                    {product.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </OverlayTrigger>
                        );
                      }
                      return (
                        <div
                          className={
                            matched ? 'card 1 selected-card' : 'card 1'
                          }
                          style={
                            filteredProducts.length <= 4
                              ? {
                                height: '18rem',
                              }
                              : {}
                          }
                          onClick={() => {
                            if (
                              materialPrice ||
                              platingPrice ||
                              polishPrice ||
                              assemblyPrice
                            ) {
                              setMaterialPrice(null);
                              setPlatingPrice(null);
                              setPolishPrice(null);
                              setAssemblyPrice(null);
                              setLaserEngravingPrice(null);
                            }
                            setSelectedProduct(product);
                            setProductPricing(product.base_price);
                            if (selectedMarketplace3dModel || selected3dModel) {
                              setSelectedMarket3dModel(null);
                              setSelectedModel(null);
                            }
                          }}
                          key={index}
                        >
                          <div className='card_image'>
                            {product.prodImages ? (
                              <img
                                src={product.prodImages[0]}
                                alt='Product'
                                className='img-fluid'
                              />
                            ) : null}
                          </div>
                          <div className='card_title title-white'>
                            <p
                              style={{
                                color: 'black',
                                fontWeight: 600,
                                marginBottom: '5px',
                              }}
                            >
                              {product.name}
                            </p>
                            <p
                              style={{
                                color: 'black',
                                overflowY: 'auto',
                                height: '3.5rem',
                              }}
                            >
                              {product.description}
                            </p>
                          </div>
                        </div>
                      );
                      {
                        /*<div
                        className={matched ? 'card 1 selected-card' : 'card 1'}
                        onClick={() => {
                          setSelectedModel(model);
                          if (selectedMarketplace3dModel || selectedProduct) {
                            setSelectedMarket3dModel(null);
                            setSelectedProduct(null);
                          }
                        }}
                        key={index}
                      >
                        <div className='card_image'>
                          <img src={model.image} alt='' className='img-fluid' />
                        </div>
                        <div className='card_title title-white'>
                          <p
                            style={{
                              color: 'black',
                              fontWeight: 600,
                              marginBottom: '5px',
                            }}
                          >
                            {model.name}
                          </p>
                          <p style={{ color: 'black' }}>{model.description}</p>
                        </div>
                      </div>*/
                      }
                    })
                  )}
                </div>
              )}
            </div>
          </div>
        </Form>
      </Tab>
      <Tab
        eventKey={'default'}
        title={'Design Library'}
        tabClassName='tab-content'
      >
        <Form className='design-content'>
          <div>
            <span>Sort by item type:</span>
            <span
              onClick={() => setSortItemType({ name: 'Default' })}
              className={
                sortItem
                  ? sortItem.name === 'Default'
                    ? 'card-type ml-1 mr-1 selected-type'
                    : 'card-type  ml-1 mr-1'
                  : 'card-type  ml-1 mr-1'
              }
            >
              {'All'}
            </span>
            {itemTypes &&
              itemTypes
                .filter((itemType) => !itemType.product)
                .map((type, idx) => {
                  const matchedItem = sortItem
                    ? type.id === sortItem.id
                      ? true
                      : false
                    : false;
                  return (
                    <span
                      onClick={() => {
                        setSortItemType(type);
                      }}
                      className={
                        matchedItem
                          ? 'card-type ml-1 mr-1 selected-type'
                          : 'card-type  ml-1 mr-1'
                      }
                      key={idx}
                    >
                      {type.name}
                    </span>
                  );
                })}
          </div>
          <div className='d-flex flex-row mt-3'>
            <div className='mt-2 mr-1'>Sort by collections:</div>
            <div>
              <Form.Control
                as='select'
                name={'select'}
                onChange={(e) => {
                  if (!tagsSelected.includes(e.target.value)) {
                    setTagsSelected([...tagsSelected, e.target.value]);
                  }
                }}
              >
                <option value=''>Select collections</option>
                {uniqueModelTags.length !== 0
                  ? uniqueModelTags.map((tag, idx) => {
                    return (
                      <option key={idx} value={tag}>
                        {tag}
                      </option>
                    );
                  })
                  : null}
              </Form.Control>
            </div>
            <div className='ml-5 d-flex flex-row flex-wrap'>
              {tagsSelected.map((tag, idx) => {
                return (
                  <span
                    key={idx}
                    className={'card-type ml-1 mr-1 selected-type text-white'}
                  >
                    {tag}
                    <Button
                      style={{
                        paddingRight: '0.3rem',
                        paddingLeft: '0.3rem',
                        paddingBottom: '0',
                        paddingTop: '0',
                        marginLeft: '4px',
                        marginBottom: '5px',
                      }}
                      onClick={() => {
                        setTagsSelected(
                          tagsSelected.filter((item) => item !== tag)
                        );
                      }}
                    >
                      <img src={closeImage} width='12px' height='12px' />
                    </Button>
                  </span>
                );
              })}
            </div>
          </div>
          <div className='switcher'>
            <div
              className='d-flex flex-row'
              style={{ justifyContent: 'space-between' }}
            >
              <div className='d-flex flex-column'>
                <h1
                  style={{
                    marginTop: '2rem',
                    color: '#7c6262',
                    fontWeight: 600,
                  }}
                >
                  Collections
                </h1>
                <span
                  style={{
                    color: '#7c6262',
                    fontSize: '13px',
                    fontWeight: 600,
                  }}
                >
                  {Number(popularClicked) === 1
                    ? '*Sorted by most popular'
                    : Number(popularClicked) === 2
                      ? '*Sorted by the latest designs'
                      : null}
                </span>
              </div>
              <div style={{ marginTop: '2rem' }}>
                <div
                  className='d-flex flex-row'
                  style={{ justifyContent: 'center' }}
                >
                  <div
                    className='mt-1'
                    style={{
                      fontSize: '16.5px',
                      color: '#7c6262',
                      width: '70px',
                    }}
                  >
                    Sort by:
                  </div>
                  <div>
                    <Form.Control
                      as='select'
                      name={'select'}
                      onChange={(e) => {
                        setMostPopular(e.target.value);
                      }}
                      value={popularClicked}
                    >
                      <option value='0'>Choose sorting </option>
                      {allItemsSelected ? null : (
                        <option value='1'>Most popular</option>
                      )}
                      <option value='2'>Latest designs</option>
                    </Form.Control>
                  </div>
                </div>
              </div>
            </div>
            {loader ? (
              <LoaderDesignLibrary title={'Models loading ...'} />
            ) : (
              <div className='cards-list'>
                {models.length === 0 ? (
                  <div className='text-center'>
                    There are no 3d models available right now!
                  </div>
                ) : (
                  filterMostPopular.map((model, index) => {
                    const matched = selected3dModel
                      ? model.id === selected3dModel.id
                        ? true
                        : false
                      : false;
                    const parsedMembership = model.memberships
                      ? JSON.parse(model.memberships)
                      : [];
                    const disableModel = membershipDisabled(
                      parsedMembership,
                      membership
                    );
                    if (disableModel.show) {
                      return (
                        <OverlayTrigger
                          key={index}
                          overlay={
                            <Tooltip id='tooltip-bottom'>
                              {disableModel.message}
                            </Tooltip>
                          }
                        >
                          <div>
                            <div className={'card 1 disable-card'} key={index}>
                              <div className='card_image'>
                                <img
                                  src={model.image}
                                  alt='Model'
                                  className='img-fluid'
                                />
                              </div>
                              <div className='card_title title-white'>
                                <p
                                  style={{
                                    color: 'black',
                                    fontWeight: 600,
                                    marginBottom: '5px',
                                  }}
                                >
                                  {model.name}
                                </p>
                                <p style={{ color: 'black' }}>
                                  {model.description}
                                </p>
                              </div>
                            </div>
                          </div>
                        </OverlayTrigger>
                      );
                    }
                    return (
                      <div
                        className={matched ? 'card 1 selected-card' : 'card 1'}
                        onClick={() => {
                          setSelectedModel(model);
                          if (selectedMarketplace3dModel || selectedProduct) {
                            setSelectedMarket3dModel(null);
                            setSelectedProduct(null);
                          }
                        }}
                        key={index}
                      >
                        <div className='card_image'>
                          <img src={model.image} alt='Model' className='img-fluid' />
                        </div>
                        <div className='card_title title-white'>
                          <p
                            style={{
                              color: 'black',
                              fontWeight: 600,
                              marginBottom: '5px',
                            }}
                          >
                            {model.name}
                          </p>
                          <p style={{ color: 'black' }}>{model.description}</p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            )}
          </div>
        </Form>
      </Tab>

      <Tab eventKey={'market'} title={'My Library'} tabClassName='tab-content'>
        <MarketplaceLibrary
          projects={projects}
          filteredDesign={filteredDesign}
          setFilteredDesign={setFilteredDesign}
          setSelectedMarket3dModel={setSelectedMarket3dModel}
          selectedMarketplace3dModel={selectedMarketplace3dModel}
          selected3dModel={selected3dModel}
          setSelectedModel={setSelectedModel}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      </Tab>
    </Tabs>
  );
};

export default DesignLibraryModal;
