import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { connect } from 'react-redux';

import { retrieveUser } from '../../state/login/thunks';
import { ShowLock } from '../../utils/auth0';
import { logout } from '../../state/login/action';
import { navigate } from '@reach/router';

const CheckJwt = (props) => {
  if (props.token) {
    const jwt = jwtDecode(props.token);
    const checkExpiry1 = jwt.exp < new Date() / 1000;
    // const checkExpiry2 = jwt.exp * 1000 < Date.now();

    if (checkExpiry1) {
      props.logout();
      ShowLock();
    }
  }

  useEffect(() => {
    if (window === void 0) {
      return;
    }
    // if (props.profile && props.profile.sub) {
    //   props.getUser(props.profile.sub).catch((e) => {
    //     if (!props.onCreate && e.status === 404) {
    //       navigate(`/create`);
    //     }
    //   });
    // }
  }, []);

  return <>{props.children}</>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.login.isAuthenticated,
  token: state.login.auth && state.login.auth.auth.accessToken,
  lastChecked: state.login.lastChecked,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getUser: (userID) => dispatch(retrieveUser(userID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckJwt);
