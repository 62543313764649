import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ShippingInfoForm from './ShippingInfoForm';

export const ConvertProfileToIntial = (profile) => {
  if (!profile) {
    return {
      state: 'AL',
    };
  }
  if (!profile.address) {
    return {
      state: 'AL',
    };
  }
  return {
    name: profile.name,
    email: profile.email,
    street: `${profile.address.street1} #${profile.address.street2}`,
    city: profile.address.city,
    zipcode: profile.address.zipcode,
    state: profile.address.state,
    country: profile.address.country,
    phone: profile.phone,
  };
};

export function useSearchDebounce(value, milliSeconds) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, milliSeconds);

    return () => {
      clearTimeout(handler);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
}

const ShippingInfo = ({
  onChange,
  shippingInfo,
  setIsValid,
  shipping,
  setValues,
  profile,
  touched,
  errors,
  values,
  setFieldValue,
  handleChange,
  handleBlur,
  cart,
  updateSalesTax,
  setShippingValues,
  setShippingRate,
  setShippoMethod,
  shippingRatePrice,
  shippoId,
  setShippoId,
  phone,
  shippingRates,
  rateFetched,
  setFetchRates,
  setRates,
  setShippoToken,
  setShippoCarrier,
  setShippoEstimatedDelivery,
  shipTo,
  setShipTo,
  ...props
}) => {
  return (
    <>
      <div style={{ display: 'flex', gap: '15px', marginBottom: '2rem' }}>
        <button
          className={
            shipTo === 'shipToMe' ? 'ship-to-button checked' : 'ship-to-button'
          }
          onClick={() => setShipTo('shipToMe')}
        >
          Ship To Me
        </button>
        <button
          className={
            shipTo === 'shipToCustomer'
              ? 'ship-to-button checked'
              : 'ship-to-button'
          }
          onClick={() => setShipTo('shipToCustomer')}
        >
          Ship To Customer
        </button>
      </div>
      {shipTo === 'shipToMe' ? (
        <ShippingInfoForm
          values={values}
          onChange={onChange}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors}
          touched={touched}
          setShippingValues={setShippingValues}
          setValues={setValues}
          updateSalesTax={updateSalesTax}
          setFieldValue={setFieldValue}
          setShippoId={setShippoId}
          setShippingRate={setShippingRate}
          setShippoMethod={setShippoMethod}
          setShippoToken={setShippoToken}
          setShippoCarrier={setShippoCarrier}
          setShippoEstimatedDelivery={setShippoEstimatedDelivery}
          cartItems={props.cartItems}
          shippingRates={shippingRates}
          rateFetched={rateFetched}
          setFetchRates={setFetchRates}
          setRates={setRates}
          type={'shipping'}
          shipTo={shipTo}
        />
      ) : (
        <>
          <div className='billing-address' style={{ width: '100%' }}>
            <label
              style={{
                fontSize: '17px',
                marginBottom: '21px',
                fontWeight: 'bold',
              }}
            >
              Billing Address
            </label>
            <ShippingInfoForm
              values={values}
              onChange={onChange}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setShippingValues={setShippingValues}
              setValues={setValues}
              updateSalesTax={updateSalesTax}
              setFieldValue={setFieldValue}
              setShippoId={setShippoId}
              setShippingRate={setShippingRate}
              setShippoMethod={setShippoMethod}
              setShippoToken={setShippoToken}
              setShippoCarrier={setShippoCarrier}
              setShippoEstimatedDelivery={setShippoEstimatedDelivery}
              cartItems={props.cartItems}
              shippingRates={shippingRates}
              rateFetched={rateFetched}
              setFetchRates={setFetchRates}
              setRates={setRates}
              type={'billing'}
              shipTo={shipTo}
            />
          </div>
          <div className='shipping-address' style={{ width: '100%' }}>
            <label
              style={{
                fontSize: '17px',
                marginBottom: '21px',
                fontWeight: 'bold',
              }}
            >
              Shipping Address
            </label>
            <ShippingInfoForm
              values={values}
              onChange={onChange}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setShippingValues={setShippingValues}
              setValues={setValues}
              updateSalesTax={updateSalesTax}
              setFieldValue={setFieldValue}
              setShippoId={setShippoId}
              setShippingRate={setShippingRate}
              setShippoMethod={setShippoMethod}
              setShippoToken={setShippoToken}
              setShippoCarrier={setShippoCarrier}
              setShippoEstimatedDelivery={setShippoEstimatedDelivery}
              cartItems={props.cartItems}
              shippingRates={shippingRates}
              rateFetched={rateFetched}
              setFetchRates={setFetchRates}
              setRates={setRates}
              type={'shipping'}
              shipTo={shipTo}
            />
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.checkout.shipping,
  profile: state.login.auth.profile,
});

export default connect(mapStateToProps)(ShippingInfo);
