import {
  getPrice as getPriceAPI,
  safeGetAuthFromState,
} from '../../../utils/api';
import {
  setMaterial as setMaterialAction,
  setPolish as setPolishAction,
  setPlating as setPlatingAction,
  setLaserEngravingOption as setLaserEngravingOptionAction,
  setAssemblySubItems,
} from '../jewelry/actions';
import { setQuantity as setQuantityAction } from './actions';
import {
  getPrice as getPriceAction,
  fetchingPrice,
  getPriceAssembly as getPriceAssemblyAction,
} from '../price/actions';
import {
  setItemType as setItemTypeAction,
  setAssemblyOption as setAssemblyOptionAction,
  setAssemblySubItems as setAssemblySubItemsAction,
} from './actions';
import { platingOptions } from './options';
import { debounceCustom } from '../../../utils/helpers';

const priceRequest = debounceCustom(async (dispatch, getState) => {
  const jewelry = getState().create.jewelry;

  const authToken = safeGetAuthFromState(getState());

  const userErpId = getState().login.auth.profile.erp_id;

  if (!jewelry.itemType) return;
  if (!jewelry.material) return;
  if (!jewelry.polish) return;
  if (!jewelry.volume) return;
  if (!jewelry.surfaceArea) return;

  const plating = jewelry.plating ? jewelry.plating.id : null;

  const assembly_option = jewelry.assemblyOption
    ? jewelry.assemblyOption.id
    : null;
  const laser_engraving_option = jewelry.laserEngravingOption
    ? jewelry.laserEngravingOption.id
    : null;

  const priceRequest = {
    customer: userErpId,
    item: jewelry.itemType.id,
    material: jewelry.material.id,
    plating,
    polish: jewelry.polish.id,
    assembly_option,
    laser_engraving_option,
    volume: jewelry.volume,
    surface_area: jewelry.surfaceArea,
    quantity: Number(jewelry.quantity),
  };

  const price = await getPriceAPI(priceRequest, authToken);

  dispatch(getPriceAction(price));
}, 150);

export const getPrice = (thing, thingAction) => async (dispatch, getState) => {
  if (!!thingAction) {
    dispatch(thingAction(thing));
  }

  const jewelry = getState().create.jewelry;
  if (!jewelry.itemType) return;
  if (!jewelry.material) return;
  if (!jewelry.polish) return;
  if (!jewelry.volume) return;
  if (!jewelry.surfaceArea) return;

  dispatch(fetchingPrice());

  await priceRequest(dispatch, getState);
};

export const priceAssemblyReq = debounceCustom(
  async (dispatch, getState, data) => {
    const jewelry = getState().create.jewelry;
    const authToken = safeGetAuthFromState(getState());
    const userErpId = getState().login.auth.profile.erp_id;

    const currentItem =
      jewelry.assemblySubItems &&
      jewelry.assemblySubItems.length > 0 &&
      jewelry.assemblySubItems.find((it) => jewelry.assemblyStep === it.id);

    const plating = jewelry.plating ? jewelry.plating.id : null;

    const assembly_option = jewelry.assemblyOption
      ? jewelry.assemblyOption.id
      : null;
    const laser_engraving_option = jewelry.laserEngravingOption
      ? jewelry.laserEngravingOption.id
      : null;

    const priceRequest = {
      customer: userErpId,
      item: jewelry.itemType.id,
      material: jewelry.material.id,
      plating,
      polish: jewelry.polish.id,
      assembly_option: currentItem.assembly ? currentItem.assembly.id : null,
      laser_engraving_option,
      volume: data.volume,
      surface_area: data.surface_area,
      quantity: (currentItem && Number(currentItem.quantity)) || 1,
    };

    const price = await getPriceAPI(priceRequest, authToken);

    const assemblySubItems =
      jewelry.assemblySubItems &&
      jewelry.assemblySubItems.length > 0 &&
      jewelry.assemblySubItems.map((it) => {
        if (jewelry.assemblyStep === it.id) {
          return {
            ...it,
            price: price,
            volume: data.volume,
            surface_area: data.surface_area,
          };
        } else {
          return it;
        }
      });
    dispatch(setAssemblySubItems(assemblySubItems));
    dispatch(getPriceAssemblyAction(price));
  },
  120
);

export const getPriceAssembly = (data) => async (dispatch, getState) => {
  const jewelry = getState().create.jewelry;
  if (!jewelry.itemType) return;
  if (!jewelry.material) return;
  if (!jewelry.polish) return;
  if (!jewelry.assemblyVolume) return;
  if (!jewelry.assemblySurface) return;

  await priceAssemblyReq(dispatch, getState, data);
};

export const setItemType = (itemType) => {
  const setItemTypeClosure = (itemType) => (dispatch) => {
    dispatch(setItemTypeAction(itemType));
  };

  const rgp = getPrice(itemType, setItemTypeClosure);
  return rgp;
};

export const setMaterial = (material) => {
  const selectMaterialClosure = (material) => (dispatch) => {
    dispatch(setMaterialAction(material));
    dispatch(setPlatingAction(platingOptions['none']));
  };

  const rgp = getPrice(material, selectMaterialClosure);
  return rgp;
};

export const setPlating = (plating) => {
  const selectPlatingClosure = (plating) => (dispatch) => {
    dispatch(setPlatingAction(plating));
  };

  const rgp = getPrice(plating, selectPlatingClosure);
  return rgp;
};

export const setPolish = (polish) => {
  const selectPolishingClosure = (polish) => (dispatch) => {
    dispatch(setPolishAction(polish));
  };

  const rgp = getPrice(polish, selectPolishingClosure);
  return rgp;
};

export const setAssemblyOption = (assemblyOption) => {
  const selectAssemblyOptionClosure = (assemblyOption) => (dispatch) => {
    dispatch(setAssemblyOptionAction(assemblyOption));
  };
  const rgp = getPrice(assemblyOption, selectAssemblyOptionClosure);
  return rgp;
};

// export const setAssemblySubItems = (assemblySubItem) => (dispatch) = {
//   dispatch(setAssemblySubItemsAction(assemblySubItem))
// }

export const setLaserEngOption = (laserEngOption) => {
  const selectLaserEngravingClosue = (laserEngOption) => (dispatch) => {
    dispatch(setLaserEngravingOptionAction(laserEngOption));
  };
  const rgp = getPrice(laserEngOption, selectLaserEngravingClosue);
  return rgp;
};

export const setQuantity = (quantity) => (dispatch) => {
  dispatch(setQuantityAction(quantity));
  dispatch(getPrice());
};
