import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
// import WebFont from 'webfontloader';

import Footer from './Footer';
import CheckJwt from './auth/CheckJwtExpiration';
import Header from './Header';
import favicon16 from '../../static/images/favicon-16x16.png';
import favicon32 from '../../static/images/favicon-32x32.png';
import favicon64 from '../../static/images/favicon-96x96.png';

import '../scss/main.scss';
import { getErpMembershipContent, getGildformStatuses } from '../utils/api';
import PopUpChat from './PopUpChat';
import { changeMembership } from '../state/login/thunks';
import MembershipSelect from '../components/MembershipPopup';
import { finishEdit } from '../state/login/action';

const Layout = ({
  children,
  style,
  className,
  light_header,
  forceFooter,
  backgroundImage,
  sit_on_top,
  haveFooter,
  onboarding,
  noAnswers,
  brandforge,
  finishEdit,
  login,
  ...props
}) => {
  haveFooter = haveFooter === undefined ? true : false;
  const query = graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `;

  const [fonts, setFonts] = useState([]);
  const [WebFont, setWebFont] = useState(null);

  const [chosenMembership, setChosen] = useState(null);
  const [erpMembershipContent, setErpMembershipContent] = useState([]);
  const [mmbLoading, setMmbLoad] = useState(false);
  const [isMemberModalOpen, openMemberModal] = useState(false);
  const [selectedPlan, _setSelectedPlan] = useState({});

  const fetchFonts = async () => {
    const res = await getGildformStatuses(12);
    setFonts(res.settings ? res.settings : []);
  };

  const fetchErpMembershipContent = async () => {
    setMmbLoad(true);
    const res = await getErpMembershipContent();
    setErpMembershipContent(res ? res.filter((it) => it.active) : []);
    setMmbLoad(false);
  };

  useEffect(() => {
    fetchFonts();
  }, []);

  useEffect(() => {
    if (isMemberModalOpen && erpMembershipContent.length === 0) {
      fetchErpMembershipContent();
    }
  }, [isMemberModalOpen]);

  let families = [];
  fonts.map((font) => {
    families.push(font.name);
  });

  function addStylesheetURL(url) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  useEffect(() => {
    (async () => {
      const WebFont = await import('webfontloader');
      setWebFont(WebFont);
    })();
  }, []);

  return (
    <CheckJwt {...props}>
      <Helmet>
        <link href={`${favicon16}`} sizes='16x16' type='image/png' rel='icon' />
        <link href={`${favicon32}`} sizes='32x32' type='image/png' rel='icon' />
        <link href={`${favicon64}`} type='image/png' rel='shortcut icon' />
        <link rel='stylesheet' href='https://use.typekit.net/mxm8xhd.css' defer />
        <link rel='preconnect' href='https://fonts.googleapis.com' />
        <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />

        <link
          href='https://fonts.googleapis.com/css?family=Raleway:400,600&display=swap'
          rel='stylesheet'
          defer
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600;700;800;900&display=swap'
          rel='stylesheet'
          defer
        />
        <link
          rel='stylesheet'
          href='https://use.fontawesome.com/releases/v5.6.3/css/all.css'
          integrity='sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/'
          crossOrigin='anonymous'
          defer
        />
        <link
          href='https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins:wght@700&display=swap'
          rel='stylesheet'
          defer
        />

        <script
          src='https://code.jquery.com/pep/0.4.3/pep.min.js'
          integrity='sha384-gcaITNPK2MIxJNVAxQFJO1mujYC/98niQA1MFyPQrNkJkE2lfBxi1oFJXGZCIza5'
          crossOrigin='anonymous'
          defer
        />
        <script src='https://cdn.auth0.com/js/lock/11.17.2/lock.min.js' defer />
        <script src='https://js.stripe.com/v3/' defer />
        <script
          src='https://07446a6977d444c5acd978edb097f403.js.ubembed.com'
          async
          defer
        />
        <script
          type='text/javascript'
          src='//script.crazyegg.com/pages/scripts/0115/7987.js'
          async='async'
        />
        <script
          type='text/javascript'
          src='https://load.fomo.com/api/v1/ZVQTVkvZIX0b3jjwvmlUTw/load.js'
          async
        />
        {/* <script crossorigin src="https://unpkg.com/creditkey-js@<latest-version>/umd/creditkey-js.js"></script> */}
      </Helmet>
      <StaticQuery
        query={query}
        render={(data) => {
          return (
            <Header
              siteTitle={data.site.siteMetadata.title}
              light_header={light_header}
              backgroundImage={backgroundImage}
              sit_on_top={sit_on_top}
              onboarding={onboarding}
              noAnswers={noAnswers}
            />
          );
        }}
      />
      <main style={style} className={className || ''}>
        {children}
      </main>
      <div
        style={
          brandforge || props.onCreate
            ? { display: 'none' }
            : { position: 'relative' }
        }
      >
        <PopUpChat
          openMemberModal={openMemberModal}
          unShow={brandforge || props.onCreate}
        />
      </div>
      <ToastContainer />
      <MembershipSelect
        showCard={false}
        show={isMemberModalOpen}
        handleClose={() => openMemberModal(false)}
        loading={login.userLoading}
        plan={(chosenMembership && chosenMembership) || {}}
        editPlan={chosenMembership ? chosenMembership : null}
        editProfile={true}
        selectedPlan={selectedPlan}
        set_SelectedPlan={_setSelectedPlan}
        erpMembershipContent={erpMembershipContent && erpMembershipContent}
        submitMembership={async (_, membership) => {
          const res = await props.changeMembership(
            props.profile.subUser
              ? props.profile.profile.admin_id
              : props.profile.id,
            membership
          );
          if (res.membership) {
            toast.success('Membership edited successfully!', {
              className: 'bg-primary text-white',
              onClose: () => finishEdit(),
            });
            setChosen(res.membership);
          } else {
            toast.error('An error occurred editing membership!', {
              onClose: () => finishEdit(),
            });
          }
          openMemberModal(false);
        }}
        setChosen={setChosen}
        cardInfo={props.profile ? props.profile.cardInfo : null}
      />
      {haveFooter ? <Footer forceBottom={forceFooter} /> : null}
    </CheckJwt>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.login.auth && state.login.auth.profile,
  login: state.login && state.login,
  roadmap: state.create.answer && state.create.answer.roadmap,
  answers: state.create.answer && state.create.answer.answers,
  token: state.login.auth && state.login.auth.auth.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  changeMembership: (userID, membership) =>
    dispatch(changeMembership(userID, membership)),
  finishEdit: () => dispatch(finishEdit()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
