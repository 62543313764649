import React from 'react';
import { Form, Button } from 'react-bootstrap';
import noImage from '../images/noimage.jpg';

const MARKET_URL = process.env.GATSBY_MARKETPLACE_URL;

const MarketplaceLibrary = (props) => {
  let models = [];
  let allParts = [];

  props.projects &&
    props.projects.map((project) => {
      if (project.completed) {
        return project.projectItems.map((item) => {
          if (item.multipleModel) {
            const parts = JSON.parse(item.parts);
            parts.map((part, idx) => {
              const word = String.fromCharCode(idx + 1 + 64);
              allParts.push({
                ...part,
                part_id: idx,
                title: `Part ${word} of ${item.title} - ${part.name}`,
                multiple: item.multipleModel,
                modelScreenshot: item.modelScreenshot,
                images: item.images,
                model3D: part.link_url,
              });
            });
          }
          models.push(item);
        });
      }
    });

  let allModels = models.concat(allParts);

  const filteredModels =
    !props.filteredDesign || props.filteredDesign === 'all'
      ? allModels
      : allModels.filter(
        (mdl) => Number(mdl.projectId) === Number(props.filteredDesign)
      );

  const hasCompletedProjects =
    props.projects && props.projects.some((project) => project.completed);

  return props.projects && props.projects.length === 0 ? (
    <div className='no-prj'>
      You have no active projects in the Marketplace!
      <Button
        style={{ marginTop: '1rem' }}
        onClick={() => {
          if (window) {
            window.open(`${MARKET_URL}`, '_blank');
          }
        }}
      >
        Go to marketplace!
      </Button>
    </div>
  ) : !hasCompletedProjects ? (
    <div className='no-completed text-center mt-2'>
      There are no projects that have been delivered yet in the marketplace!
      <Button
        style={{ marginTop: '1rem' }}
        onClick={() => {
          if (window) {
            window.open(`${MARKET_URL}`, '_blank');
          }
        }}
      >
        Go to marketplace!
      </Button>
    </div>
  ) : (
    <div className='marketplace-lib-main'>
      <div className='mdl-title'>Choose one model</div>
      <div className='filter-designs'>
        <span className='filter-title'>Filter by project:</span>
        <span>
          <Form.Control
            as='select'
            name={'select'}
            onChange={(e) => {
              props.setFilteredDesign(e.target.value);
            }}
            value={props.filteredDesign}
          >
            <option value='0'>Choose project</option>
            <option value='all'>All models</option>
            {props.projects.map((project) => {
              if (project.completed) {
                return (
                  <option key={project.id} value={project.id}>
                    {project.title}
                  </option>
                );
              }
            })}
          </Form.Control>
        </span>
      </div>
      <div className='models-list'>
        {filteredModels.map((model, idx) => {
          const matched = props.selectedMarketplace3dModel
            ? props.selectedMarketplace3dModel.multiple
              ? model.part_id === props.selectedMarketplace3dModel.part_id
                ? true
                : false
              : model.id === props.selectedMarketplace3dModel.id
                ? true
                : false
            : false;
          const imgSrc = model.modelScreenshot ? model.modelScreenshot : model.images
          return (
            <div
              className={matched ? 'model-card selected-card' : 'model-card'}
              key={idx}
              onClick={() => {
                props.setSelectedMarket3dModel(model);
                if (props.selected3dModel || props.selectedProduct) {
                  props.setSelectedModel(null);
                  props.setSelectedProduct(null);
                }
              }}
            >
              <div className='card_image'>
                {imgSrc ? (
                  <img
                    src={imgSrc}
                    className='img-fluid'
                    alt='marketplace'
                  />
                ) : (
                  <img src={noImage} className='img-fluid' alt='market' />
                )}
              </div>
              <div className='model-title'>{model.title}</div>
              <div className='card-body'>{model.description}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MarketplaceLibrary;
