import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container, Row } from 'react-bootstrap';
import { Link } from '@reach/router';
import gildformLogoBlack from '../images/gildformlogo-black.png';
import gildformLogo from '../images/logo.png';
import { isToggleOpen, setOpenToggle } from '../utils/helpers';
import WindoWidth from './WindowWidth';

const ActualLink = ({
  internal_link,
  link_text,
  external_link,
  link_column,
  idz,
}) => {
  if (internal_link) {
    const linkParts = internal_link.split('/');
    const pageSlug = linkParts[linkParts.length - 1];
    const pageCategory = linkParts[linkParts.length - 2];
    if (pageCategory === 'designer') {
      return (
        <Link to={`/designer/${pageSlug}`} key={idz}>
          {link_text}
        </Link>
      );
    }

    return (
      <Link to={pageSlug} key={idz}>
        {link_text}
      </Link>
    );
  }

  return (
    <a href={external_link} target='__blank' key={idz}>
      {link_column.title === 'Social Media' && (
        <i
          className={
            link_text.toLowerCase() === 'instagram'
              ? 'fab fa-instagram'
              : link_text.toLowerCase() === 'facebook'
                ? 'fab fa-facebook'
                : link_text.toLowerCase() === 'twitter'
                  ? 'fab fa-twitter'
                  : link_text.toLowerCase() === 'linkedin'
                    ? 'fab fa-linkedin'
                    : ''
          }
        />
      )}
      {link_text}
    </a>
  );
};

const Footer = ({ forceBottom }) => {
  const [toggle, setToggle] = useState({ id: '', toggle: false });
  const [testToggle, setTestToggle] = useState([]);

  const rebrandFooterData = useStaticQuery(graphql`
    {
      wordpressAcfFooter(wordpress_id: { eq: 663 }) {
        acf {
          link_columns_rebrand {
            title
            links {
              internal_link
              link_text
              external_link
            }
          }
          footer_description
        }
      }
    }
  `).wordpressAcfFooter;

  const windowWidth = WindoWidth();

  return (
    <Row as='footer' className={forceBottom ? 'bottom' : ''}>
      <Container>
        <div className='logo-description'>
          <div className='logo'>
            <img className='gildform-logo' src={gildformLogoBlack} alt='Logo' />
            <img className='gildform-logo-text' src={gildformLogo} alt='Logo' />
          </div>
          <div className='gildform-description'>
            <span
              dangerouslySetInnerHTML={{
                __html: rebrandFooterData.acf.footer_description,
              }}
            />
          </div>
        </div>
        <div className='links'>
          {rebrandFooterData.acf.link_columns_rebrand.map((item, index) => {
            return (
              <div className='single-link' key={index}>
                <div
                  className='link-click'
                  data-toggle='collapse'
                  data-target={
                    windowWidth > 992 ? '' : `#${item.title.split(' ')[0]}`
                  }
                  role='button'
                  aria-expanded='false'
                  aria-controls='link1'
                  onClick={() => {
                    setOpenToggle(
                      { id: index, toggle: !toggle.toggle },
                      testToggle,
                      setTestToggle
                    );
                  }}
                >
                  <span className='link-title'>{item.title}</span>
                  {isToggleOpen(index, testToggle) ? (
                    <i className='fas fa-chevron-up' />
                  ) : (
                    <i className='fas fa-chevron-down' />
                  )}
                </div>
                <div className='collapse' id={`${item.title.split(' ')[0]}`}>
                  <div className='link-content'>
                    {item.links.map((link) => (
                      <ActualLink
                        {...link}
                        link_column={item}
                        idz={`${Math.random() + index}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
      <hr />
      <div className='date'>
        Copyright © {new Date().getFullYear()} <a href='#'>Gildform</a>
      </div>
    </Row>
  );
};

export default Footer;
