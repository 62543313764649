import { useState } from 'react';

const WindowObject = () => {
  const windowGlobal = typeof window !== 'undefined';

  if (windowGlobal) {
    const [windowLocation, setWidth] = useState(window.location);
    return windowLocation;
  }
};

export default WindowObject;
