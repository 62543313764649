import React from 'react';
import { uploadGildformStoreProductImages } from '../utils/api';
import Modal from './Modal';
import shopifyLogo from '../images/shopify-icon.png';

const GildformStore = ({
  openStoreModal,
  setOpenStoreModal,
  stripeComplete,
  prodName,
  prodImages,
  prodPrice,
  saveGildformStoreItem,
  publishToGildformStore,
  gildformItem,
  setProductImages,
  setProductName,
  setProductDescription,
  setProductPrice,
  gildformPercentage,
  storeLoading,
  imageUplod,
  imageUse,
  setImgUpl,
  setImgUse,
  openLoad,
}) => {
  const revenue =
    gildformItem && prodPrice
      ? Number(prodPrice) - Number(gildformItem.price.totalPrice)
      : null;
  const custPerc =
    100 - Number(gildformPercentage ? gildformPercentage.value : 10);

  const custReven = custPerc
    ? Number((custPerc / 100) * revenue).toFixed(2)
    : null;
  return (
    <Modal
      title={
        <div>
          <span>Publish product to Gildform Store on Shopify </span>
          <img
            src={shopifyLogo}
            width={30}
            height={30}
            style={{ marginBottom: '4px', marginLeft: '5px' }}
            alt='shopify'
          />
        </div>
      }
      show={openStoreModal}
      className='d-flex align-items-center justify-content-center'
      handleClose={() => setOpenStoreModal(false)}
      size={'lg'}
      headerStyle={{
        backgroundColor: '#7c6262',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      titleStyle={{ fontSize: '19px', margin: 'auto' }}
      primaryButtons={[
        {
          title: storeLoading ? (
            <i className='fas fa-circle-notch fa-spin' />
          ) : !stripeComplete ? (
            `Complete your Stripe Connect account`
          ) : (
            `Publish to Gildform Store`
          ),
          disabled: stripeComplete ? !prodName || !prodPrice : false,
          handle: !stripeComplete
            ? saveGildformStoreItem
            : publishToGildformStore,
        },
      ]}
      dangerButtons={[
        { title: 'Cancel', handle: () => setOpenStoreModal(false) },
      ]}
    >
      <div className='d-flex flex-column justify-content-center align-items-center modal-store'>
        {openLoad ? (
          <div style={{ textAlign: 'center', alignItems: 'center' }}>
            <i className='fas fa-circle-notch fa-spin' />
          </div>
        ) : (
          gildformItem && (
            <>
              <div className='gild-title-product'>
                Product{' '}
                {gildformItem.file_name ? `- ${gildformItem.file_name}` : ''}
              </div>
              <div className='gildform-item'>
                <div className='gild-spec'>
                  <div className='gild-spec-item'>Item</div>
                  <div>{`${gildformItem.item}`}</div>
                </div>
                <div className='gild-spec'>
                  <div className='gild-spec-item'>Material</div>
                  <div>{`${gildformItem.material}`}</div>
                </div>
                <div className='gild-spec'>
                  <div className='gild-spec-item'>Plating</div>
                  <div>{`${gildformItem.plating}`}</div>
                </div>
                <div className='gild-spec'>
                  <div className='gild-spec-item'>Polish</div>
                  <div>{`${gildformItem.polish}`}</div>
                </div>
                {gildformItem.assemblyOption && (
                  <div className='gild-spec'>
                    <div className='gild-spec-item'>Assembly Option</div>
                    <div>{`${gildformItem.assemblyOption}`}</div>
                  </div>
                )}
                {gildformItem.laserEngravingOption && (
                  <div className='gild-spec'>
                    <div className='gild-spec-item'>Laser Engraving</div>
                    <div>{`${gildformItem.laserEngravingOption}`}</div>
                  </div>
                )}
                <div className='gild-spec price'>
                  <div className='gild-spec-item'>Production Price</div>
                  <div>{`$${Number(gildformItem.price.totalPrice).toFixed(
                    2
                  )}`}</div>
                </div>
              </div>
            </>
          )
        )}
        <div className='gild-to-add-item'>
          <div className='upload-image-text mt-3'>
            <div className='choose-title'>Choose one</div>
            <div>
              <div className='choose-image-type'>
                <div className='upload-img'>
                  <input
                    id={11}
                    type='checkbox'
                    name='upload-image'
                    onChange={(e) => {
                      if (imageUse) {
                        setImgUse(false);
                      }
                      setImgUpl(e.target.checked);
                    }}
                    checked={imageUplod}
                    disabled={!stripeComplete}
                  />
                  <div className='img-text'>Upload image</div>
                </div>
                <div className='upload-img'>
                  <input
                    id={1}
                    type='checkbox'
                    name='use-image'
                    onChange={(e) => {
                      if (imageUplod) {
                        setImgUpl(false);
                      }
                      setImgUse(e.target.checked);
                    }}
                    checked={imageUse}
                    disabled={!stripeComplete}
                  />
                  <div className='img-text'>Use image</div>
                </div>
              </div>
            </div>
            <div>
              {imageUplod ? (
                <div className='img-to-upload'>
                  <div className='inputs d-flex flex-column'>
                    <input
                      className='w-100 mb-2'
                      type='file'
                      name='file-input'
                      onChange={async (e) => {
                        if (e.target.files.length > 0) {
                          const res = await uploadGildformStoreProductImages(
                            e.target.files[0]
                          );
                          if (res) {
                            setProductImages([
                              ...prodImages,
                              { image: res.image, name: res.imageName },
                            ]);
                          }
                        }
                      }}
                    />
                    <div className='input-gild-img d-flex flex-wrap'>
                      {prodImages &&
                        prodImages.map((item, index) => (
                          <div
                            className='gild-img-name d-flex justify-content-between align-items-center'
                            key={index}
                          >
                            <span className='image-name'>
                              {item && item.name}
                            </span>
                            <i
                              className='fa fa-times del-tag-icon ml-2'
                              onClick={() => {
                                const newArr = prodImages.filter(
                                  (image) => image !== item
                                );
                                setProductImages(newArr);
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : imageUse ? (
                <div className='use-image'>
                  {gildformItem && (
                    <div className='img-show'>
                      <img src={gildformItem.image} width={120} height={120}  alt='shop'/>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className='gild-text-inputs'>
          <input
            type='text'
            name='name'
            placeholder='Name'
            className='gild-txt-inp'
            onChange={(e) => setProductName(e.target.value)}
            value={prodName}
            disabled={!stripeComplete}
            style={!stripeComplete ? { backgroundColor: '#e3e3e3' } : {}}
          />
          <input
            type='text'
            name='description'
            placeholder='Description'
            className='gild-txt-inp'
            onChange={(e) => setProductDescription(e.target.value)}
            disabled={!stripeComplete}
            style={!stripeComplete ? { backgroundColor: '#e3e3e3' } : {}}
          />
        </div>
        <div className='gild-inputs-price'>
          <input
            type='number'
            name='sellingPrice'
            placeholder='Selling Price'
            min='0'
            className='gild-txt-inp'
            onChange={(e) => setProductPrice(e.target.value)}
            value={prodPrice}
            disabled={!stripeComplete}
            style={!stripeComplete ? { backgroundColor: '#e3e3e3' } : {}}
          />
          {revenue && (
            <>
              <span
                className={revenue > 0 ? 'gild-revenue-sc' : 'gild-revenue-low'}
              >
                {revenue < 0 ? (
                  <i
                    className='fa fa-arrow-down'
                    style={{ marginRight: '5.5px' }}
                    aria-hidden='true'
                  />
                ) : (
                  <i
                    className='fa fa-arrow-up'
                    style={{ marginRight: '5.5px' }}
                    aria-hidden='true'
                  />
                )}
                {revenue > 0
                  ? `+$${revenue.toFixed(2)}`
                  : `$${revenue.toFixed(2)}`}
              </span>
            </>
          )}
        </div>
        {custReven && revenue && (
          <div className='gild-inputs-price'>
            <label style={{ marginRight: '10px', marginTop: '5px' }}>
              Your Revenue:
            </label>
            <input
              type='text'
              name='Revenue'
              placeholder='Revenue'
              className='gild-txt-inp'
              value={`$${custReven}` || ''}
              style={{ pointerEvents: 'none' }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default GildformStore;
