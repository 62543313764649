import React from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

const CustomTooltip = (props) => {
  const usualTxt = `You have no permission to perform this action.
   Please talk to your admin for more details!`;
  return (
    <Overlay
      target={props.target.current}
      show={props.show}
      placement={props.place}
    >
      {(fprops) => (
        <Tooltip id='overlay-example' {...fprops}>
          {props.mmb ? props.text : usualTxt}
        </Tooltip>
      )}
    </Overlay>
  );
};

export default CustomTooltip;
