import React, { Component, createRef } from 'react';
import {
  ArcRotateCamera,
  Vector3,
  Color4,
  Color3,
  Engine,
  Scene,
  Tools,
  PBRMaterial,
  CubeTexture,
  VertexBuffer,
} from 'babylonjs';
import { connect } from 'react-redux';
import {
  STLFileLoader,
  OBJFileLoader,
  GLTFFileLoader,
} from 'babylonjs-loaders';
import { EventEmitter } from 'events';

import {
  modelIsLoading,
  modelIsDoneLoading,
  setHeight,
  setWidth,
  setLength,
  setFileDimensionError,
  removeFileDimensionError,
  setNewOrder,
  setVolumeAssembly,
  setSurfaceAreaAssembly,
} from '../state/create/jewelry/actions';
import {
  setImageScreenShot,
  setVolume,
  setSurfaceArea,
} from '../state/create/jewelry/actions';
import { getPrice, getPriceAssembly } from '../state/create/jewelry/thunks';

class Model extends Component {
  background;
  canvasProps;
  canvasRef;
  eventEmitter;
  fileLoader;
  model;
  material;
  scene;
  unsupportedBrowserMessage;

  constructor(props) {
    super(props);
    const {
      _file,
      _material,
      _plating,
      _screenshot,
      _setImageScreenShot,
      _setNewOrder,
      _new_order,
      onError,
      assemblyStep,
      ...canvasProps
    } = props;

    this.background = new Color4(0, 0, 0, 0);
    this.canvasProps = canvasProps;
    this.eventEmitter = new EventEmitter();
    this.fileLoader = new STLFileLoader();
    this.objFileLoader = new OBJFileLoader();
    this.gltfFileLoader = new GLTFFileLoader();
    this.canvasRef = createRef();
    this.assemblyStep = assemblyStep;

    this.unsupportedBrowserMessage =
      'Your browser does not support HTML5 Canvas. Please upgrade!';

    this.takeScreenShot = this.takeScreenShot.bind(this);

    if (onError) {
      this.eventEmitter.addListener('error', (e) => {
        onError(e);
        this.props.modelIsDoneLoading();
      });
    }
  }

  takeScreenShot() {
    return new Promise((res, rej) => {
      Tools.CreateScreenshot(
        this.scene.getEngine(),
        this.scene.activeCamera,
        400,
        (data) => {
          res(data);
        }
      );
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file && this.props.file) {
      this.props.setImageScreenShot('');
      this.loadScene(this.props.file);
    } else if (prevProps.file !== this.props.file) {
      this.model.dispose();
      this.props.modelIsDoneLoading();
    }

    const loadingObject = this.props.isMaterialLoading;
    if (Object.keys(loadingObject).every((k) => !loadingObject[k])) {
      if (
        prevProps.material !== this.props.material &&
        this.props.isMaterialLoading.materialOptions === false
      ) {
        this.updateTexture();
      }

      if (
        prevProps.plating !== this.props.plating &&
        this.props.isMaterialLoading.platingOptions === false
      ) {
        this.updateTexture();
      }
      if (
        prevProps.polish !== this.props.polish &&
        this.props.isMaterialLoading.polishOptions === false
      ) {
        this.updateTexture();
      }
    }
  }

  async loadScene(data) {
    this.resetScene();
    if (data) {
      const parts = this.props.fileName.split('.');
      const extension = parts[parts.length - 1];
      if (extension.toLowerCase() === 'stl') {
        this.fileLoader.load(this.scene, data, null);
      } else if (extension.toLowerCase() === 'obj') {
        const decoder = new TextDecoder('utf-8');
        const str = decoder.decode(data);
        await this.objFileLoader.loadAsync(this.scene, str, '');
      }
    }

    if (this.validateModelCount()) {
      this.model = this.scene.meshes[0];
      const vetors = this.model.getBoundingInfo().boundingBox.vectorsWorld;
      const width = Math.abs(Number(vetors[1].x - vetors[0].x));
      const height = Math.abs(Number(vetors[1].y - vetors[0].y));
      const depth = Math.abs(Number(vetors[1].z - vetors[0].z));

      this.model.onMaterialChangedObservable.add(() => {
        this.props.modelIsLoading();
      });
      this.createCamera();
      this.updateTexture();
      this.displayModel();

      this.model.updateFacetData();

      const vertices = this.model.getVerticesData(VertexBuffer.PositionKind);
      const indices = this.model.getIndices();
      const volume = this.getSignedVolume(vertices, indices);
      const srf = this.getSurfaceArea(vertices, indices);

      if (this.assemblyStep) {
        this.props.setVolumeAssembly(volume);
      } else {
        this.props.setVolume(volume);
      }
      if (Math.round(volume) === 0 || Math.round(srf) === 0) {
        this.props.setFileDimensionError(true);
      } else {
        this.props.setFileDimensionError(false);
      }
      if (this.assemblyStep) {
        this.props.setSurfaceAreaAssembly(srf);
      } else {
        this.props.setSurfaceArea(srf);
      }
      if (!this.assemblyStep) {
        this.props.setHeight(height);
        this.props.setWidth(width);
        this.props.setLength(depth);
      }
      if (this.assemblyStep) {
        const data = {
          volume: Math.round(volume),
          surface_area: Math.round(srf),
        };
        this.props.getPriceAssembly(data);
      } else {
        this.props.getPrice();
      }
    }
  }

  resetScene() {
    if (this.scene) {
      this.scene.getEngine().dispose();
      delete this.scene;
    }

    this.scene = new Scene(
      new Engine(this.canvasRef.current, true, { preserveDrawingBuffer: true })
    );
    this.scene.clearColor = this.background;
  }

  validateModelCount() {
    const modelCount = this.scene ? this.scene.meshes.length : 0;

    if (modelCount === 0) {
      this.eventEmitter.emit('error', 'No models were detected.');
      return false;
    }

    if (modelCount > 1) {
      this.eventEmitter.emit('error', 'More than one model was detected.');
      return false;
    }

    return true;
  }

  createCamera() {
    const defaultCamera = this.scene.activeCamera;
    const rotatingCamera = new ArcRotateCamera(
      'arcCamera',
      0,
      0,
      10,
      Vector3.Zero(),
      this.scene
    );
    rotatingCamera.attachControl(this.canvasRef.current, false, true);
    rotatingCamera.setTarget(this.model, true);
    rotatingCamera.zoomOn();
    rotatingCamera.maxZ = 50000;
    rotatingCamera.lowerBetaLimit = NaN;
    rotatingCamera.upperBetaLimit = NaN;

    this.scene.addCamera(rotatingCamera);
    this.scene.removeCamera(defaultCamera);
    this.scene.setActiveCameraByName('arcCamera');
  }

  updateTexture() {
    if (!this.model) {
      return;
    }

    if (this.material) {
      this.material.dispose();
      delete this.material;
    }

    let textureInfo = {
      ...this.props.material,
      texture_url: `/textures/${this.props.material.texture_url}`,
    };

    if (this.props.plating && this.props.plating.texture_url) {
      textureInfo = {
        ...this.props.plating,
        texture_url: `/textures/${this.props.plating.texture_url}`,
      };
    }

    if (typeof textureInfo.color === 'string') {
      textureInfo = { ...textureInfo, color: JSON.parse(textureInfo.color) };
    }

    const color = new Color3(
      textureInfo.color.r / 255,
      textureInfo.color.g / 255,
      textureInfo.color.b / 255
    );

    let metal = new PBRMaterial('metal', this.scene);

    var basicTexture = CubeTexture.CreateFromImages(
      [
        textureInfo.texture_url,
        textureInfo.texture_url,
        textureInfo.texture_url,
        textureInfo.texture_url,
        textureInfo.texture_url,
        textureInfo.texture_url,
      ],
      this.scene
    );

    metal.reflectionTexture = basicTexture;
    metal.microSurface = this.props.polish.diffuse;
    metal.reflectivityColor = color;
    metal.albedoColor = new Color3(0.01, 0.01, 0.01);
    this.model.material = metal;
  }

  displayModel() {
    const render = this.scene.render.bind(this.scene);

    this.scene.getEngine().runRenderLoop(() => {
      if (this.props.isMaterialLoading) render();
      if (this.model.isReady(true) && this.props.isModelLoading) {
        this.props.modelIsDoneLoading();
      }
    });
  }

  getSignedVolume(vertices, indices) {
    let volume = 0;
    const numTriangles = indices.length / 3;

    for (let i = 0; i < numTriangles; i++) {
      const i3 = i * 3;

      const v1 = new Vector3(
        vertices[indices[i3] * 3],
        vertices[indices[i3] * 3 + 1],
        vertices[indices[i3] * 3 + 2]
      );
      const v2 = new Vector3(
        vertices[indices[i3 + 1] * 3],
        vertices[indices[i3 + 1] * 3 + 1],
        vertices[indices[i3 + 1] * 3 + 2]
      );
      const v3 = new Vector3(
        vertices[indices[i3 + 2] * 3],
        vertices[indices[i3 + 2] * 3 + 1],
        vertices[indices[i3 + 2] * 3 + 2]
      );

      const triangleVolume = Vector3.Dot(v1, Vector3.Cross(v2, v3)) / 6;
      volume += triangleVolume;
    }

    return Math.abs(volume);
  }

  getSurfaceArea(vertices, indices) {
    let area = 0;
    const numTriangles = indices.length / 3;

    for (let i = 0; i < numTriangles; i++) {
      const i3 = i * 3;

      const v1 = new Vector3(
        vertices[indices[i3] * 3],
        vertices[indices[i3] * 3 + 1],
        vertices[indices[i3] * 3 + 2]
      );
      const v2 = new Vector3(
        vertices[indices[i3 + 1] * 3],
        vertices[indices[i3 + 1] * 3 + 1],
        vertices[indices[i3 + 1] * 3 + 2]
      );
      const v3 = new Vector3(
        vertices[indices[i3 + 2] * 3],
        vertices[indices[i3 + 2] * 3 + 1],
        vertices[indices[i3 + 2] * 3 + 2]
      );

      const a = v2.subtract(v1);
      const b = v3.subtract(v1);
      const triangleArea = 0.5 * Vector3.Cross(a, b).length();
      area += triangleArea;
    }

    return Math.abs(area);
  }

  render() {
    return this.assemblyStep ? (
      <canvas
        className='render'
        style={{ height: '5.3vh', display: 'none' }}
        ref={this.canvasRef}
        touch-action='none'
      >
        {this.unsupportedBrowserMessage}
      </canvas>
    ) : (
      <canvas
        className='render'
        style={{ height: '63.3vh' }}
        ref={this.canvasRef}
        touch-action='none'
      >
        {this.unsupportedBrowserMessage}
      </canvas>
    );
  }
}

const mapStateToProps = (state) => ({
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  screenshot: state.create.jewelry.screenshot,
  isModelLoading: state.create.jewelry.isLoading.modelIsLoading,
  isMaterialLoading: state.create.jewelry.isLoading,
  loadProgress: state.create.jewelry && state.create.jewelry.loadProgress,
  new_order: state.create.jewelry.new_order,
});

const mapDispatchToProps = (dispatch) => ({
  setImageScreenShot: (data) => dispatch(setImageScreenShot(data)),
  modelIsLoading: () => dispatch(modelIsLoading()),
  modelIsDoneLoading: () => dispatch(modelIsDoneLoading()),
  setVolume: (data) => dispatch(setVolume(Math.round(data))),
  setSurfaceArea: (data) => dispatch(setSurfaceArea(Math.round(data))),
  setHeight: (data) =>
    dispatch(setHeight(data < 1 ? data.toFixed(4) : Math.round(data))),
  setWidth: (data) =>
    dispatch(setWidth(data < 1 ? data.toFixed(4) : Math.round(data))),
  setLength: (data) =>
    dispatch(setLength(data < 1 ? data.toFixed(4) : Math.round(data))),
  getPrice: () => dispatch(getPrice()),
  setFileDimensionError: (data) => dispatch(setFileDimensionError(data)),
  removeFileDimensionError: () => dispatch(removeFileDimensionError()),
  setNewOrder: (data) => dispatch(setNewOrder(data)),
  setVolumeAssembly: (data) => dispatch(setVolumeAssembly(Math.round(data))),
  setSurfaceAreaAssembly: (data) =>
    dispatch(setSurfaceAreaAssembly(Math.round(data))),
  getPriceAssembly: (data) => dispatch(getPriceAssembly(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Model);
