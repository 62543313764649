import React from 'react';
import { Button } from 'react-bootstrap';
import Loader from './Loader';

const GenerateImagesModal = ({
  generateImagesInput,
  setGenerateImagesInput,
  isGeneratingImages,
  generateImages,
  setImgGnrt,
  aiImgs,
  imgErr,
  setImgGnrtText,
  imgSelected,
  setImgSelected,
  setImgErr,
  membership,
  profile,
  updateUserData,
}) => {
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    setImgGnrt({ img: new Blob([ia], { type: mimeString }) });
    return new Blob([ia], { type: mimeString });
  }

  const firstDate =
    profile && profile.firstReqTime ? new Date(profile.firstReqTime) : null;

  const moreThan30Days = firstDate
    ? new Date(firstDate.setDate(firstDate.getDate() + 30))
    : null;

  const notExpired = moreThan30Days ? new Date() <= moreThan30Days : false;

  return (
    <div className='generate-images-modal-container'>
      <div className='input-section'>
        <label>Enter text or description to generate reference images</label>
        <div className='input-button'>
          <div
            className='input-elms'
            style={imgErr ? { marginTop: '15px' } : {}}
          >
            <input
              type='text'
              name='custom-text-font'
              className='mb-1 w-100'
              onChange={(e) => {
                if (imgErr) {
                  setImgErr(null);
                }
                setGenerateImagesInput(e.target.value);
                setImgGnrtText(e.target.value);
              }}
              value={generateImagesInput}
            />
            {imgErr ? <span className='error-img'>{imgErr}</span> : null}
          </div>

          <Button
            style={{ width: 'fit-content', marginBottom: '10px' }}
            className='choose-from-library mb-1'
            onClick={() => {
              if (membership && membership.trim() === 'Standard') {
                if (!profile.nxReq) {
                  generateImages();
                  updateUserData({
                    ...profile,
                    nxReq: 1,
                    firstReqTime: new Date(),
                  });
                } else if (Number(profile.nxReq) < 10) {
                  if (notExpired) {
                    updateUserData({
                      ...profile,
                      nxReq: Number(profile.nxReq) + 1,
                    });
                    generateImages();
                  } else {
                    setImgErr(
                      'Creators on the free plan get 10 uses for JewelForge™ per month. Upgrade your membership to Gildform Plus to unlock unlimited uses.'
                    );
                  }
                } else {
                  if (!notExpired) {
                    updateUserData({
                      ...profile,
                      nxReq: 1,
                      firstReqTime: new Date(),
                    });
                    generateImages();
                  } else {
                    setImgErr(
                      'Creators on the free plan get 10 uses for JewelForge™ per month. Upgrade your membership to Gildform Plus to unlock unlimited uses.'
                    );
                  }
                }
              } else if (
                (membership && membership.includes('Silver Plus')) ||
                (membership && membership.trim() === 'Bronze') ||
                (membership && membership.trim() === 'Silver')
              ) {
                if (!profile.nxReq) {
                  generateImages();
                  updateUserData({
                    ...profile,
                    nxReq: 1,
                    firstReqTime: new Date(),
                  });
                } else if (Number(profile.nxReq) < 20) {
                  if (notExpired) {
                    updateUserData({
                      ...profile,
                      nxReq: Number(profile.nxReq) + 1,
                    });
                    generateImages();
                  } else {
                    setImgErr(
                      'Creators on the free plan get 20 uses for JewelForge™ per month. Upgrade your membership to Gildform Gold Plus to unlock unlimited uses.'
                    );
                  }
                } else {
                  if (!notExpired) {
                    updateUserData({
                      ...profile,
                      nxReq: 1,
                      firstReqTime: new Date(),
                    });
                    generateImages();
                  } else {
                    setImgErr(
                      'Creators on the free plan get 20 uses for JewelForge™ per month. Upgrade your membership to Gildform Gold Plus to unlock unlimited uses.'
                    );
                  }
                }
              } else {
                generateImages();
              }
            }}
            disabled={!generateImagesInput || imgErr}
          >
            Generate
          </Button>
        </div>
      </div>
      <div className='images-section'>
        <div className='images-title'>
          <span>Generated images:</span>
        </div>
        <div className={!isGeneratingImages ? 'images' : 'images loading'}>
          {!isGeneratingImages ? (
            aiImgs &&
            aiImgs.map((img, index) => (
              <div
                className={
                  img === imgSelected
                    ? 'img-container selected-card'
                    : 'img-container'
                }
                onClick={() => {
                  setImgSelected(img);
                  DataURIToBlob(`data:image/jpeg;base64,${img.b64_json}`);
                }}
                key={index}
              >
                <img
                  src={`data:image/jpeg;base64,${img.b64_json}`}
                  key={index}
                  alt='no img'
                />
              </div>
            ))
          ) : (
            <Loader msg='Generating images... Please wait' />
          )}
        </div>
      </div>
    </div>
  );
};

export default GenerateImagesModal;
