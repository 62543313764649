import React, { useEffect } from 'react';

import CartItem from './CartItem';

const CartContainer = ({ cart, setIsValid, selectedProduct }) => {
  useEffect(() => {
    if (setIsValid) setIsValid(true);
  }, [setIsValid]);

  return (
    <ul style={{ width: '100%' }}>
      {cart.items.map((item, ndx) => (
        <CartItem
          item={item}
          key={ndx}
          selectedProduct={selectedProduct}
        />
      ))}
    </ul>
  );
};

export default CartContainer;
