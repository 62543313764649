import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Form } from 'react-bootstrap';

import { deleteCartItemById } from '../../state/shoppingCart/thunks';
import { setQuantity } from '../../state/create/jewelry/thunks';
import { assemblySubPrice } from '../../utils/helpers';

const thousands_separators = (num) => {
  var num_parts = num.toString().split('.');
  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return num_parts.join('.');
};

const CartItem = ({
  item,
  deleteCartItemById,
  setQuantity,
  selectedProduct,
}) => {
  const assemblySubItemsPrice = assemblySubPrice(item.assemblySubItems);

  const finalPrice =
    Number(item.price && item.price.totalPrice) + Number(assemblySubItemsPrice);
  return (
    <li className='cart-item'>
      <Row>
        <Col className='d-flex align-items-center'>
          {item.image ? (
            <div style={{ width: '50px', height: '50px' }}>
              <img
                style={{ width: '100%' }}
                src={item.image}
                alt='Screen shot of this jewelry piece'
              />
            </div>
          ) : (
            <div />
          )}
          <p>
            {selectedProduct
              ? `${selectedProduct.name} ${item.material.name}`
              : item.itemType.name}
          </p>
        </Col>
        <Col className='price'>
          Total: ${thousands_separators(finalPrice.toFixed(2))}
        </Col>
      </Row>
      <p>{item.description}</p>
      <Row>
        <Col className='d-flex justify-content-start'>
          <Form.Group>
            <Form.Control
              disabled
              style={{ textAlign: 'right' }}
              as='input'
              value={item.quantity}
              onChange={setQuantity}
              type='number'
            />
          </Form.Group>
        </Col>
        <Col className='d-flex justify-content-end'>
          <Button
            onClick={() => {
              deleteCartItemById(item.id)
            }}
            variant='outline-dark'
          >
            <i className='fas fa-times' />
          </Button>
        </Col>
      </Row>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteCartItemById: (id) => dispatch(deleteCartItemById(id)),
  setQuantity: (quantity) => dispatch(setQuantity(quantity)),
});

export default connect(undefined, mapDispatchToProps)(CartItem);
