import {
  SET_ITEM_TYPE,
  SET_MATERIAL,
  SET_PLATING,
  SET_POLISH,
  SET_ASSEMBLY_OPTION,
  SET_LASER_ENGRAVING_OPTION,
  SET_VOLUME,
  SET_SURFACE_AREA,
  SET_DESCRIPTION,
  SET_QUANTITY,
  SET_SCREENSHOT,
  IS_FETCHING,
  SELECT_FIRST,
  REMOVE_SELECTION,
  CLEAR_SELECTIONS,
  UPLOAD_FILE,
  MODEL_LOADING,
  MODEL_LOADING_DONE,
  RESET,
  SET_MESH_LOCATION,
  REMOVE_ASSEMBLY_OPTION,
  REMOVE_LASER_ENGRAVING_OPTION,
  OPEN_MODAL_LIBRARY,
  SET_SELECTED_MODEL,
  MODEL_CHOSEN,
  SET_SORT_ITEM_TYPE,
  SET_MOST_POPULAR,
  SET_LOAD_PROCESS,
  SET_SELECTED_MARKETPLACE_MODEL,
  OPEN_CREDIT_CARD_CHECKOUT,
  SET_WIDTH,
  SET_HEIGHT,
  SET_LENGTH,
  SET_FILE_DIMENSION_ERRORS,
  NEW_ORDER,
  SET_WEIGHT,
  SET_SELECTED_PRODUCT,
  PRODUCT_CHOSEN,
  SET_FONT,
  SET_LASER_CUSTOM_TEXT,
  SET_LASER_IMAGE,
  SET_LIBRARY_PRODUCT,
  SET_ASSEMBLY_SUB_ITEMS,
  REMOVE_ASSEMBLY_SUB_ITEMS,
  UPLOAD_ASSEMBLY_FILE,
  SET_VOLUME_ASSEMBLY,
  SET_SURFACE_AREA_ASSEMBLY,
  SET_ASSEMBLY_STEP,
  SET_PRODUCT_SHOPIFY,
  REMOVE_FILE_DIMENSION_ERRORS
} from '../../actionTypes';

export const setItemType = (itemType) => ({
  type: SET_ITEM_TYPE,
  itemType,
});

export const setMaterial = (material) => ({
  type: SET_MATERIAL,
  material,
});

export const setPlating = (plating) => ({
  type: SET_PLATING,
  plating,
});

export const setPolish = (polish) => ({
  type: SET_POLISH,
  polish,
});

export const setAssemblyOption = (assemblyOption) => ({
  type: SET_ASSEMBLY_OPTION,
  assemblyOption,
});

export const removeAssemblyOption = () => ({
  type: REMOVE_ASSEMBLY_OPTION,
});

export const setAssemblySubItems = (assemblySubItems) => ({
  type: SET_ASSEMBLY_SUB_ITEMS,
  assemblySubItems,
});

export const removeAssemblySubItems = () => ({
  type: REMOVE_ASSEMBLY_SUB_ITEMS,
});

export const setLaserEngravingOption = (laserEngravingOption) => ({
  type: SET_LASER_ENGRAVING_OPTION,
  laserEngravingOption,
});

export const setLibraryProduct = (libraryProduct) => ({
  type: SET_LIBRARY_PRODUCT,
  libraryProduct,
});

export const removeLaserEngravingOption = () => ({
  type: REMOVE_LASER_ENGRAVING_OPTION,
});

export const setFontLaser = (laserFont) => ({
  type: SET_FONT,
  laserFont,
});

export const setLaserCustomText = (laserCustomText) => {
  return {
    type: SET_LASER_CUSTOM_TEXT,
    laserCustomText,
  };
};

export const setLaserImage = (laserImage) => ({
  type: SET_LASER_IMAGE,
  laserImage,
});

export const removeSelection = (name) => ({
  type: REMOVE_SELECTION,
  name,
});

export const clearSelections = () => ({
  type: CLEAR_SELECTIONS,
});

export const setVolume = (volume) => ({
  type: SET_VOLUME,
  volume,
});

export const setSurfaceArea = (surfaceArea) => ({
  type: SET_SURFACE_AREA,
  surfaceArea,
});

export const setWeight = (weight) => ({
  type: SET_WEIGHT,
  weight,
});

export const setWidth = (payload) => ({
  type: SET_WIDTH,
  payload,
});

export const setHeight = (payload) => ({
  type: SET_HEIGHT,
  payload,
});

export const setLength = (payload) => ({
  type: SET_LENGTH,
  payload,
});

export const setDescription = (description) => ({
  type: SET_DESCRIPTION,
  description,
});

export const setQuantity = (quantity) => ({
  type: SET_QUANTITY,
  quantity,
});

export const setImageScreenShot = (screenshot) => ({
  type: SET_SCREENSHOT,
  screenshot,
});

export const setOptions = (name, options) => ({
  type: SET_OPTIONS,
  name,
  options,
});

export const selectFirst = () => ({
  type: SELECT_FIRST,
});

export const setMeshLocation = (payload) => ({
  type: SET_MESH_LOCATION,
  payload,
});

export const isFetching = () => ({
  type: IS_FETCHING,
});

export const uploadFile = (name, file) => ({
  type: UPLOAD_FILE,
  file,
  name,
  isFetching,
});

export const uploadAssemblyFile = (name, file) => ({
  type: UPLOAD_ASSEMBLY_FILE,
  file,
  name,
});

export const setVolumeAssembly = (volume) => ({
  type: SET_VOLUME_ASSEMBLY,
  volume,
});

export const setSurfaceAreaAssembly = (surfaceArea) => ({
  type: SET_SURFACE_AREA_ASSEMBLY,
  surfaceArea,
});

export const setAssemblyStep = (data) => ({
  type: SET_ASSEMBLY_STEP,
  data,
});

export const modelIsLoading = () => ({
  type: MODEL_LOADING,
});

export const modelIsDoneLoading = () => ({
  type: MODEL_LOADING_DONE,
});

export const setFileDimensionError = (payload) => ({
  type: SET_FILE_DIMENSION_ERRORS,
  payload,
});

export const removeFileDimensionError = () => ({
  type: REMOVE_FILE_DIMENSION_ERRORS,
});

export const resetCreate = () => ({
  type: RESET,
});

export const openModalLibrary = (payload) => ({
  type: OPEN_MODAL_LIBRARY,
  payload,
});

export const openCreditCardCheckout = (payload) => ({
  type: OPEN_CREDIT_CARD_CHECKOUT,
  payload,
});

export const setSelected3dModel = (payload) => ({
  type: SET_SELECTED_MODEL,
  payload,
});

export const setSelectedMarket3dModel = (payload) => ({
  type: SET_SELECTED_MARKETPLACE_MODEL,
  payload,
});

export const setSelectedProduct = (payload) => ({
  type: SET_SELECTED_PRODUCT,
  payload,
});

export const setSortItemType = (payload) => ({
  type: SET_SORT_ITEM_TYPE,
  payload,
});

export const isModelChosen = (payload) => ({
  type: MODEL_CHOSEN,
  payload,
});

export const isProductChosen = (payload) => ({
  type: PRODUCT_CHOSEN,
  payload,
});

export const setMostPopular = (payload) => ({
  type: SET_MOST_POPULAR,
  payload,
});

export const setLoadingProcess = (payload) => ({
  type: SET_LOAD_PROCESS,
  payload,
});

export const setNewOrder = (payload) => ({
  type: NEW_ORDER,
  payload,
});

export const setAddedShopify = (payload) => ({
  type: SET_PRODUCT_SHOPIFY,
  payload,
});
