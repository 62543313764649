import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  brandForgeTextComplete,
  getCurrentMonthUserBrandForgeMessages,
} from '../utils/api';
import {
  clickHandler,
  errMessageBrandforge,
  formatDate,
} from '../utils/helpers';
import CustomTooltip from './CustomTooltip';
import Prompts from './Prompts';

export const useAutosizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '36px';
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);
};

export const PopUpChat = (props) => {
  const [chatopen, setChatopen] = useState(false);
  const [msg, setMsg] = useState(null);
  const [messages, setMessages] = useState([
    {
      id: 0,
      ai_text: `Hi there, I am BRANDFORGE™, Gildform's AI-powered assistant designed to help you build and grow your brand from concept to production and beyond.\nHow can I help?`,
      user_text: null,
    },
  ]);
  const [msgLoader, setMsgLoader] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [msgsLength, setMsgsLength] = useState(0);
  const [openPrompts, setOpenPrompts] = useState(false);

  const chatBoxRef = useRef(null);
  const textAreaRef = useRef(null);

  useAutosizeTextArea(textAreaRef.current, msg);

  const profile = useSelector(
    (state) => state.login.auth && state.login.auth.profile
  );

  const isAuthenticated = useSelector(
    (state) => state.login && state.login.isAuthenticated
  );

  const toggle = () => {
    setChatopen(!chatopen);
  };

  const handleSend = async () => {
    setOpenPrompts(false);
    setMsg(null);
    let messageErr = {
      user_text: null,
      name: `${profile.firstName} ${profile.lastName}`,
      userId: profile.sub,
      error: true,
    };
    messages.push({
      ai_text: null,
      user_text: msg,
      name: `${profile.firstName} ${profile.lastName}`,
      userId: profile.sub,
      createdAt: formatDate(new Date(), true),
    });
    setMsgLoader(true);
    const data = {
      text: msg ? msg : '',
      userId: profile.sub,
      name: `${profile.firstName} ${profile.lastName}`,
    };
    const res = await brandForgeTextComplete(data);
    if (res.status === 200) {
      const dd = await res.json();
      setMsgsLength(msgsLength + 1);
      messages.pop();
      if (dd.brandForge) {
        setMessages([...messages, dd.brandForge]);
      }
      if (profile.membership.name === 'Standard') {
        if (messages.length + 1 >= 10) {
          setErrMsg(true);
          setMessages((prevState) => [
            ...prevState,
            {
              ...messageErr,
              ai_text: `<div><bold>${dd.length}/10</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline;'>here</b> to get unlimited access.</div>`,
            },
          ]);
        }
      } else if (
        profile.membership.name.includes('Silver Plus') ||
        profile.membership.name === 'Bronze' ||
        profile.membership.name === 'Silver'
      ) {
        if (messages.length + 1 >= 20) {
          setErrMsg(true);
          setMessages((prevState) => [
            ...prevState,
            {
              ...messageErr,
              ai_text: `<div><bold>${dd.length}/20</bold> calls used this month. Upgrade your membership <b style='cursor:pointer; text-decoration:underline;'>here</b> to get unlimited access.</div>`,
            },
          ]);
        }
      }
      setMsgLoader(false);
    } else {
      setMsgLoader(false);
    }
  };

  const getUserMsgs = async (id) => {
    const res = await getCurrentMonthUserBrandForgeMessages(id);
    const dd = await res.json();
    let message = {
      user_text: null,
      name: `${profile.firstName} ${profile.lastName}`,
      userId: profile.sub,
      error: true,
    };
    if (res.status === 200) {
      setMsgsLength(dd.length);
      errMessageBrandforge(
        profile.membership,
        setMessages,
        message,
        dd,
        setErrMsg
      );
    }
  };

  useEffect(() => {
    if (chatopen && profile && messages.length === 1) {
      getUserMsgs(profile.sub);
    }
  }, [chatopen]);

  useEffect(() => {
    if (chatBoxRef.current || msgLoader || openPrompts) {
      chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight);
    }
  }, [messages, chatopen, msgLoader, openPrompts]);

  let disableSend;
  if (profile && !props.unShow) {
    if (profile.membership) {
      const mmb = profile.membership.metadata
        ? profile.membership.metadata.nickname
          ? profile.membership.metadata.nickname
          : profile.membership.name.trim()
        : profile.membership.name.trim();
      disableSend =
        mmb === 'Standard'
          ? msgsLength >= 10
            ? true
            : false
          : mmb.includes('Silver Plus') || mmb === 'Bronze' || mmb === 'Silver'
          ? msgsLength >= 20
            ? true
            : false
          : false;
    }
  }

  return isAuthenticated ? (
    <div id='chatCon'>
      <div className={chatopen ? 'chat-box' : 'chat-box-hidden'}>
        <div className={'header'}>
          BrandForge Chat
          <span>
            <i className='fa fa-times ml-2' onClick={toggle} />
          </span>
        </div>
        <div className='msg-area' ref={chatBoxRef}>
          {messages.map((msg, i) => (
            <div key={i}>
              {msg.user_text && (
                <div className='right'>
                  <p className='message-content'>{msg.user_text}</p>
                  {msg.createdAt && (
                    <div className='message-timestamp-right'>
                      {formatDate(new Date(msg.createdAt), true)}
                    </div>
                  )}
                </div>
              )}
              {msg.ai_text && (
                <div className='left' style={msg.error ? { color: 'red' } : {}}>
                  {msg.error ? (
                    <p
                      className='message-content'
                      dangerouslySetInnerHTML={{ __html: msg.ai_text }}
                      onClick={(e) => clickHandler(e, props.openMemberModal)}
                    />
                  ) : (
                    <p className='message-content'>{msg.ai_text}</p>
                  )}
                </div>
              )}
            </div>
          ))}
          {msgLoader && (
            <div className='spinner' aria-busy='true'>
              <span className='bubble1'></span>
              <span className='bubble2'></span>
              <span className='bubble3'></span>
            </div>
          )}
          <Prompts
            setMsg={setMsg}
            setOpenPrompts={setOpenPrompts}
            openPrompts={openPrompts}
          />
        </div>
        <div className='footer'>
          <div className='inputs-send'>
            <textarea
              id='review-text'
              onChange={(e) => setMsg(e.target.value)}
              placeholder='Ask BrandForge...'
              ref={textAreaRef}
              rows={1}
              value={msg ? msg : ''}
              disabled={msgLoader || errMsg || disableSend}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (msg && !errMsg && !disableSend) {
                    handleSend();
                  }
                }
              }}
            />
            <button
              onClick={handleSend}
              disabled={msgLoader || errMsg || disableSend}
            >
              <i className='fa fa-paper-plane'></i>
            </button>
          </div>
          <div className='disclaimer'>
            By using BrandForge™, you agree to our full disclaimer regarding the
            accuracy, applicability, and limitations of the AI assistant's
            advice, which can be found{' '}
            <a
              href='https://support.gildform.com/brandforge-disclaimer'
              target='_blank'
            >
              here
            </a>
            .
          </div>
        </div>
      </div>
      <CustomTooltip text={'BrandForge Chat now available!'} show={!chatopen}>
        <div className='pop' onClick={toggle}>
          <p>
            BrandForge
            <img src='/images/chat.png' alt='branforge' />
          </p>
        </div>
      </CustomTooltip>
    </div>
  ) : null;
};

export default PopUpChat;
