import uuid from 'uuid/v4';
import {
  isProductChosen,
  setImageScreenShot,
  setSelectedProduct,
} from '../create/jewelry/actions';
import {
  addToCartRequest,
  addToCartSuccess,
  addToCartFailure,
  deleteCartItemById as deleteCartItemByIdAction,
  generateCartId,
} from './actions';
import { setMeshLocation } from '../create/jewelry/actions';
import {
  uploadFile,
  addToCart as addToCartApi,
  uploadItemImage,
  safeGetAuthFromState,
  getPrice,
} from '../../utils/api';
import { setCreditOrder } from '../checkout/actions';
import { getMeshId } from '../../utils/helpers';

export const addToCart = (item) => async (dispatch, getState) => {
  try {
    const itemId = uuid();
    dispatch(addToCartRequest());
    let cartId = getState().cart.id;

    if (cartId === null || !cartId) {
      cartId = `${uuid()}-${uuid()}`;
      dispatch(generateCartId(cartId));
    }
    let cartModelId = item.selected3dModel
      ? item.selected3dModel.mesh_folder
      : item.selectedMarketplace3dModel
        ? getMeshId(item.selectedMarketplace3dModel.model3D)
        : null;

    const authToken = safeGetAuthFromState(getState());

    if (!item.productChosen) {
      dispatch(setImageScreenShot(item.screenshot));
    }

    const data = item.productChosen
      ? null
      : {
        id: cartId,
        file: window.meshes[item.file_name],
      };

    let id;
    if (cartId) {
      item.modelChosen === true || item.productChosen
        ? null
        : await uploadFile(data, authToken);
      dispatch(setMeshLocation(cartId));
    } else {
      id = `${uuid()}-${uuid()}`;
      dispatch(generateCartId(id));
      if (!item.productChosen) {
        await uploadFile(
          { id: id, file: window.meshes[item.file_name] },
          authToken
        );
      }
      dispatch(setMeshLocation(id));
    }

    let finalCartId = cartId ? cartId : id;

    const screenshotLocation = !item.productChosen
      ? await uploadItemImage(
        item.modelChosen === true ? cartModelId : finalCartId,
        item.screenshot
      )
      : null;

    const { screenshot, ...itemAttrs } = item;
    const onlyWeight =
      item.productChosen && Number(item.selectedProduct.weight) * 1000;
    const volume =
      item.productChosen &&
      onlyWeight / (item.material ? Number(item.material.gravity) : 8.9);
    const libraryProduct = getState().create.jewelry.libraryProduct;

    return dispatch(
      addToCartSuccess({
        ...itemAttrs,
        volume: item.productChosen ? (volume ? volume : 0) : item.volume,
        libraryProduct,
        id: itemId,
        image: item.productChosen
          ? item.selectedProduct.prodImages[0]
          : screenshotLocation.screenshot_url,
        meshLocation: item.modelChosen === true ? cartModelId : finalCartId,
        weight: item.productChosen ? item.selectedProduct.weight : null,
        selectedProduct: item.productChosen ? item.selectedProduct : null,
      })
    );
  } catch (error) {
    return dispatch(addToCartFailure());
  }
};

export const addToCartReorder = (cart, data) => async (dispatch, getState) => {
  const authToken = safeGetAuthFromState(getState());
  if (cart.cartId === null) {
    cart.cartId = await dispatch(createCart());
  }
  dispatch(addToCartAction());

  const subitems = [
    {
      item_id: data.material.item_id,
      variant: data.material.variant,
      sub_item_id: data.material.sub_item_id,
    },
    {
      item_id: data.polish.item_id,
      variant: data.polish.variant,
      sub_item_id: data.polish.sub_item_id,
    },
  ];

  if (data.plating && data.plating.name !== 'None') {
    subitems.push({
      item_id: data.plating.item_id,
      variant: data.plating.variant,
      sub_item_id: data.plating.sub_item_id,
    });
  }

  const priceRequest = {
    item_id: data.itemTypeId,
    surface_area: data.surface_area,
    volume: data.volume,
    count: Number(data.units),
    sub_items: subitems,
  };

  const res = await getPrice(priceRequest, authToken);
  const price = {
    ...res,
    isFetching: false,
  };

  const itemID = uuid();

  cart.items.push({
    item_id: itemID,
    uploaded_mesh: `${cart.cartId}/REORDER`,
    volume: data.volume,
    surface_area: data.surface_area,
    material: {
      material: data.material,
      polish: data.polish,
      plating: data.plating,
      total: data.total,
    },
    price: price,
    units: Number(data.units),
    additional_items: {
      assembly: {
        option: data.option,
        subOption: data.subOption,
        total: data.total,
      },
      description: {
        type: {
          name: data.skuName,
          product_id: data.sku,
          id: data.itemTypeId,
        },
        description: data.description,
        screenshot_url: data.screenshot_url,
      },
    },
  });

  const response = await addToCartApi(cart.cartId, cart, authToken);
  return;
};

export const deleteCartItemById = (itemId) => async (dispatch) => {
  dispatch(deleteCartItemByIdAction(itemId));
  dispatch(setCreditOrder(null));
  dispatch(setSelectedProduct(null));
  dispatch(isProductChosen(null));
};
