import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form } from 'react-bootstrap';
import Joyride from 'react-joyride';

import DeliverDate from '../DeliveryDate';
import { setQuantity } from '../../state/create/jewelry/thunks';
import { assemblySubPrice, getNextHighestIndex } from '../../utils/helpers';
import AssemblySubItemsList from './assembly_options/AssemblySubItemsList';

const steps = [
  {
    title: 'Review your order!',
    target: '#my-unit-tour-price',
    content:
      'You may adjust your order quantity here after reviewing your order.',
  },
  {
    title: 'Add these items to your cart!',
    target: '#continue-tour',
    content:
      'Click here to add these products to your cart for production. You may then choose to check out or add another item.',
  },
];

const setDefaut = (thing, property, defaultVal) => {
  return !!thing ? thing[property] : defaultVal;
};

const Review = ({
  jewelry,
  price,
  quantity,
  setQuantity,
  discount,
  paymentDetail,
  productionPage,
  setSellingPrice,
  setQty,
  sellingPrice,
  qty,
  itemType,
  material,
  plating,
  polish,
  assemblyOption,
  laserEngravingOption,
  estimatePricing,
  showHowMuch,
  productChosen,
  totalProductPrice,
  assemblySubItems,
  setShowQtyNotification,
  setUnlockDiscount,
  setMsgQtyNotif,
}) => {
  const description = [
    setDefaut(jewelry.material, 'name', undefined),
    setDefaut(jewelry.plating, 'name', undefined),
    setDefaut(jewelry.polish, 'name', undefined),
    setDefaut(jewelry.assemblyOption, 'name', undefined),
    setDefaut(jewelry.laserEngravingOption, 'name', undefined),
  ].filter((part) => !!part);

  const assemblySubTotal = assemblySubPrice(assemblySubItems);
  const total = productChosen
    ? totalProductPrice
    : price.totalPrice + Number(assemblySubTotal);

  return (
    <>
      {!productionPage && (
        <>
          <Row className='pricing'>
            <Joyride
              steps={steps}
              continuous={true}
              styles={{
                options: {
                  zIndex: 10000,
                  primaryColor: '#7c6262',
                  overlayColor: 'rgba(0, 0, 0, 0.3)',
                },
              }}
              showProgress={true}
              showSkipButton={true}
            />
            <Col>
              <h5>{jewelry.itemType.name}</h5>
              <p style={{ color: '#aea9a2' }}>{description.join('. ')}</p>
              <AssemblySubItemsList
                review={true}
                assemblySubItems={assemblySubItems}
                setAssemblyStep={() => { }}
                setStep={() => { }}
                setAssemblySubItems={() => { }}
              />
            </Col>
          </Row>
          <Row style={{ width: '55%' }} className='ml-auto'>
            <Col
              style={{
                flexGrow: 2,
                marginTop: '10px',
                marginLeft: 'auto',
                textAlign: 'right',
              }}
              id='my-unit-tour-price'
            >
              Price
            </Col>
            {price.isFetching ? (
              <i className='fas fa-circle-notch fa-spin' />
            ) : (
              <Col style={{ textAlign: 'right' }}>${total.toFixed(2)}</Col>
            )}
          </Row>
        </>
      )}
      {productionPage && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginBottom: '3.5px' }}>
            <span>&#8226;</span> Item Type:{' '}
            <span style={{ fontWeight: 'bold' }}>{itemType.name}</span>
          </div>
          <div style={{ marginBottom: '3.5px' }}>
            <span>&#8226;</span> Material:{' '}
            <span style={{ fontWeight: 'bold' }}>{material.name}</span>
          </div>
          {polish && (
            <div style={{ marginBottom: '3.5px' }}>
              <span>&#8226;</span> Polish:{' '}
              <span style={{ fontWeight: 'bold' }}>{polish.name}</span>
            </div>
          )}
          {plating && (
            <div style={{ marginBottom: '3.5px' }}>
              <span>&#8226;</span> Plating:{' '}
              <span style={{ fontWeight: 'bold' }}>{plating.name}</span>
            </div>
          )}
          {assemblyOption && (
            <div style={{ marginBottom: '3.5px' }}>
              <span>&#8226;</span> Assembly Option:{' '}
              <span style={{ fontWeight: 'bold' }}>{assemblyOption.name}</span>
            </div>
          )}
          {laserEngravingOption && (
            <div style={{ marginBottom: '3.5px' }}>
              <span>&#8226;</span> Laser Engraving Option:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {laserEngravingOption.name}
              </span>
            </div>
          )}
        </div>
      )}
      <Row
        style={productionPage ? { width: '100%' } : { width: '55%' }}
        className={!productionPage ? 'ml-auto' : ''}
      >
        <Col>
          <Form.Group
            style={
              productionPage
                ? {
                  display: 'flex',
                  flexDirection: 'row',
                }
                : {}
            }
          >
            {productionPage && (
              <Form.Label
                style={{
                  marginRight: '15px',
                  fontSize: '17px',
                  marginTop: '4px',
                }}
              >
                Quantity
              </Form.Label>
            )}
            <Form.Control
              style={{ textAlign: 'right' }}
              as='input'
              value={quantity}
              onChange={(e) => {
                if (e.target.value < 1) return;
                setQuantity(parseInt(e.target.value));
                if (itemType) {
                  if (itemType.has_discount) {
                    let dscs = JSON.parse(itemType.discount);
                    setShowQtyNotification(true);
                    let qtys = [];
                    if (dscs.length > 0) {
                      dscs.map((it) => {
                        qtys.push(it.quantity);
                      });
                    }

                    const nextHigh = getNextHighestIndex(
                      qtys,
                      e.target.value ? e.target.value : 1
                    );
                    let replacedWord;
                    if (dscs[nextHigh]) {
                      if (
                        Number(e.target.value) ===
                        Number(dscs[nextHigh].quantity)
                      ) {
                        setUnlockDiscount(true);
                      } else {
                        setUnlockDiscount(false);
                      }
                      if (itemType.notification) {
                        if (itemType.notification !== '') {
                          if (itemType.notification.includes('X')) {
                            replacedWord = itemType.notification.replace(
                              'X',
                              `${Number(dscs[nextHigh].quantity) -
                              Number(e.target.value)
                              }`
                            );
                          }
                          if (itemType.notification.includes('Y')) {
                            replacedWord = replacedWord
                              ? replacedWord.replace(
                                'Y',
                                `${dscs[nextHigh].discount}`
                              )
                              : itemType.notification.replace(
                                'Y',
                                `${dscs[nextHigh].discount}`
                              );
                          }
                        }
                      }
                      setMsgQtyNotif(
                        replacedWord
                          ? !replacedWord.includes('X') ||
                            !replacedWord.includes('Y')
                            ? replacedWord
                            : `${Number(dscs[nextHigh].quantity) -
                              Number(e.target.value) ===
                              0
                              ? ``
                              : `Add ${Number(dscs[nextHigh].quantity) -
                              Number(e.target.value)
                              } more items to your cart and unlock a ${dscs[nextHigh].discount
                              }% discount on your entire order!`
                            }`
                          : `${Number(dscs[nextHigh].quantity) -
                            Number(e.target.value) ===
                            0
                            ? ''
                            : `Add ${Number(dscs[nextHigh].quantity) -
                            Number(e.target.value)
                            } more items to your cart and unlock a ${dscs[nextHigh].discount
                            }% discount on your entire order!`
                          }`
                      );
                    }
                  }
                }
                if (productionPage) {
                  estimatePricing(
                    itemType,
                    material,
                    plating,
                    polish,
                    assemblyOption,
                    laserEngravingOption,
                    e.target.value
                  );
                }
              }}
              type='number'
            />
          </Form.Group>
        </Col>
      </Row>
      {productionPage && showHowMuch && (
        <div>
          <Form.Group>
            <Form.Label>What is your ideal selling price?</Form.Label>
            <Form.Control
              as='input'
              name='sellingPrice'
              value={sellingPrice}
              type='text'
              onChange={(e) => {
                setSellingPrice(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>How many units could you sell in a year?</Form.Label>
            <Form.Control
              as='input'
              value={qty}
              onChange={(e) => {
                setQty(e.target.value);
              }}
              type='number'
            />
          </Form.Group>
        </div>
      )}
      {!!discount && !productionPage && (
        <Row style={{ width: '55%' }} className='ml-auto'>
          <Col style={{ flexGrow: 2 }}>Bulk Discount</Col>
          <Col style={{ textAlign: 'right' }}>-{discount.toFixed(2)}</Col>
        </Row>
      )}
      {!productionPage && (
        <>
          <div style={{ width: '100%', borderBottom: '1px solid black' }} />
          <Row className='ml-2 text-center mt-3'>
            <div>{paymentDetail}</div>
          </Row>
          <Row style={{ width: '100%', margin: '0', marginTop: '50px' }}>
            <Row style={{ width: '100%', margin: '0' }}>
              {/* <Col style={{ flexGrow: 2 }} xs={6}>
            Estimated Delivery Date
          </Col>
          <Col style={{ textAlign: 'right', fontWeight: '600' }}>
            <DeliverDate dontShow />
          </Col> */}
            </Row>
            <Row style={{ width: '100%', margin: '0' }}>
              <Col style={{ flexGrow: 2, fontWeight: '600' }}>Total Price</Col>
              <Col style={{ textAlign: 'right', fontWeight: '600' }}>
                ${(Number(total) * quantity).toFixed(2)}
              </Col>
            </Row>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  price: state.create.price.price,
  jewelry: state.create.jewelry,
  quantity: state.create.jewelry.quantity,
  discount: 0.0,
});

const mapDispatchToProps = (dispatch) => ({
  setQuantity: (quantity) => dispatch(setQuantity(quantity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Review);
