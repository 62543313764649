import React from 'react';

const DiscountCode = (props) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <div>Discount Code</div>
        <div>
          <input
            type='text'
            name='custom-text-font'
            className='mb-1 w-100'
            style={
              props.matchedCode
                ? { border: '1px solid grey' }
                : {
                  border: '1px dashed grey',
                }
            }
            value={props.promoCode ? props.promoCode : ''}
            onChange={(e) => {
              props.setPromoCode(e.target.value);
            }}
          />
        </div>
      </div>
      {props.promoCode && (
        <div
          style={{
            marginBottom: '8px',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          {!props.matchedCode ? (
            <div style={{ color: '#8B0000', fontWeight: 'bold' }}>
              Discount code is not valid!
            </div>
          ) : (
            <>
              {props.restrictionCoupon ? (
                props.applyDis ? (
                  <div style={{ color: '#7c6363', fontWeight: 'bold' }}>
                    {props.matchedCode.coupon.percent_off
                      ? `${props.matchedCode.coupon.percent_off}% `
                      : `${props.matchedCode.coupon.amount_off / 100}$ `}
                    discount is applied!
                    {props.matchedCode.coupon.percent_off == 100 && (
                      <div>A minimum amount of $0.50 will be applied to your card.</div>
                    )}
                  </div>
                ) : (
                  <div style={{ color: '#8B0000', fontWeight: 'bold' }}>
                    coupon not applied: minimum of{' '}
                    {props.matchedCode.restrictions.minimum_amount} order value
                    was not met!
                  </div>
                )
              ) : (
                <div style={{ color: '#7c6363', fontWeight: 'bold' }}>
                  {props.matchedCode.coupon.percent_off
                    ? `${props.matchedCode.coupon.percent_off}% `
                    : `${props.matchedCode.coupon.amount_off / 100}$ `}
                  discount is applied!
                  {props.matchedCode.coupon.percent_off == 100 && (
                    <div>A minimum amount of $0.50 will be applied to your card.</div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DiscountCode;
