import {
  SET_PRODUCT_DESCRIPTION,
  SET_PRODUCT_IMAGES,
  SET_PRODUCT_NAME,
  SET_PRODUCT_PRICE,
} from '../../actionTypes';

export const setProductName = (payload) => ({
  type: SET_PRODUCT_NAME,
  payload,
});

export const setProductDescription = (payload) => ({
  type: SET_PRODUCT_DESCRIPTION,
  payload,
});

export const setProductPrice = (payload) => ({
  type: SET_PRODUCT_PRICE,
  payload,
});

export const setProductImages = (payload) => ({
  type: SET_PRODUCT_IMAGES,
  payload,
});
