import React, { useEffect, useState } from 'react';
import { getGildformStatuses } from '../utils/api';

function Prompts(props) {
  const [selectedPrompt, setSelectedPompt] = useState(null);
  const [prompts, setPrompts] = useState([]);

  const getPrompts = async () => {
    const res = await getGildformStatuses(13);
    setPrompts(res.settings ? res.settings : []);
  };

  useEffect(() => {
    if (props.openPrompts && prompts.length === 0) {
      getPrompts();
    }
  }, [props.openPrompts]);

  return (
    <div className='prompts-main'>
      <div
        className='show-sidebar'
        onClick={() => {
          props.setOpenPrompts(!props.openPrompts);
          if (!props.openPrompts) {
            setSelectedPompt(null);
          }
        }}
      >
        <span>{props.openPrompts ? 'Hide prompts' : 'Show prompts'}</span>
        <div className='icon-content'>
          {props.openPrompts ? (
            <i className='fas fa-chevron-up' />
          ) : (
            <i className='fas fa-chevron-down' />
          )}
        </div>
      </div>
      <div className={`prompts-collapse ${props.openPrompts ? 'open' : ''}`}>
        <div className='prompts-container'>
          {prompts
            .filter((it) => it.code === '1')
            .map((prompt, index) => (
              <div
                className={
                  selectedPrompt && selectedPrompt.id === prompt.id
                    ? 'chat-row active'
                    : 'chat-row'
                }
                key={index}
                onClick={() => {
                  setSelectedPompt(prompt);
                  props.setMsg(prompt.value);
                }}
              >
                <span className='chat-name'>{prompt.value}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Prompts;
