import * as yup from 'yup';

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default {
  user: yup.object().shape({
    firstName: yup.string().required('Name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().required().email(),
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, 'Please enter a valid Phone Number'),
    website: yup.string().url(),
  }),
  full: yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required().email(),
    website: yup.string().url(),
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, 'Please enter a valid Phone Number'),
    country: yup.string().required('Country is required'),
    state: yup.string().required('State is required'),
    street: yup.string().required(),
    city: yup.string().required(),
    zipcode: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_ .-]{3,}(?:-[a-zA-Z0-9_ .-]{2})?$/,
        'Please enter a valid Zipcode'
      ),
  }),
  withCard: yup.object().shape({
    card_token: yup.string().required(),
    membership: yup.object().shape({
      id: yup.string().required(),
      product: yup.string().required(),
    }),
    didLegal: yup
      .bool()
      .required()
      .oneOf([true], 'You must agree to our terms'),
    email: yup.string().required().email(),
    firstName: yup.string().required(),
    website: yup.string().url(),
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, 'Please enter a valid Phone Number'),
    street: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    zipcode: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_ .-]{3,}(?:-[a-zA-Z0-9_ .-]{2})?$/,
        'Please enter a valid Zipcode'
      ),
  }),
  noCard: yup.object().shape({
    membership: yup.object().shape({
      id: yup.string().required(),
      product: yup.string().required(),
    }),
    didLegal: yup
      .bool()
      .required()
      .oneOf([true], 'You must agree to our terms'),
    email: yup.string().required().email(),
    firstName: yup.string().required(),
    website: yup.string().url(),
    phone: yup
      .string()
      .required()
      .matches(phoneRegExp, 'Please enter a valid Phone Number'),
    street: yup.string().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().required(),
    zipcode: yup
      .string()
      .required()
      .matches(
        /^[a-zA-Z0-9_ .-]{3,}(?:-[a-zA-Z0-9_ .-]{2})?$/,
        'Please enter a valid Zipcode'
      ),
  }),
  productionCalculator: yup.object().shape({
    width: yup.string().required(),
    length: yup.string().required(),
    height: yup.string().required(),
    weight: yup.string().required(),
  }),
};
