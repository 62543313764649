import React, { useEffect, useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';

import {
  removeAssemblyOption,
  setAssemblyStep,
  setAssemblySubItems,
  setMostPopular,
  setSortItemType,
  setSurfaceAreaAssembly,
  setVolumeAssembly,
  uploadAssemblyFile,
} from '../../state/create/jewelry/actions';
import { setAssemblyOption } from '../../state/create/jewelry/thunks';
import { getPrice, getPriceAssembly } from '../../state/create/jewelry/thunks';
import AssemblyContent from './assembly_options/AssemblyContent';
import SubItem from './SubItem';
import DesignLibraryModal from '../DesignLibraryModal';
import Modal from '../Modal';
import {
  apiFetch,
  getAll3DModels,
  getPresignedUrl,
  getProductVariants,
} from '../../utils/api';
import { toast } from 'react-toastify';
import { getMarketplaceUsersDesigns } from '../../state/login/thunks';
import { getMeshId } from '../../utils/helpers';
import AssemblySubItemsList from './assembly_options/AssemblySubItemsList';

const steps = [
  {
    title: 'Select an assembly option',
    target: '#my-row-tour',
    content:
      'You may choose to have your jewelry products come assembled with the components you select. (Example: When you select Ear Posts, your jewelry will come with ear posts assembled) .',
  },
  {
    title: 'Continue',
    target: '#continue-tour',
    content: 'Press continue to move on to the next step.',
  },
  {
    title: 'Skip this step',
    target: '#skip-my-tour-earrings',
    content:
      'If you wish to skip this step, please click here. Please keep in mind that Gildform does not support any types of Ear Post (including but not limited to, ear wires, hinges or joint and catch sets) in your 3D model(s).  You must select Ear Posts, if you’d like your finished product to have ear posts.',
  },
];

const steps2 = [
  {
    title: 'Select an assembly option',
    target: '#my-row-tour',
    content:
      'You may choose to have your pendant come assembled with the chains/necklaces/bracelets you select.',
  },
  {
    title: 'Continue',
    target: '#continue-tour',
    content: 'Press continue to move on to the next step.',
  },
  {
    title: 'Skip this step',
    target: '#skip-my-tour-earrings',
    content:
      'If you wish to skip this step, please click here. Please keep in mind that Gildform does not support any types of chains in your 3D model(s).  You must select a chain type and length, if you’d like your finished product to come with assembled with a chain.',
  },
];

const AssemblyStep = ({
  itemType,
  assemblyOption,
  assemblyOptions,
  total,
  quantity,
  priceIsLoading,
  getPrice,
  setAssemblyOption,
  removeAssemblyOption,
  paymentDetail,
  membership,
  productionPage,
  estimatePricing,
  material,
  polish,
  plating,
  laserEngravingOption,
  setAssemblyPrice,
  totalProductPrice,
  isDesignLibraryModalOpen,
  openDesignLibraryModal,
  openModalLibrary,
  setMostPopular,
  popularClicked,
  userProjects,
  token,
  profile,
  sortItem,
  setSortItemType,
  setAssemblySubItems,
  assemblySubItems,
  uploadAssemblyFile,
  assemblyFile,
  assemblyFileName,
  setAssemblyStep,
  getPriceAssembly,
  setVolumeAssembly,
  setSurfaceAreaAssembly,
  assemblyVolume,
  assemblySurface,
}) => {
  const [step, setStep] = useState(0);
  const [Steps, setAllSteps] = useState([AssemblyContent]);

  const [selected3dModel, setSelected3dModel] = useState(null);
  const [selectedMarketplace3dModel, setSelectedMarket3dModel] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [chosen, setChosen] = useState(false);
  const [chosenPr, setChosenPr] = useState(false);

  const [selectLoading, setSelectLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);

  const [filteredDesign, setFilteredDesign] = useState(null);
  const [products, setProducts] = useState([]);

  const [D3models, setModels] = useState([]);
  const [activeTab, setActiveTab] = useState('product');
  const [itemTypes, setItemTypes] = useState(null);
  const [qt, setQt] = useState(1);
  const [prLoader, setPrLoader] = useState(false);
  const [showError, setShowError] = useState(false);

  const fetchItemTypes = async () => {
    const data = await apiFetch('/items');
    setItemTypes(data.filter((it) => !it.product));
  };

  const fetch3dModels = async (token) => {
    setPrLoader(true);
    const data = await getAll3DModels(token);
    const filtered = data.filter((mod) => mod.manufactId === null);
    setModels(filtered);
    setTimeout(() => {
      setPrLoader(false);
    }, 1000);
  };

  const getProducts = async () => {
    setPrLoader(true);
    const res = await getProductVariants();
    setProducts(res);
    setTimeout(() => {
      setPrLoader(false);
    }, 1000);
  };

  const getMarketplaceDesigns = async (id) => {
    await getMarketplaceUsersDesigns(id);
  };

  useEffect(() => {
    if (isDesignLibraryModalOpen) {
      if (products.length === 0 && activeTab === 'product') {
        getProducts();
      }
    }
  }, [isDesignLibraryModalOpen, activeTab]);

  useEffect(() => {
    if (activeTab === 'default' && D3models.length === 0) {
      fetch3dModels(token);
      fetchItemTypes();
    }
    if (activeTab === 'market') {
      getMarketplaceDesigns(profile.id);
    }
  }, [activeTab]);

  const getComponent = (num) => Steps[num];

  const Component = getComponent(step);

  const fileReader =
    typeof window !== `undefined`
      ? new FileReader()
      : { readAsArrayBuffer: (_file) => { } };

  const onFileLoad2 = (files) => {
    setChosenPr(false);
    setSelectedProduct(null);

    if (files.length === 0) return;

    if (files.length > 1) {
      setShowError('Too many files were selected');
      return;
    }

    fileReader.onload = (e) => {
      uploadAssemblyFile(files[0].name, e.target.result);
    };

    fileReader.readAsArrayBuffer(files[0]);
    setTimeout(() => {
      setChosen(false);
      setPriceLoading(false);
    }, 1500);
  };

  function setBlobs(blob, fileName) {
    const splitted = fileName ? fileName.split('.') : '';
    let container = new DataTransfer();
    let file = new File([blob], fileName ? `${splitted[0]}.stl` : 'model.stl', {
      lastModified: new Date().getTime(),
    });
    container.items.add(file);
    setChosen(true);
    setSelectLoading(false);
    openDesignLibraryModal(false);
    openModalLibrary(false);
    setSortItemType(null);
    onFileLoad2(container.files);
  }

  const download3d = (url) => {
    fetch(url, {
      method: 'GET',
      responseType: 'blob',
    })
      .then((response) => response.blob())
      .then(function (myBlob) {
        setBlobs(
          myBlob,
          activeTab === 'default'
            ? selected3dModel.file_name
            : activeTab === 'market'
              ? selectedMarketplace3dModel.modelFileName
              : ''
        );
      })
      .catch((e) => {
        console.log('error n fetchDonwlodn---', e);
        setPriceLoading(false);
      });
  };

  const currentItem =
    assemblySubItems &&
    assemblySubItems.length > 0 &&
    assemblySubItems.find((item) => item.id === step);

  const chooseThreeDModel = async () => {
    setSelectLoading(true);

    const toAdd = {
      id: step,
      model3D: null,
      modelMarket: null,
      product: null,
    };

    if (activeTab === 'default') {
      setPriceLoading(true);
      const res = await getPresignedUrl(selected3dModel.mesh_folder);
      if (res.url) {
        download3d(res.url);
      } else {
        toast.error('Could not load this file!');
        setSelectLoading(false);
        setPriceLoading(false);
      }

      if (assemblySubItems && assemblySubItems.length > 0) {
        if (currentItem && currentItem.id === step) {
          const newArr =
            assemblySubItems &&
            assemblySubItems.map((it) => {
              if (it.id === step) {
                return {
                  ...it,
                  model3D: true,
                  modelMarket: null,
                  product: null,
                  file: selected3dModel,
                  mesh_folder: selected3dModel.mesh_folder,
                  // volume: assemblyVolume,
                  // surface_area: assemblySurface,
                };
              }
            });
          setAssemblySubItems(newArr);
        } else {
          setAssemblySubItems([
            ...assemblySubItems,
            {
              ...toAdd,
              model3D: true,
              file: selected3dModel,
              mesh_folder: selected3dModel.mesh_folder,
              // volume: assemblyVolume,
              // surface_area: assemblySurface,
            },
          ]);
        }
      } else {
        setAssemblySubItems([
          ...assemblySubItems,
          {
            id: step,
            model3D: true,
            modelMarket: null,
            product: null,
            file: selected3dModel,
            mesh_folder: selected3dModel.mesh_folder,
            // volume: assemblyVolume,
            // surface_area: assemblySurface,
          },
        ]);
      }

      openModalLibrary(false);
      openDesignLibraryModal(false);
    } else if (activeTab === 'market') {
      if (selectedMarketplace3dModel.model3D) {
        download3d(selectedMarketplace3dModel.model3D);
      } else {
        toast.error('Could not load this file!');
        setSelectLoading(false);
      }
      if (selectedMarketplace3dModel.model3D) {
        if (assemblySubItems && assemblySubItems.length > 0) {
          if (currentItem && currentItem.id === step) {
            const newArr =
              assemblySubItems &&
              assemblySubItems.map((it) => {
                if (it.id === step) {
                  return {
                    ...it,
                    model3D: null,
                    modelMarket: true,
                    product: null,
                    file: selectedMarketplace3dModel,
                    mesh_folder: getMeshId(selectedMarketplace3dModel.model3D),
                    // volume: assemblyVolume,
                    // surface_area: assemblySurface,
                  };
                }
              });
            setAssemblySubItems(newArr);
          } else {
            setAssemblySubItems([
              ...assemblySubItems,
              {
                ...toAdd,
                modelMarket: true,
                file: selectedMarketplace3dModel,
                mesh_folder: getMeshId(selectedMarketplace3dModel.model3D),
                // volume: assemblyVolume,
                // surface_area: assemblySurface,
              },
            ]);
          }
        } else {
          setAssemblySubItems([
            ...assemblySubItems,
            {
              id: step,
              model3D: null,
              modelMarket: true,
              product: null,
              file: selectedMarketplace3dModel,
              mesh_folder: getMeshId(selectedMarketplace3dModel.model3D),
              // volume: assemblyVolume,
              // surface_area: assemblySurface,
            },
          ]);
        }
      } else {
        toast.error('Could not load this file!');
        setSelectLoading(false);
      }
      openModalLibrary(false);
      openDesignLibraryModal(false);
    } else if (activeTab === 'product') {
      if (assemblySurface || assemblyVolume) {
        setVolumeAssembly(null);
        setSurfaceAreaAssembly(null);
      }
      if (assemblySubItems && assemblySubItems.length > 0) {
        if (currentItem && currentItem.id === step) {
          const newArr =
            assemblySubItems &&
            assemblySubItems.map((it) => {
              if (it.id === step) {
                return {
                  ...it,
                  model3D: null,
                  modelMarket: null,
                  product: true,
                  file: selectedProduct,
                  price: {
                    castPrice: 0,
                    fixedCostPrice: 0,
                    polishLaborPrice: 0,
                    threeDPrintingPrice: 0,
                    platingPrice: 0,
                    assemblyOptionPrice: 0,
                    laserEngravingOptionPrice: 0,
                    totalPrice: selectedProduct.base_price,
                  },
                  volume: null,
                  surface_area: null,
                };
              }
            });
          setAssemblySubItems(newArr);
        } else {
          setAssemblySubItems([
            ...assemblySubItems,
            {
              ...toAdd,
              product: true,
              file: selectedProduct,
              mesh_folder: '',
              price: {
                castPrice: 0,
                fixedCostPrice: 0,
                polishLaborPrice: 0,
                threeDPrintingPrice: 0,
                platingPrice: 0,
                assemblyOptionPrice: 0,
                laserEngravingOptionPrice: 0,
                totalPrice: selectedProduct.base_price,
              },
              volume: null,
              surface_area: null,
            },
          ]);
        }
      } else {
        setAssemblySubItems([
          ...assemblySubItems,
          {
            id: step,
            file: selectedProduct,
            product: true,
            model3D: null,
            modelMarket: null,
            volume: null,
            surface_area: null,
            price: {
              castPrice: 0,
              fixedCostPrice: 0,
              polishLaborPrice: 0,
              threeDPrintingPrice: 0,
              platingPrice: 0,
              assemblyOptionPrice: 0,
              laserEngravingOptionPrice: 0,
              totalPrice: selectedProduct.base_price,
            },
          },
        ]);
      }
      setSelectLoading(false);
      openModalLibrary(false);
      openDesignLibraryModal(false);
    }

    setSelectLoading(false);
  };

  return (
    <>
      <Joyride
        steps={
          itemType.name.toLowerCase() === 'earring'
            ? steps
            : itemType.name.toLowerCase() === 'necklace' ||
              itemType.name.toLowerCase() === 'bracelet' ||
              chosenPr
              ? steps2
              : null
        }
        continuous={true}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#c2c2c2',
            overlayColor: 'rgba(0, 0, 0, 0.3)',
          },
        }}
        showProgress={true}
        showSkipButton={true}
      />
      <Component
        itemType={itemType}
        assemblyOption={assemblyOption}
        assemblyOptions={assemblyOptions}
        total={total}
        quantity={quantity}
        priceIsLoading={priceIsLoading}
        getPrice={getPrice}
        setAssemblyOption={setAssemblyOption}
        removeAssemblyOption={removeAssemblyOption}
        paymentDetail={paymentDetail}
        membership={membership}
        productionPage={productionPage}
        estimatePricing={estimatePricing}
        material={material}
        polish={polish}
        plating={plating}
        laserEngravingOption={laserEngravingOption}
        setAssemblyPrice={setAssemblyPrice}
        totalProductPrice={totalProductPrice}
        step={step}
        openDesignLibraryModal={openDesignLibraryModal}
        isDesignLibraryModalOpen={isDesignLibraryModalOpen}
        openModalLibrary={openModalLibrary}
        setAssemblySubItems={setAssemblySubItems}
        assemblySubItems={assemblySubItems}
        onFileLoad2={onFileLoad2}
        chosen={chosen}
        uploadAssemblyFile={uploadAssemblyFile}
        setChosenPr={setChosenPr}
        assemblyFile={assemblyFile}
        assemblyFileName={assemblyFileName}
        getPriceAssembly={getPriceAssembly}
        setPriceLoading={setPriceLoading}
        priceLoading={priceLoading}
        token={token}
        selectLoading={selectLoading}
        assemblyVolume={assemblyVolume}
        assemblySurface={assemblySurface}
        setQt={setQt}
        qt={qt}
        setShowError={setShowError}
      />
      <AssemblySubItemsList
        assemblySubItems={assemblySubItems}
        setAssemblyStep={setAssemblyStep}
        setStep={setStep}
        setAssemblySubItems={setAssemblySubItems}
        review={false}
      />
      <div className='d-flex flex-row align-content-center justify-content-center pt-3 pb-3'>
        <Button
          className=''
          onClick={() => {
            setAllSteps([...Steps, SubItem]);
            setStep(step + 1);
            setAssemblyStep(step + 1);
            setQt(1);
          }}
        >
          Add More 3D Model Parts{' '}
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div className='ml-4 align-content-center justify-content-center'>{paymentDetail}</div>
      </div>
      <Modal
        title='Welcome to the Design Library'
        description="We've created the best and brightest 3D models for you to choose from. Just take your pick and make your specifications."
        show={isDesignLibraryModalOpen}
        handleClose={() => {
          openModalLibrary(false);
          openDesignLibraryModal(false);
          setSortItemType(null);
        }}
        primaryButtons={[
          {
            title:
              activeTab === 'product' ? 'Select Product' : 'Select 3D model',
            disabled:
              activeTab === 'default'
                ? !selected3dModel
                : activeTab === 'market'
                  ? !selectedMarketplace3dModel
                  : activeTab === 'product'
                    ? !selectedProduct
                    : true,
            isLoading: selectLoading,
            handle: chooseThreeDModel,
          },
        ]}
        dangerButtons={[
          {
            title: 'Cancel',
            handle: () => {
              openModalLibrary(false);
              openDesignLibraryModal(false);
              setSortItemType(null);
            },
          },
        ]}
        size={'lg'}
        headerClassName={'d-flex flex-column'}
        headerStyle={{
          paddingRight: '4rem',
          paddingLeft: '4rem',
          paddingBottom: '5px',
          paddingTop: '20px',
        }}
        bodyStyle={{ paddingRight: '2.5rem', paddingLeft: '2.5rem' }}
        loading={selectLoading}
      >
        <DesignLibraryModal
          models={D3models}
          itemTypes={itemTypes}
          setSelectedModel={setSelected3dModel}
          selected3dModel={selected3dModel}
          setSortItemType={setSortItemType}
          sortItem={sortItem}
          membership={membership}
          setMostPopular={setMostPopular}
          popularClicked={popularClicked}
          projects={userProjects && userProjects}
          filteredDesign={filteredDesign}
          setFilteredDesign={setFilteredDesign}
          setSelectedMarket3dModel={setSelectedMarket3dModel}
          selectedMarketplace3dModel={selectedMarketplace3dModel}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          products={products}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          laserEngravingStep={false}
          setPolishPrice={() => { }}
          setMaterialPrice={() => { }}
          setAssemblyPrice={() => { }}
          setLaserEngravingPrice={() => { }}
          setPlatingPrice={() => { }}
          materialPrice={null}
          platingPrice={null}
          polishPrice={null}
          assemblyPrice={null}
          setProductPricing={() => { }}
          loader={prLoader}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  total: state.create.price.price,
  jewelry: state.create.jewelry,
  itemType: state.create.jewelry.itemType,
  material: state.create.jewelry.material,
  plating: state.create.jewelry.plating,
  polish: state.create.jewelry.polish,
  laserEngravingOption: state.create.jewelry.laserEngravingOption,
  assemblyOption: state.create.jewelry.assemblyOption,
  assemblyOptions:
    state.itemConfigs.configs &&
    state.itemConfigs.configs.length !== 0 &&
    state.itemConfigs.configs.filter(
      (itemConfig) => itemConfig.subItemId === 4
    ),
  priceIsLoading:
    state.create.price.isFetching ||
    state.create.jewelry.isLoading.modelIsLoading,
  membership:
    state.login.auth.profile.membership &&
      state.login.auth.profile.membership.nickname
      ? state.login.auth.profile.membership.nickname
      : state.login.auth.profile.membership.name,
  quantity: state.create.jewelry.quantity,
  popularClicked: state.create.jewelry && state.create.jewelry.popularClicked,
  userProjects: state.login && state.login.userProjects,
  token: state.login.auth && state.login.auth.auth.token,
  sortItem: state.create.jewelry && state.create.jewelry.sortItem,
  assemblySubItems: state.create.jewelry.assemblySubItems,
  assemblyFile: state.create.jewelry && state.create.jewelry.assemblyFile,
  assemblyFileName:
    state.create.jewelry && state.create.jewelry.assemblyFileName,
  assemblyVolume: state.create.jewelry && state.create.jewelry.assemblyVolume,
  assemblySurface: state.create.jewelry && state.create.jewelry.assemblySurface,
});

const mapDispatchToProps = (dispatch) => ({
  getPrice: () => dispatch(getPrice()),
  setAssemblyOption: (option) => dispatch(setAssemblyOption(option)),
  removeAssemblyOption: () => dispatch(removeAssemblyOption()),
  setMostPopular: (data) => dispatch(setMostPopular(data)),
  setSortItemType: (data) => dispatch(setSortItemType(data)),
  setAssemblySubItems: (data) => dispatch(setAssemblySubItems(data)),
  uploadAssemblyFile: (name, file) => dispatch(uploadAssemblyFile(name, file)),
  setAssemblyStep: (data) => dispatch(setAssemblyStep(data)),
  getPriceAssembly: (data) => dispatch(getPriceAssembly(data)),
  setVolumeAssembly: (data) => dispatch(setVolumeAssembly(data)),
  setSurfaceAreaAssembly: (data) => dispatch(setSurfaceAreaAssembly(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyStep);
