import { clearCart } from '../shoppingCart/actions';
import { charging, charged, shouldHideCart } from './actions';
import { checkoutCart } from '../../utils/api';

export const checkout = ({
  customer,
  tax,
  notes,
  shippingMethod,
  address,
  orderItems,
  apiToken,
  cartId,
  defaultCard,
  newCard,
}) => async (dispatch) => {
  try {
    dispatch(charging());
    const orderCreated = await checkoutCart({
      customer,
      tax,
      notes,
      shippingMethod,
      address,
      orderItems,
      apiToken,
      cartId,
      defaultCard,
      newCard,
    });
    dispatch(charged());
    dispatch(clearCart());
    dispatch(shouldHideCart());
  } catch (error) {
    dispatch(charged());
    dispatch(clearCart());
    dispatch(shouldHideCart());
  }
};
